import React, { Component } from 'react';
import { connect } from 'react-redux';
import { valetActions, userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import CreateAddCategoryModal from "./components/CreateAddCategoryModal/CreateAddCategoryModal";
import UpdateUserPasswordModal from './components/UpdateUserPasswordModal/UpdateUserPasswordModal';
import UpdateCategoryModal from "./components/UpdateCategoryModal/UpdateCategoryModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";

class Valet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideo: false,
      isVideoUpdate: false,
      valetRowData: {},
      fieldsValet: {},
      errorsValet: {},
      fieldsValetUpdate: {},
      errorsUpdateValet: {},
      viewRowData: {},
      type: null,
      gameType: null,
      rankType: null,
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCategoryCreateModal: false,
      loginToThisAccountModal: false,
      creditpasswordOpenModal: false,
      errorsUpdatePassword: {},
      fieldsUserPasswordUpdate: {},
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      imageName: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    // let reqData = { "id": "642e67eae8b9716b9142c41b"}
    this.props.dispatch(valetActions.getValetUserList(temp))
    //  this.props.dispatch(valetActions.getRestaurantUserById(reqData))




  }
  static getDerivedStateFromProps(nextProps, prevState) {

    console.log("STATIC______nextProps.valet.addUserSuccess", nextProps.valet.addUserSuccess);

    if (nextProps.valet.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsValet: {},
        errorsValet: {},
        addUserCreateModal: false,
        UpdateCategoryCreateModal: false,
        moreDetailsCreateModal: false,
        creditpasswordOpenModal: false,
        errorsUpdatePassword: {},
        fieldsUserPasswordUpdate: {},
        type: null,
        gameType: null,
        rankType: null,

      }
    }
    if (nextProps.users.filesDetails) {
      return {
        ...nextProps,
        imageName: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null

      }
    }

    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(valetActions.getValetUserList(datatemp));
  }
  handleSearch = (event) => {
    // console.log("666666666666666", event.target.value);
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(valetActions.getValetUserList(data));
  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status of User?',
      message: `Are you sure about ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(valetActions.disableValet(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteCategory = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(valetActions.deleteRestoCat(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  verifyEmail = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to verifyEmail?',
      message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(valetActions.verifyEmail(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    // let req = {
    //     "id": data.id
    //   }
    //   this.props.dispatch(valetActions.getValetUserById(req));

    this.setState({ moreDetailsCreateModal: true, valetRowData: data });
    //  console.log("fgfdgf1111111111111111111111111111111111",req);
  }

  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }

  handleOpenCreateModalUpdatePassword = (data) => {
    this.setState({ UpdateCategoryCreateModal: true, fieldsValetUpdate: data });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdateCategoryCreateModal: false });
  }

  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({ addUserCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }

  handleOpenCreateModal = () => {
    this.setState({ addUserCreateModal: true });
  }
  inputAddUserChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsValet = this.state.fieldsValet;
    let errorsValet = this.state.errorsValet;
    fieldsValet[name] = value;
    console.log(name, value);
    errorsValet[name] = "";
    this.setState({ fieldsValet, errorsValet });
  }
  inputChangeUpdateCategory = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsValetUpdate = this.state.fieldsValetUpdate;
    let errorsUpdateValet = this.state.errorsUpdateValet;
    fieldsValetUpdate[name] = value;
    errorsUpdateValet[name] = "";
    console.log(name, value);
    this.setState({ fieldsValetUpdate, errorsUpdateValet });
  }

  createRestoCategorySubmit = () => {

    let { users } = this.props;


    let { filesDetails,
      //  filesDetailsVideo
    } = users;
    console.log('filesDetailsfilesDetails__________', filesDetails);
    if (this.handleValidationAddUser()) {
      let reqData = {
        "name": this.state.fieldsValet.name,
        "mobile": this.state.fieldsValet.mobile,
        "email": this.state.fieldsValet.email,
        "password": this.state.fieldsValet.password,
        "image": filesDetails && filesDetails.imageName
          ? filesDetails.imageName
          : null,
      }

      console.log("createRestoCategory>>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(valetActions.createValetUser(reqData));
    }

  }


  updateCategorySubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;

    if (this.handleValidationUpdateCategory()) {
      let reqData = {
        "id": this.state.fieldsValetUpdate.id,
        "name": this.state.fieldsValetUpdate.name,
        "email": this.state.fieldsValetUpdate.email,
        "mobile": this.state.fieldsValetUpdate.mobile,
        // "password": this.state.fieldsValetUpdate.password,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsValetUpdate.image,

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>000000000", reqData);

      this.props.dispatch(valetActions.updateValetUser(reqData, paginationData));
    }

  }

  handleValidationUpdateCategory = () => {
    let fieldsValetUpdate = this.state.fieldsValetUpdate;
    let errorsUpdateValet = {};
    let formIsValid = true;

    //name
    if (!fieldsValetUpdate["name"] || fieldsValetUpdate["name"] === "") {
      formIsValid = false;
      errorsUpdateValet["name"] = "Cannot be empty";
    }
    //Email
    if (!fieldsValetUpdate["email"] || fieldsValetUpdate["email"] === "") {
      formIsValid = false;
      errorsUpdateValet["email"] = "Please Enter Email Address";
    }
    if (typeof fieldsValetUpdate["email"] !== "undefined" && fieldsValetUpdate["email"] !== "") {
      let lastAtPos = fieldsValetUpdate["email"].lastIndexOf('@');
      let lastDotPos = fieldsValetUpdate["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fieldsValetUpdate["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fieldsValetUpdate["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errorsUpdateValet["email"] = "Enter valid email!";
      }
    }

    this.setState({ errorsUpdateValet: errorsUpdateValet });
    return formIsValid;
  }

  handleValidationAddUser = () => {
    let fieldsValet = this.state.fieldsValet;
    let errorsValet = {};
    let formIsValid = true;

    //name
    if (!fieldsValet["name"] || fieldsValet["name"] === "") {
      formIsValid = false;
      errorsValet["name"] = "Cannot be empty name";
    }
    //Email
    if (!fieldsValet["email"] || fieldsValet["email"] === "") {
      formIsValid = false;
      errorsValet["email"] = "Please Enter Email Address";
    }
    if (typeof fieldsValet["email"] !== "undefined" && fieldsValet["email"] !== "") {
      let lastAtPos = fieldsValet["email"].lastIndexOf('@');
      let lastDotPos = fieldsValet["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fieldsValet["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fieldsValet["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errorsValet["email"] = "Enter valid email!";
      }
    }

    this.setState({ errorsValet: errorsValet });
    return formIsValid;
  }

  handleSelectType = (e) => {
    console.log("handleSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }



  handleSelectRankType = (e) => {
    console.log("handleSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleUpdateSelectType = (e) => {
    console.log("handleUpdateSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }

  handleUpdateSelectGameType = (e) => {
    console.log("handleUpdateSelectGameType:::#", e.target.value);
    let { value } = e.target;
    this.setState({ gameType: value });
  }

  handleUpdateSelectRankType = (e) => {
    console.log("handleUpdateSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleFile = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.setState({ selectedFile: null });
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));

    }
    else {
      console.log("No File To Upload!")
    }

  }


  inputChangeUpdatePasswordUser = (e) => {
    e.preventDefault();
    console.log("1111111111111", e.target.value);
    let { name, value } = e.target;
    let fieldsUserPasswordUpdate = this.state.fieldsUserPasswordUpdate;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUserPasswordUpdate[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUserPasswordUpdate, errorsUpdatePassword });
  }
  handlePasswordModal = (data) => {
    this.setState({ creditpasswordOpenModal: true, fieldsUserPasswordUpdate: data })
  }

  handleCreditpasswordHideModal = () => {
    this.setState({ creditpasswordOpenModal: false })
  }


  updateUserPasswordSubmit = () => {
    // let { users } = this.props;
    // let { filesDetails } = users;

    if (this.handleValidationUpdateUserPassword()) {
      let reqData = {
        "id": this.state.fieldsUserPasswordUpdate.id,
        "password": this.state.fieldsUserPasswordUpdate.password,

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>", reqData);

      this.props.dispatch(valetActions.updateValetUserPassword(reqData, paginationData));
    }

  }

  handleValidationUpdateUserPassword = () => {
    let fieldsUserPasswordUpdate = this.state.fieldsUserPasswordUpdate;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //name
    if (!fieldsUserPasswordUpdate["password"] || fieldsUserPasswordUpdate["password"] === "") {
      formIsValid = false;
      errorsUpdatePassword["password"] = "Cannot be empty";
    }


    console.log("errorsUpdatePassworderrorsUpdatePassworderrorsUpdatePassword", errorsUpdatePassword);
    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }

  inputChangeIsVideo = (e) => {
    e.preventDefault();
    this.setState({ isVideo: !this.state.isVideo });
  }

  inputChangeIsVideoUpdate = (e) => {
    e.preventDefault();
    this.setState({ isVideoUpdate: !this.state.isVideoUpdate });
  }

  render() {

    let { valet, users } = this.props;
    let { items, total, loading } = valet;
    let { filesDetails } = users;
    // let { allValet } = valet;
    // console.log("RENDER111111111111111", items);
    // console.log("this.state.imageName", this.state.imageName);



    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-x-auto overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="p-6 space-y-6 bg-white border rounded-md">

                  <div className="flex flex-wrap items-center justify-between w-full ">
                    <div className="">
                      <h3 className="text-xl font-semibold ">Valet Employee Management</h3>
                    </div>

                    <div className='flex space-x-6 '>

                      <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form>

                      <button className="flex items-center px-2 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md " onClick={() => this.handleOpenCreateModal()}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" />  Add Valet Employee</button>

                    </div>

                  </div>





                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Employee name</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Employee email</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">mobile no</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">image</th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">image</th> */}
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Date/Time</th>

                                {/* <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Password </th> */}

                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Status </th>
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm font-medium text-gray-800 capitalize whitespace-nowrap" title={element.name} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element.name && element.name.length > 50 ? element.name.substring(0, 25) + "..." : element.name}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.email && element.email ? element.email : "-"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.mobile && element.mobile ? element.mobile : "-"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <div className='flex justify-center'>
                                          <img className="object-cover h-10 rounded-sm w-14" src={element && element.imageLinkUrl
                                            ? element.imageLinkUrl
                                            : "/dist/img/profile.png"} alt="" />
                                        </div>
                                      </td>
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <div className='flex justify-center'>
                                          <img className="object-cover h-10 rounded-sm w-14" src={element && element.imageLinkUrl ? element.imageLinkUrl : "NA"} alt="not found" />
                                        </div>
                                      </td> */}

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>




                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap ">
                                        <div class="flex justify-center">
                                          <label class="flex items-center cursor-pointer targetablepx-4 tooltip">
                                            <div class="relative" onClick={() => this.disableUser(element)}>
                                              <input type="checkbox" id="toggleB" class="sr-only" />
                                              <div class="block bg-gray-600 w-10 h-6 rounded-full"></div>
                                              <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                              <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>Enable / Disable</span>
                                            </div>
                                          </label>
                                        </div>
                                      </td> */}

                                      { /* <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">

                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-gray-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-gray-400 cursor-pointer" onClick={() => this.handlePasswordModal(element)}>

                                            <label>Password</label>
                                          </div>

                                        </span>
                                    </td>*/}

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isDisable === false ?
                                            <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUser(element)}>
                                              <label>Enabled</label>
                                            </div>
                                            : <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUser(element)}>
                                              <label>Disabled</label>
                                            </div>}
                                        </span>
                                      </td>


                                      <td className="flex justify-center px-2 py-3 space-x-2 text-center text-gray-600 whitespace-nowrap">
                                        <div className='flex space-x-2'>

                                          <span className="relative ">
                                            {element.isActive ? "ADDED" : <div class="targetablepx-4 tooltip p-2 rounded-full font-medium hover:bg-blue-100 cursor-pointer  " onClick={() => this.handlePasswordModal(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>Password</span>
                                              <RiLockPasswordLine style={{ fontSize: "1.5rem" }} />
                                            </div>}
                                          </span>

                                          <span className="relative ">
                                            {element.isActive ? "ADDED" : <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer  " onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-6 text-sm text-white bg-gray-500 rounded tooltip-text'>More Details</span>
                                              <BiDetail style={{ fontSize: "1.5rem" }} />
                                            </div>}
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>Edit</span>

                                              <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.deleteCategory(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-2 text-sm text-white bg-gray-500 rounded tooltip-text'>Delete</span>
                                              <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>
                                        </div>
                                      </td>


                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <CreateAddCategoryModal
          addUserCreateModal={this.state.addUserCreateModal}
          fieldsValet={this.state.fieldsValet}
          errorsValet={this.state.errorsValet}

          inputAddUserChange={this.inputAddUserChange}
          handleFile={this.handleFile}
          handleFile2={this.handleFile2}
          createRestoCategorySubmit={this.createRestoCategorySubmit}
          handleSelectType={this.handleSelectType}
          handleSelectGameType={this.handleSelectGameType}
          handleSelectRankType={this.handleSelectRankType}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputChangeIsVideo={this.inputChangeIsVideo}
          isVideo={this.state.isVideo}
          filesDetails={filesDetails}
        />

        <UpdateCategoryModal
          UpdateCategoryCreateModal={this.state.UpdateCategoryCreateModal}
          fieldsValetUpdate={this.state.fieldsValetUpdate}
          errorsUpdateValet={this.state.errorsUpdateValet}
          inputChangeUpdateCategory={this.inputChangeUpdateCategory}
          updateCategorySubmit={this.updateCategorySubmit}

          handleFile={this.handleFile}
          handleFile2={this.handleFile2}
          handleUpdateSelectType={this.handleUpdateSelectType}
          handleUpdateSelectGameType={this.handleUpdateSelectGameType}
          handleUpdateSelectRankType={this.handleUpdateSelectRankType}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
          inputChangeIsVideoUpdate={this.inputChangeIsVideoUpdate}
          isVideoUpdate={this.state.isVideoUpdate}
          filesDetails={filesDetails}
        />

        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          valetRowData={this.state.valetRowData}
          // getProductByCategoryId={getProductByCategoryId}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        <UpdateUserPasswordModal
          creditpasswordOpenModal={this.state.creditpasswordOpenModal}
          fieldsUserPasswordUpdate={this.state.fieldsUserPasswordUpdate}
          errorsUpdatePassword={this.state.errorsUpdatePassword}
          inputChangeUpdatePasswordUser={this.inputChangeUpdatePasswordUser}
          updateUserPasswordSubmit={this.updateUserPasswordSubmit}
          handleAppSeetingHideModal={this.handleCreditpasswordHideModal}
          handleFile={this.handleFile}

        />

        {/* <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        /> */}
      </>

    );
  }
}
function mapStateToProps(state) {
  const { massage, valet, users, product, } = state;
  return {
    massage,
    users,
    product,
    valet
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Valet);
