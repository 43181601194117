import {

 emsConstants,
} from '../_constants';

export function ems(state = {}, action) {
//  console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case emsConstants.GET_ID_EMS_REQUEST:
      return {
        ...state
      };
    case emsConstants.GET_ID_EMS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allems: action.users.getEmsUserById,
      };
    case emsConstants.GET_ID_EMS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case emsConstants.ADD_EMS_REQUEST:
      return {
        ...state
      };
    case emsConstants.ADD_EMS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case emsConstants.ADD_EMS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case emsConstants.GET_LIST_EMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case emsConstants.GET_LIST_EMS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getEMSUserList.list,
        total: action.users.getEMSUserList.total
      };
    case emsConstants.GET_LIST_EMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case emsConstants.GETALL_EMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case emsConstants.GETALL_EMS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allrestaurent: action.users.getRestaurantUserById,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case emsConstants.GETALL_EMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case emsConstants.UPDATE_EMS_REQUEST:
      return {
        ...state
      };
    case emsConstants.UPDATE_EMS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case emsConstants.UPDATE_EMS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case emsConstants.UPDATE_PASSWORD_REQUEST:
      return {
        ...state
      };
    case emsConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case emsConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case emsConstants.DELETE_EMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case emsConstants.DELETE_EMS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case emsConstants.DELETE_EMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case emsConstants.DISABLE_EMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case emsConstants.DISABLE_EMS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case emsConstants.DISABLE_EMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}