export const groupClassConstants = {

    GET_ID_GROUPCLASS_REQUEST: 'GET_ID_GROUPCLASS_REQUEST',
    GET_ID_GROUPCLASS_SUCCESS: 'GET_ID_GROUPCLASS_SUCCESS',
    GET_ID_GROUPCLASS_FAILURE: 'GET_ID_GROUPCLASS_FAILURE',

    GETALL_GROUPCLASS_REQUEST: 'GETALL_GROUPCLASS_REQUEST',
    GETALL_GROUPCLASS_SUCCESS: 'GETALL_GROUPCLASS_SUCCESS',
    GETALL_GROUPCLASS_FAILURE: 'GETALL_GROUPCLASS_FAILURE',

    GET_LIST_GROUPCLASS_REQUEST: 'GET_LIST_GROUPCLASS_REQUEST',
    GET_LIST_GROUPCLASS_SUCCESS: 'GET_LIST_GROUPCLASS_SUCCESS',
    GET_LIST_GROUPCLASS_FAILURE: 'GET_LIST_GROUPCLASS_FAILURE',

    GET_LIST_PARKING_REQUEST: 'GET_LIST_PARKING_REQUEST',
    GET_LIST_PARKING_SUCCESS: 'GET_LIST_PARKING_SUCCESS',
    GET_LIST_PARKING_FAILURE: 'GET_LIST_PARKING_FAILURE',


    ADD_GROUPCLASS_REQUEST: 'ADD_GROUPCLASS_REQUEST',
    ADD_GROUPCLASS_SUCCESS: 'ADD_GROUPCLASS_SUCCESS',
    ADD_GROUPCLASS_FAILURE: 'ADD_GROUPCLASS_FAILURE',

    DELETE_GROUPCLASS_REQUEST: 'DELETE_GROUPCLASS_REQUEST',
    DELETE_GROUPCLASS_SUCCESS: 'DELETE_GROUPCLASS_SUCCESS',
    DELETE_GROUPCLASS_FAILURE: 'DELETE_GROUPCLASS_FAILURE',

    UPDATE_GROUPCLASS_REQUEST: 'UPDATE_GROUPCLASS_REQUEST',
    UPDATE_GROUPCLASS_SUCCESS: 'UPDATE_GROUPCLASS_SUCCESS',
    UPDATE_GROUPCLASS_FAILURE: 'UPDATE_GROUPCLASS_FAILURE',

    UPDATE_GROUPCLASS_PASSWORD_REQUEST: 'UPDATE_GROUPCLASS_PASSWORD_REQUEST',
    UPDATE_GROUPCLASS_PASSWORD_SUCCESS: 'UPDATE_GROUPCLASS_PASSWORD_SUCCESS',
    UPDATE_GROUPCLASS_PASSWORD_FAILURE: 'UPDATE_GROUPCLASS_PASSWORD_FAILURE',

    DISABLE_GROUPCLASS_REQUEST: 'DISABLE_GROUPCLASS_REQUEST',
    DISABLE_GROUPCLASS_SUCCESS: 'DISABLE_GROUPCLASS_SUCCESS',
    DISABLE_GROUPCLASS_FAILURE: 'DISABLE_GROUPCLASS_FAILURE',

};
