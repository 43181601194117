import React from "react";
import { CgLogIn } from 'react-icons/cg';



export default function UpdateQuoteModal(props) {

  let { UpdateQuoteCreateModal, handleUpdatePasswordHideModal, errorsUpdateQuote, inputChangeUpdateUser, updateUserSubmit, fieldsUserUpdate, filesDetails, handleFile } = props;
  console.log('filesDetailsfilesDetailsfilesDetailsfilesDetailsfilesDetailsfilesDetails', filesDetails);
  console.log('fieldsUserUpdatefieldsUserUpdatefieldsUserUpdate', fieldsUserUpdate);

  return (


    <div className={UpdateQuoteCreateModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">
        <div className="flex items-center justify-between p-6 py-2 bg-blue-500 border-b">
          <p className="text-[16px] font-medium text-white uppercase">Update User</p>
          <div className="rounded-full cursor-pointer modal-close ">
            <svg onClick={() => handleUpdatePasswordHideModal()} className="fill-current text-white/60 hover:text-white/100 " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>

        <div className="p-4">
          <button className="text-base text-gray-600 p-4 py-2 border border-gray-300 border-b-transparent bg-white -mb-0.5 font-bold">
            Update {fieldsUserUpdate.name}
          </button>
          <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">
            <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="name">  Name :</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500   ${errorsUpdateQuote && !errorsUpdateQuote["name"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                  id="name" name="name" placeholder="First Name" value={fieldsUserUpdate && fieldsUserUpdate.name ? fieldsUserUpdate.name : null} type="text" onChange={inputChangeUpdateUser} />
                {errorsUpdateQuote && errorsUpdateQuote["name"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsUpdateQuote["name"]}
                  </div>
                  : null}
              </div>

      
              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="number">Number :</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500   ${errorsUpdateQuote && !errorsUpdateQuote["number"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                  id="number" name="number" placeholder="Number" value={fieldsUserUpdate && fieldsUserUpdate.number ? fieldsUserUpdate.number : null} type="text" onChange={inputChangeUpdateUser} />
                {errorsUpdateQuote && errorsUpdateQuote["number"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsUpdateQuote["number"]}
                  </div>
                  : null}
              </div>

              {/* <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="email">Email :</label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdateQuote && !errorsUpdateQuote["email"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                  id="email" name="email" placeholder="email" value={fieldsUserUpdate && fieldsUserUpdate.email ? fieldsUserUpdate.email : null} type="email" onChange={inputChangeUpdateUser} />
                {errorsUpdateQuote && errorsUpdateQuote["email"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsUpdateQuote["email"]}
                  </div>
                  : null}
              </div> */}

              {/* <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Mobile No:</label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdateQuote && !errorsUpdateQuote["mobile"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                  id="mobile" name="mobile" placeholder="Mobile Number" value={fieldsUserUpdate && fieldsUserUpdate.mobile ? fieldsUserUpdate.mobile : null} type="text" onChange={inputChangeUpdateUser} />
                {errorsUpdateQuote && errorsUpdateQuote["mobile"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsUpdateQuote["mobile"]}
                  </div>
                  : null}
              </div> */}

              {/* <div>
                <label for="frm-whatever" className='block text-xs font-medium text-gray-500 dark:text-white'>Gender</label>
                <select className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-gray-500  focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-300 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500  dark:focus:border-blue-500" onChange={inputChangeUpdateUser}
                  id="gender"
                  name="gender">
                  <option value="">{fieldsUserUpdate && fieldsUserUpdate.gender ? fieldsUserUpdate.gender : null} &hellip;</option>
                  <option value="MALE">MALE</option>
                  <option value="FEMALE">FEMALE</option>
                  <option value="OTHER">OTHER</option>
                </select>
              </div> */}

{/* 
              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">dob :</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdateQuote && !errorsUpdateQuote["dob"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                  id="dob" name="dob" placeholder="Date Of Birth" value={fieldsUserUpdate && fieldsUserUpdate.dob ? fieldsUserUpdate.dob : null} type="date" onChange={inputChangeUpdateUser} />
                {errorsUpdateQuote && errorsUpdateQuote["dob"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsUpdateQuote["dob"]}
                  </div>
                  : null}
              </div> */}

              {/* <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Pin Code :</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdateQuote && !errorsUpdateQuote["pin"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                  id="pin" name="pin" placeholder="Pin Code " value={fieldsUserUpdate && fieldsUserUpdate.pin ? fieldsUserUpdate.pin : null} type="pin" onChange={inputChangeUpdateUser} />
                {errorsUpdateQuote && errorsUpdateQuote["pin"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsUpdateQuote["pin"]}
                  </div>
                  : null}
              </div> */}
              {/* 
              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Password :</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdateQuote && !errorsUpdateQuote["password"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                  id="password" name="password" placeholder="Password" value={fieldsUserUpdate && fieldsUserUpdate.password ? fieldsUserUpdate.password : null} type="password" onChange={inputChangeUpdateUser} />
                {errorsUpdateQuote && errorsUpdateQuote["password"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsUpdateQuote["password"]}
                  </div>
                  : null}
              </div> */}

              {/* <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Confirm Password :</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdateQuote && !errorsUpdateQuote["confirmPassword"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                  id="confirmPassword" name="confirmPassword" placeholder="Confirm Password" value={fieldsUserUpdate && fieldsUserUpdate.confirmPassword ? fieldsUserUpdate.confirmPassword : null} type="Password" onChange={inputChangeUpdateUser} />
                {errorsUpdateQuote && errorsUpdateQuote["confirmPassword"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsUpdateQuote["confirmPassword"]}
                  </div>
                  : null}
              </div> */}

            </div>

            {/* {

              fieldsUserUpdate && fieldsUserUpdate.imageLinkUrl ?


                <div className="flex justify-between px-4"   >
                  <div className="flex flex-wrap w-full mt-4 ">

                    <input id="image" name="image" type="file" onChange={handleFile} />
                  </div>

                  <div className='h-8 '>
                    <img className="object-cover w-24 h-20 rounded-sm" src={fieldsUserUpdate && fieldsUserUpdate.imageLinkUrl ? fieldsUserUpdate.imageLinkUrl : null} alt="not found" />
                  </div>
                  <div className='h-8 '>
                    <img className="object-cover w-24 h-20 rounded-sm" src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : null} alt="not found" />
                  </div>
                </div>
                :
                null
            } */}



            {

              filesDetails && filesDetails.imageURL ?

                <div className=""   >

                  <div className='flex justify-between px-4 '>
                    <img className="object-cover w-24 h-20 rounded-sm" src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : null} alt="not found" />
                  </div>
                  <div className="flex flex-wrap w-full mt-4 ">

                    <input id="image" name="image" type="file" onChange={handleFile} />
                  </div>
                </div>
                :


                <div>
                  <div className='flex justify-between px-4'>
                    <img className="object-cover w-24 h-20 rounded-sm" src={fieldsUserUpdate && fieldsUserUpdate.imageLinkUrl ? fieldsUserUpdate.imageLinkUrl : null} alt="not found" />
                  </div>

                  <div className="flex flex-wrap w-full mt-4 ">

                    <input id="image" name="image" type="file" onChange={handleFile} />
                  </div>
                </div>



            }



            {/* btns */}
            <div className="flex justify-end pt-10 space-x-4">
              <button className="bg-blue-500  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
                onClick={updateUserSubmit}
              >Submit
                <CgLogIn size={18} className="ml-2" />
              </button>
            </div>

          </form>

        </div>
        {/*Footer*/}

      </div >
    </div >

  );
}
