import React, { Component } from 'react';
import { connect } from 'react-redux';
import { plotActions, sectionActions, userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import CreateAddCategoryModal from "./components/CreateAddCategoryModal/CreateAddCategoryModal";
import UpdateCategoryModal from "./components/UpdateCategoryModal/UpdateCategoryModal";
import UpdateUserPasswordModal from './components/UpdateUserPasswordModal/UpdateUserPasswordModal';
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";
// import { RiLockPasswordLine } from "react-icons/ri"; 

class groupClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideo: false,
      isVideoUpdate: false,
      groupClassRowData: {},
      fieldsgroupClass: {},
      errorsgroupClass: {},
      fieldsgroupClassUpdate: {},
      errorsUpdategroupClass: {},
      viewRowData: {},
      type: null,
      gameType: null,
      rankType: null,
      creditpasswordOpenModal: false,
      errorsUpdatePassword: {},
      fieldsUserPasswordUpdate: {},
      selectedPlayer: [""],
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCategoryCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      offset: 0,
      size: 10,
      fieldDropDounState: '',
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    // let reqData = { "id": "642e67eae8b9716b9142c41b"}
    this.props.dispatch(plotActions.getPlotList(temp))
    this.props.dispatch(sectionActions.getAllSectionList(temp))
    this.props.dispatch(userActions.getUserList());

  }
  static getDerivedStateFromProps(nextProps, prevState) {

    // console.log("STATIC______nextProps.plot.addUserSuccess", nextProps.plot.addUserSuccess);

    if (nextProps.plot.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsgroupClass: {},
        errorsgroupClass: {},
        creditpasswordOpenModal: false,
        errorsUpdatePassword: {},
        fieldsUserPasswordUpdate: {},
        addUserCreateModal: false,
        UpdateCategoryCreateModal: false,
        moreDetailsCreateModal: false,

        type: null,
        gameType: null,
        rankType: null,

      }
    }


    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "sectionId": this.state.fieldDropDounState,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(plotActions.getPlotList(datatemp));
    this.props.dispatch(sectionActions.getAllSectionList());
    // this.props.dispatch(sectionActions.getAllSection());
  }
  handleSearch = (event) => {
    // console.log("666666666666666", event.target.value);
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(plotActions.getPlotList(data));
  }
  handleSelect = (e) => {
    // e.preventDefault(e);
    this.setState({ fieldDropDounState: e.target.value })
    let data = {
      "sectionId": e.target.value,
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(plotActions.getPlotList(data));
    console.log("5555555555555555555", data);

  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status of User?',
      message: `Are you sure about ${data.plotno}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(plotActions.disablegroupClass(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteCategory = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.plotno}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(plotActions.deleteGroup(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  handleOpenCreateModalMoreDetails = (data) => {
    // let req = {
    //     "id": data.id
    //   }
    //   this.props.dispatch(plotActions.getgroupClassUserById(req));

    this.setState({ moreDetailsCreateModal: true, groupClassRowData: data });
    //  console.log("fgfdgf1111111111111111111111111111111111",req);
  }

  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }

  handleOpenCreateModalUpdatePassword = (data) => {
    this.setState({ UpdateCategoryCreateModal: true, fieldsgroupClassUpdate: data });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdateCategoryCreateModal: false });
  }

  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({ addUserCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }

  handleOpenCreateModal = () => {
    this.setState({ addUserCreateModal: true });
  }
  inputAddUserChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsgroupClass = this.state.fieldsgroupClass;
    let errorsgroupClass = this.state.errorsgroupClass;
    fieldsgroupClass[name] = value;
    console.log(name, value);
    errorsgroupClass[name] = "";
    this.setState({ fieldsgroupClass, errorsgroupClass });
  }
  inputChangeUpdateCategory = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsgroupClassUpdate = this.state.fieldsgroupClassUpdate;
    let errorsUpdategroupClass = this.state.errorsUpdategroupClass;
    fieldsgroupClassUpdate[name] = value;
    errorsUpdategroupClass[name] = "";
    console.log(name, value);
    this.setState({ fieldsgroupClassUpdate, errorsUpdategroupClass });
  }

  createRestoCategorySubmit = () => {

    let { users } = this.props;


    let { filesDetails,
    } = users;
    console.log('filesDetailsfilesDetails__________', filesDetails);
    if (this.handleValidationAddUser()) {

      let playerArray = [];

      this.state.selectedPlayer.forEach(element => (
        playerArray.push(
          element.value
        )
      ));


      let reqData = {
        "plotno": this.state.fieldsgroupClass.plotno,
        "roadsize": this.state.fieldsgroupClass.roadsize,
        "size": this.state.fieldsgroupClass.size,
        "sectionId": this.state.fieldsgroupClass.sectionId,
        "desc": this.state.fieldsgroupClass.desc,

      }

      console.log("createRestoCategory>>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(plotActions.createPlot(reqData));
    }

  }


  updateCategorySubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;

    if (this.handleValidationUpdateCategory()) {
      let reqData = {
        "id": this.state.fieldsgroupClassUpdate.id,
        "plotno": this.state.fieldsgroupClassUpdate.plotno,
        "roadsize": this.state.fieldsgroupClassUpdate.roadsize,
        "size": this.state.fieldsgroupClassUpdate.size,
        "sectionId": this.state.fieldsgroupClassUpdate.sectionId,
        "desc": this.state.fieldsgroupClassUpdate.desc,

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>000000000", reqData);

      this.props.dispatch(plotActions.updatePlot(reqData, paginationData));
    }

  }

  handleValidationUpdateCategory = () => {
    let fieldsgroupClassUpdate = this.state.fieldsgroupClassUpdate;
    let errorsUpdategroupClass = {};
    let formIsValid = true;

    //plotno
    if (!fieldsgroupClassUpdate["plotno"] || fieldsgroupClassUpdate["plotno"] === "") {
      formIsValid = false;
      errorsUpdategroupClass["plotno"] = "Cannot be empty";
    }
    //roadsize
    if (!fieldsgroupClassUpdate["roadsize"] || fieldsgroupClassUpdate["roadsize"] === "") {
      formIsValid = false;
      errorsUpdategroupClass["roadsize"] = "Please Enter roadsize Address";
    }

    if (!fieldsgroupClassUpdate["sectionId"] || fieldsgroupClassUpdate["sectionId"] === "") {
      formIsValid = false;
      errorsUpdategroupClass["sectionId"] = "Cannot be empty sectionId";
    }

    if (!fieldsgroupClassUpdate["size"] || fieldsgroupClassUpdate["size"] === "") {
      formIsValid = false;
      errorsUpdategroupClass["size"] = "Cannot be empty size";
    }

    if (!fieldsgroupClassUpdate["desc"] || fieldsgroupClassUpdate["desc"] === "") {
      formIsValid = false;
      errorsUpdategroupClass["desc"] = "Cannot be empty desc";
    }




    this.setState({ errorsUpdategroupClass: errorsUpdategroupClass });
    return formIsValid;
  }

  handleValidationAddUser = () => {
    let fieldsgroupClass = this.state.fieldsgroupClass;
    let errorsgroupClass = {};
    let formIsValid = true;

    if (!fieldsgroupClass["plotno"] || fieldsgroupClass["plotno"] === "") {
      formIsValid = false;
      errorsgroupClass["plotno"] = "Cannot be empty plotno";
    }
    if (!fieldsgroupClass["roadsize"] || fieldsgroupClass["roadsize"] === "") {
      formIsValid = false;
      errorsgroupClass["roadsize"] = "Cannot be empty roadsize";
    }
    if (!fieldsgroupClass["sectionId"] || fieldsgroupClass["sectionId"] === "") {
      formIsValid = false;
      errorsgroupClass["sectionId"] = "Cannot be empty sectionId";
    }
    if (!fieldsgroupClass["size"] || fieldsgroupClass["size"] === "") {
      formIsValid = false;
      errorsgroupClass["size"] = "Cannot be empty size";
    }

    if (!fieldsgroupClass["desc"] || fieldsgroupClass["desc"] === "") {
      formIsValid = false;
      errorsgroupClass["desc"] = "Cannot be empty desc";
    }

    this.setState({ errorsgroupClass: errorsgroupClass });
    return formIsValid;
  }

  handleSelectType = (e) => {
    console.log("handleSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }



  handleSelectRankType = (e) => {
    console.log("handleSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleUpdateSelectType = (e) => {
    console.log("handleUpdateSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }

  handleUpdateSelectGameType = (e) => {
    console.log("handleUpdateSelectGameType:::#", e.target.value);
    let { value } = e.target;
    this.setState({ gameType: value });
  }

  handleUpdateSelectRankType = (e) => {
    console.log("handleUpdateSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleFile = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.setState({ selectedFile: null });
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));

    }
    else {
      console.log("No File To Upload!")
    }

  }

  inputChangeUpdatePasswordUser = (e) => {
    e.preventDefault();
    console.log("1111111111111", e.target.value);
    let { name, value } = e.target;
    let fieldsUserPasswordUpdate = this.state.fieldsUserPasswordUpdate;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUserPasswordUpdate[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUserPasswordUpdate, errorsUpdatePassword });
  }
  handlePasswordModal = (data) => {
    this.setState({ creditpasswordOpenModal: true, fieldsUserPasswordUpdate: data })
  }

  handleCreditpasswordHideModal = () => {
    this.setState({ creditpasswordOpenModal: false })
  }


  updateUserPasswordSubmit = () => {
    // let { users } = this.props;
    // let { filesDetails } = users;

    if (this.handleValidationUpdateUserPassword()) {
      let reqData = {
        "id": this.state.fieldsUserPasswordUpdate.id,
        "password": this.state.fieldsUserPasswordUpdate.password,

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>", reqData);

      this.props.dispatch(plotActions.updateGroupClassUserPassword(reqData, paginationData));
    }

  }

  handleValidationUpdateUserPassword = () => {
    let fieldsUserPasswordUpdate = this.state.fieldsUserPasswordUpdate;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //name
    if (!fieldsUserPasswordUpdate["password"] || fieldsUserPasswordUpdate["password"] === "") {
      formIsValid = false;
      errorsUpdatePassword["password"] = "Cannot be empty";
    }


    console.log("errorsUpdatePassworderrorsUpdatePassworderrorsUpdatePassword", errorsUpdatePassword);
    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }



  inputChangeIsVideo = (e) => {
    e.preventDefault();
    this.setState({ isVideo: !this.state.isVideo });
  }

  inputChangeIsVideoUpdate = (e) => {
    e.preventDefault();
    this.setState({ isVideoUpdate: !this.state.isVideoUpdate });
  }
  handleSelectedPlayer = (selectedPlayer) => {
    console.log("selectedPlayerselectedPlayer  ", selectedPlayer);
    this.setState({ selectedPlayer });
  };

  render() {

    let { plot, users, section } = this.props;
    let { getPlotList, total, loading } = plot;
    let { getAllSection } = section;
    let { filesDetails, getUserListItems } = users;
    // let { allgroupClass } = plot;
    console.log("1111111111111111111111111111111111111111111111111111111111111111111", getPlotList);

    console.log('this.state.fieldDropDounState', this.state.fieldDropDounState);

    // console.log("selectedPlayer", this.state.selectedPlayer);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-x-auto overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="p-6 space-y-6 bg-white border rounded-md">

                  <div className="flex flex-wrap items-center justify-between w-full ">
                    <div className="">
                      <h3 className="text-xl font-semibold "> Plot List Management</h3>
                    </div>


                    <div className="flex space-x-6  ">
                      <select class="form-select border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onClick={this.handleSelect} id="sectionId" name="sectionId"
                        value={this.state.fieldsgroupClass && this.state.fieldsgroupClass["sectionId"] ? this.state.fieldsgroupClass["sectionId"] : null} >
                        <option selected>Please Select Section</option>
                        {
                          getAllSection && getAllSection && getAllSection.length > 0 ?
                            getAllSection.map((element, index) => (
                              <option value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                            )) : null
                        }
                      </select>
                      {this.state.errorsgroupClass && this.state.errorsgroupClass["sectionId"] ?
                        <div className="invalid-feedback text-red-600 text-xs">
                          {this.state.errorsgroupClass["sectionId"]}
                        </div>
                        : null}
                    </div>




                    <div className='flex space-x-6 '>

                      <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" name="plotno" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form>

                      <button className="flex items-center px-2 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md " onClick={() => this.handleOpenCreateModal()}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" />  Add  Plot  </button>



                    </div>

                  </div>


                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">plotNo</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">roadSize</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">size</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">desc</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">section</th>
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th>
                              </tr>
                            </thead>


                            <tbody>
                              {

                                getPlotList && getPlotList.length > 0 ?
                                  getPlotList.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm font-medium text-gray-800 capitalize whitespace-nowrap" title={element.plotno} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element.plotno && element.plotno.length > 50 ? element.plotno.substring(0, 25) + "..." : element.plotno}
                                      </td>
                                      <td className="px-6 py-3 text-sm font-medium text-gray-800 capitalize whitespace-nowrap" title={element.roadsize} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element.roadsize && element.roadsize.length > 50 ? element.roadsize.substring(0, 25) + "..." : element.roadsize}
                                      </td>

                                      <td className="px-6 py-3 text-sm font-medium text-gray-800 capitalize whitespace-nowrap" title={element.size} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element.size && element.size.length > 50 ? element.size.substring(0, 25) + "..." : element.size}
                                      </td>

                                      <td className="px-6 py-3 text-sm font-medium text-gray-800 capitalize whitespace-nowrap" title={element.desc} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element.desc && element.desc.length > 50 ? element.desc.substring(0, 25) + "..." : element.desc}
                                      </td>
                                      <td className="px-6 py-3 text-sm font-medium text-gray-800 capitalize whitespace-nowrap" title={element.sectionId} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element && element.sectionId && element.sectionId.name ? element.sectionId.name : "-"}
                                      </td>



                                      <td className="flex justify-center px-2 py-3 space-x-2 text-center text-gray-600 whitespace-nowrap">

                                        <div className='flex space-x-2'>
                                          <span className="relative ">
                                            {element.isActive ? "ADDED" : <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer  " onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-6 text-sm text-white bg-gray-500 rounded tooltip-text'>More Details</span>
                                              <BiDetail style={{ fontSize: "1.5rem" }} />
                                            </div>}
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>Edit</span>

                                              <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.deleteCategory(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-2 text-sm text-white bg-gray-500 rounded tooltip-text'>Delete</span>
                                              <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>
                                        </div>
                                      </td>


                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <CreateAddCategoryModal
          addUserCreateModal={this.state.addUserCreateModal}
          fieldsgroupClass={this.state.fieldsgroupClass}
          errorsgroupClass={this.state.errorsgroupClass}
          getUserListItems={getUserListItems}
          getAllSection={getAllSection}
          inputAddUserChange={this.inputAddUserChange}
          handleFile={this.handleFile}
          handleFile2={this.handleFile2}
          createRestoCategorySubmit={this.createRestoCategorySubmit}
          handleSelectType={this.handleSelectType}
          handleSelectGameType={this.handleSelectGameType}
          handleSelectRankType={this.handleSelectRankType}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputChangeIsVideo={this.inputChangeIsVideo}
          isVideo={this.state.isVideo}
          filesDetails={filesDetails}
          handleSelectedPlayer={this.handleSelectedPlayer}
          selectedPlayer={this.state.selectedPlayer}
        />

        <UpdateCategoryModal
          UpdateCategoryCreateModal={this.state.UpdateCategoryCreateModal}
          fieldsgroupClassUpdate={this.state.fieldsgroupClassUpdate}
          errorsUpdategroupClass={this.state.errorsUpdategroupClass}
          inputChangeUpdateCategory={this.inputChangeUpdateCategory}
          updateCategorySubmit={this.updateCategorySubmit}
          getUserListItems={getUserListItems}
          getAllSection={getAllSection}
          handleFile={this.handleFile}
          handleFile2={this.handleFile2}
          handleUpdateSelectType={this.handleUpdateSelectType}
          handleUpdateSelectGameType={this.handleUpdateSelectGameType}
          handleUpdateSelectRankType={this.handleUpdateSelectRankType}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
          inputChangeIsVideoUpdate={this.inputChangeIsVideoUpdate}
          isVideoUpdate={this.state.isVideoUpdate}
          filesDetails={filesDetails}
        />

        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          groupClassRowData={this.state.groupClassRowData}
          // getProductByCategoryId={getProductByCategoryId}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        <UpdateUserPasswordModal
          creditpasswordOpenModal={this.state.creditpasswordOpenModal}
          fieldsUserPasswordUpdate={this.state.fieldsUserPasswordUpdate}
          errorsUpdatePassword={this.state.errorsUpdatePassword}
          inputChangeUpdatePasswordUser={this.inputChangeUpdatePasswordUser}
          updateUserPasswordSubmit={this.updateUserPasswordSubmit}
          handleAppSeetingHideModal={this.handleCreditpasswordHideModal}
          handleFile={this.handleFile}

        />

        {/* <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        /> */}
      </>

    );
  }
}
function mapStateToProps(state) {
  const { massage, plot, users, product, section } = state;
  return {
    massage,
    users,
    product,
    plot,
    section
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(groupClass);
