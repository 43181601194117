import {

 massageSlotConstants,
} from '../_constants';

export function massageSlot(state = {}, action) {
//  console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case massageSlotConstants.GET_ID_MASSAGE_SLOT_REQUEST:
      return {
        ...state
      };
    case massageSlotConstants.GET_ID_MASSAGE_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allems: action.users.getEmsUserById,
      };
    case massageSlotConstants.GET_ID_MASSAGE_SLOT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case massageSlotConstants.ADD_MASSAGE_SLOT_REQUEST:
      return {
        ...state
      };
    case massageSlotConstants.ADD_MASSAGE_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case massageSlotConstants.ADD_MASSAGE_SLOT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case massageSlotConstants.GET_LIST_MASSAGE_SLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case massageSlotConstants.GET_LIST_MASSAGE_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getMassageList.list,
        total: action.users.getMassageList.total
      };
    case massageSlotConstants.GET_LIST_MASSAGE_SLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case massageSlotConstants.GET_LIST_MASSAGE_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case massageSlotConstants.GET_LIST_MASSAGE_BOOK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      massageBookSlot: action.users.getShowMassageBookSlot,
        currentDate: action.users.getShowMassageBookSlot.currentDate
      };
    case massageSlotConstants.GET_LIST_MASSAGE_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case massageSlotConstants.GETALL_MASSAGE_SLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case massageSlotConstants.GETALL_MASSAGE_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allrestaurent: action.users.getRestaurantUserById,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case massageSlotConstants.GETALL_MASSAGE_SLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case massageSlotConstants.UPDATE_MASSAGE_SLOT_REQUEST:
      return {
        ...state
      };
    case massageSlotConstants.UPDATE_MASSAGE_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case massageSlotConstants.UPDATE_MASSAGE_SLOT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case massageSlotConstants.DELETE_MASSAGE_SLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case massageSlotConstants.DELETE_MASSAGE_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case massageSlotConstants.DELETE_MASSAGE_SLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case massageSlotConstants.DISABLE_MASSAGE_SLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case massageSlotConstants.DISABLE_MASSAGE_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case massageSlotConstants.DISABLE_MASSAGE_SLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case massageSlotConstants.DISABLE_MASSAGE_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case massageSlotConstants.DISABLE_MASSAGE_BOOK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case massageSlotConstants.DISABLE_MASSAGE_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}