export const emsConstants = {

    GET_ID_EMS_REQUEST: 'GET_ID_EMS_REQUEST',
    GET_ID_EMS_SUCCESS: 'GET_ID_EMS_SUCCESS',
    GET_ID_EMS_FAILURE: 'GET_ID_EMS_FAILURE',

    GETALL_MASSAGE_REQUEST: 'GETALL_MASSAGE_REQUEST',
    GETALL_MASSAGE_SUCCESS: 'GETALL_MASSAGE_SUCCESS',
    GETALL_MASSAGE_FAILURE: 'GETALL_MASSAGE_FAILURE',

    GET_LIST_EMS_REQUEST: 'GET_LIST_EMS_REQUEST',
    GET_LIST_EMS_SUCCESS: 'GET_LIST_EMS_SUCCESS',
    GET_LIST_EMS_FAILURE: 'GET_LIST_EMS_FAILURE',


    ADD_EMS_REQUEST: 'ADD_EMS_REQUEST',
    ADD_EMS_SUCCESS: 'ADD_EMS_SUCCESS',
    ADD_EMS_FAILURE: 'ADD_EMS_FAILURE',

    DELETE_EMS_REQUEST: 'DELETE_EMS_REQUEST',
    DELETE_EMS_SUCCESS: 'DELETE_EMS_SUCCESS',
    DELETE_EMS_FAILURE: 'DELETE_EMS_FAILURE',

    UPDATE_EMS_REQUEST: 'UPDATE_EMS_REQUEST',
    UPDATE_EMS_SUCCESS: 'UPDATE_EMS_SUCCESS',
    UPDATE_EMS_FAILURE: 'UPDATE_EMS_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

    DISABLE_EMS_REQUEST: 'DISABLE_EMS_REQUEST',
    DISABLE_EMS_SUCCESS: 'DISABLE_EMS_SUCCESS',
    DISABLE_EMS_FAILURE: 'DISABLE_EMS_FAILURE',

};
