import React from "react";
import { CgLogIn } from 'react-icons/cg';
// import Multiselect from 'multiselect-react-dropdown';



export default function CreateAddCategoryModal(props) {

  let { addUserCreateModal, handleAddUserHideModal, handleSelectedPlayer, selectedPlayer, errorsgroupClass, getUserListItems, inputAddUserChange, createRestoCategorySubmit, fieldsgroupClass, handleFile, filesDetails } = props;
  console.log("fieldsgroupClassfieldsgroupClassfieldsgroupClass", fieldsgroupClass);
  // let colourOptions = []
  // if (getUserListItems && getUserListItems.length > 0) {
  //   getUserListItems.forEach((user) => {
  //     colourOptions.push(
  //       {
  //         value: user.firstname,
  //         label: user.firstname,
  //         id: user.id,
  //       }
  //     )
  //   })
  // }
  // console.log("=================>", colourOptions);
  return (
    <div className={addUserCreateModal ? "fixed w-full top-0 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto mt-2">
        {/*modal header*/}
        <div className="flex items-center justify-between p-6 py-2 bg-blue-500 border-b">
          <p className="text-[16px] font-medium text-white uppercase">Add Plot </p>
          <div className="rounded-full cursor-pointer modal-close ">
            <svg onClick={() => handleAddUserHideModal()} className="fill-current text-white/60 hover:text-white/100 " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>

        <div className="p-4">
          <button className="text-sm text-gray-600 font-medium p-4 py-2 border border-gray-300 border-b-transparent bg-white -mb-0.5">
            Create Plot
          </button>
          <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">

            {/* <div class=" w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left">Member:</label>
              <select class="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example" id="member"
                firstname="member"
                value={fieldsgroupClass && fieldsgroupClass["member"] ? fieldsgroupClass["member"] : null}
                // name="courseId"
                onChange={inputAddUserChange}
              >
                <option selected>Plz Select Member</option>
                {
                  getUserListItems && getUserListItems && getUserListItems.length > 0 ?
                    getUserListItems.map((element, index) => (
                      <option key={index} name={element && element.firstname ? element.firstname : null} value={element && element.id ? element.id : null}>{element && element.firstname ? element.firstname : "NA"}</option>
                    )) : null
                } 
              </select>

              <div class="flex justify-center w-full">
                <div class="mb-3 w-full">
                  <label for="countries" className="block text-xs font-medium text-gray-500 md:text-left"></label>
                  <Multiselect
                    options={colourOptions}
                    onSelect={handleSelectedPlayer}
                    onRemove={handleSelectedPlayer}
                    selected={selectedPlayer}
                    isObject={true}
                    displayValue="label"
                  />

                </div>
              </div>
              {errorsgroupClass && errorsgroupClass["member"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsgroupClass["member"]}
                </div>
                : null}
            </div> */}
            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">plotNo :</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 {errorsgroupClass && !errorsgroupClass["name"] ? "border placeholder-gray-500" : "border   "}`}
                id="name" name="name" placeholder="plotno" value={fieldsgroupClass.plotno} type="text" onChange={inputAddUserChange} />
              {errorsgroupClass && errorsgroupClass["plotno"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsgroupClass["plotno"]}roadsize
                </div>
                : null}
            </div>
            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">roadSize:</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 {errorsgroupClass && !errorsgroupClass["name"] ? "border placeholder-gray-500" : "border   "}`}
                id="name" name="name" placeholder="roadSize" value={fieldsgroupClass.roadsize} type="text" onChange={inputAddUserChange} />
              {errorsgroupClass && errorsgroupClass["roadsize"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsgroupClass["roadsize"]}
                </div>
                : null}
            </div><div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">section:</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 {errorsgroupClass && !errorsgroupClass["name"] ? "border placeholder-gray-500" : "border   "}`}
                id="name" name="name" placeholder="section" value={fieldsgroupClass.sectionId} type="text" onChange={inputAddUserChange} />
              {errorsgroupClass && errorsgroupClass["sectionId"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsgroupClass["sectionId"]}
                </div>
                : null}
            </div>
            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">size:</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 {errorsgroupClass && !errorsgroupClass["name"] ? "border placeholder-gray-500" : "border   "}`}
                id="name" name="name" placeholder="size" value={fieldsgroupClass.size} type="text" onChange={inputAddUserChange} />
              {errorsgroupClass && errorsgroupClass["size"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsgroupClass["size"]}
                </div>
                : null}
            </div>
            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Description :</label>
              <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsgroupClass && !errorsgroupClass["description"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                id="description" name="description" placeholder="Description" value={fieldsgroupClass.desc} type="text" onChange={inputAddUserChange} />
              {errorsgroupClass && errorsgroupClass["desc"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsgroupClass["desc"]}
                </div>
                : null}
            </div>



            {/* <div className="flex justify-between px-2"   >
              <div className="flex flex-wrap w-full py-2">
                <label htmlFor="image">
                  Upload Image of Trainer
                </label>
                <input id="image" name="image" type="file" onChange={handleFile} />
                <div className="flex mt-1 space-x-4">

                </div>
              </div>


              {
                filesDetails && filesDetails.imageURL ?
                  <>
                    <div>
                      <div className='flex justify-center'>
                        <img className="object-cover h-10 rounded-sm w-14" src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : null} alt="not found" />
                      </div>
                    </div>
                  </> :
                  <>
                    <div>
                      <div className='flex justify-center'>
                        <img className="object-cover h-10 rounded-sm w-14" src="/dist/img/profile.png" alt="not found" />
                      </div>
                    </div>
                  </>
              }





            </div> */}

            {/* btns */}
            <div className="flex justify-end py-0 space-x-4">
              <button className="bg-blue-500  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
                onClick={createRestoCategorySubmit}
              >Submit
                <CgLogIn size={18} className="ml-2" />
              </button>
            </div>

          </form>
        </div>
        {/*Footer*/}
      </div>
    </div>




  );
}
