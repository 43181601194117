import React from "react";
import { CgLogIn } from 'react-icons/cg';


export default function UpdateCategoryModal(props) {

  let { UpdateCategoryCreateModal, handleAppSeetingHideModal, errorsUpdateGroupClassCategory, inputChangeUpdateCategory, updateCategorySubmit, fieldsGroupClassCategoriesUpdate } = props;


  return (

    <div className={UpdateCategoryCreateModal ? "fixed w-full top-0 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto mt-2">
        {/*modal header*/}
        <div className="flex items-center justify-between p-6 py-2 bg-blue-500 border-b">
          <p className="text-[16px] font-medium text-white uppercase">Update Group Class Category </p>
          <div className="rounded-full cursor-pointer modal-close ">
            <svg onClick={() => handleAppSeetingHideModal()} className="fill-current text-white/60 hover:text-white/100 " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>

        <div className="p-4">
          <button className="text-base text-gray-600 p-4 py-2 border border-gray-300 border-b-transparent bg-white -mb-0.5 font-bold">
            Update {fieldsGroupClassCategoriesUpdate.name}
          </button>
          <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">

            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Trainer Name :</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500   ${errorsUpdateGroupClassCategory && !errorsUpdateGroupClassCategory["name"] ? "border  placeholder-gray-500" : "border "}`}
                id="categoryType" name="categoryType" placeholder="categoryType" value={fieldsGroupClassCategoriesUpdate.categoryType} type="text" onChange={inputChangeUpdateCategory} />
              {errorsUpdateGroupClassCategory && errorsUpdateGroupClassCategory["categoryType"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsUpdateGroupClassCategory["categoryType"]}
                </div>
                : null}
            </div>

            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Day Name:</label>
              <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 ${errorsUpdateGroupClassCategory && !errorsUpdateGroupClassCategory["email"] ? "border  placeholder-gray-500" : "border"}`}
                id="dayName" name="dayName" placeholder="dayName" value={fieldsGroupClassCategoriesUpdate.dayName} type="dayName" onChange={inputChangeUpdateCategory} />
              {errorsUpdateGroupClassCategory && errorsUpdateGroupClassCategory["dayName"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsUpdateGroupClassCategory["dayName"]}
                </div>
                : null}
            </div>

            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Slug:</label>
              <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 ${errorsUpdateGroupClassCategory && !errorsUpdateGroupClassCategory["name"] ? "border  placeholder-gray-500" : "border"}`}
                id="slug" name="slug" placeholder="slug" value={fieldsGroupClassCategoriesUpdate.slug} type="number" onChange={inputChangeUpdateCategory} />
              {errorsUpdateGroupClassCategory && errorsUpdateGroupClassCategory["slug"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsUpdateGroupClassCategory["slug"]}
                </div>
                : null}
            </div>

            {/* <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Password :</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-red-200 ${errorsUpdateGroupClassCategory && !errorsUpdateGroupClassCategory["name"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="password" name="password" placeholder="password" value={fieldsGroupClassCategoriesUpdate.password} type="text" onChange={inputChangeUpdateCategory} />
                    {errorsUpdateGroupClassCategory && errorsUpdateGroupClassCategory["password"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsUpdateGroupClassCategory["password"]}
                      </div>
                      : null}
            </div> */}

            {/* 
            <div className="flex justify-between px-2"   >
              <div className="flex flex-wrap w-full mt-2 ">
                <label htmlFor="image">Upload Image of Trainer </label>
                <input id="image" name="image" type="file" onChange={handleFile} />
              </div>
              <div>
              <div className='flex justify-center'>
                  <img className="object-cover w-24 h-20 rounded-sm" src={fieldsGroupClassCategoriesUpdate && fieldsGroupClassCategoriesUpdate .imageLinkUrl ? fieldsGroupClassCategoriesUpdate.imageLinkUrl : null} alt="not found" />
                </div>
              </div>
            </div> */}


            {/* btns */}
            <div className="flex justify-end py-0 space-x-4">
              <button className="bg-blue-500  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
                onClick={updateCategorySubmit}
              >Submit
                <CgLogIn size={18} className="ml-2" />
              </button>
            </div>

          </form>

        </div>
        {/*Footer*/}

      </div>
    </div>




  );
}
