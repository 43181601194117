export const userConstants = {


    GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
    GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
    GET_USER_LIST_FAILURE: 'GET_USER_LIST_FAILURE',

    GET_TICKET_LIST_REQUEST: 'GET_TICKET_LIST_REQUEST',
    GET_TICKET_LIST_SUCCESS: 'GET_TICKET_LIST_SUCCESS',
    GET_TICKET_LIST_FAILURE: 'GET_TICKET_LIST_FAILURE',

    DELETE_TICKET_REQUEST: 'DELETE_TICKET_REQUEST',
    DELETE_TICKET_SUCCESS: 'DELETE_TICKET_SUCCESS',
    DELETE_TICKET_FAILURE: 'DELETE_TICKET_FAILURE',

    DISABLE_TICKET_REQUEST: 'DISABLE_TICKET_REQUEST',
    DISABLE_TICKET_SUCCESS: 'DISABLE_TICKET_SUCCESS',
    DISABLE_TICKET_FAILURE: 'DISABLE_TICKET_FAILURE',

    APPROVE_TICKET_REQUEST: 'APPROVE_TICKET_REQUEST',
    APPROVE_TICKET_SUCCESS: 'APPROVE_TICKET_SUCCESS',
    APPROVE_TICKET_FAILURE: 'APPROVE_TICKET_FAILURE',

    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    UPDATE_USER_STATUS_ADMIN_REQUEST: 'UPDATE_USER_STATUS_ADMIN_REQUEST',
    UPDATE_USER_STATUS_ADMIN_SUCCESS: 'UPDATE_USER_STATUS_ADMIN_SUCCESS',
    UPDATE_USER_STATUS_ADMIN_FAILURE: 'UPDATE_USER_STATUS_ADMIN_FAILURE',




    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGIN_TO_THIS_ACCOUNT_REQUEST: 'USERS_LOGIN_TO_THIS_ACCOUNT_REQUEST',
    LOGIN_TO_THIS_ACCOUNT_SUCCESS: 'USERS_LOGIN_TO_THIS_ACCOUNT_SUCCESS',
    LOGIN_TO_THIS_ACCOUNT_FAILURE: 'USERS_LOGIN_TO_THIS_ACCOUNT_FAILURE',

    GET_EMS_SLOT_BOOKING_REQUEST: 'USERS_GET_EMS_SLOT_BOOKING_REQUEST',
    GET_EMS_SLOT_BOOKING_SUCCESS: 'USERS_GET_EMS_SLOT_BOOKING_SUCCESS',
    GET_EMS_SLOT_BOOKING_FAILURE: 'USERS_GET_EMS_SLOT_BOOKING_FAILURE',

    GET_USER_PARKING_LIST_REQUEST: 'GET_USER_PARKING_LIST_REQUEST',
    GET_USER_PARKING_LIST_SUCCESS: 'GET_USER_PARKING_LIST_SUCCESS',
    GET_USER_PARKING_LIST_FAILURE: 'GET_USER_PARKING_LIST_FAILURE',

    GET_TX_LIST_BY_USER_REQUEST: 'GET_TX_LIST_BY_USER_REQUEST',
    GET_TX_LIST_BY_USER_SUCCESS: 'GET_TX_LIST_BY_USER_SUCCESS',
    GET_TX_LIST_BY_USER_FAILURE: 'GET_TX_LIST_BY_USER_FAILURE',

    GET_ORDER_LIST_BY_USER_REQUEST: 'GET_ORDER_LIST_BY_USER_REQUEST',
    GET_ORDER_LIST_BY_USER_SUCCESS: 'GET_ORDER_LIST_BY_USER_SUCCESS',
    GET_ORDER_LIST_BY_USER_FAILURE: 'GET_ORDER_LIST_BY_USER_FAILURE',

    GET_LOGIN_HISTORY_REQUEST: 'GET_LOGIN_HISTORY_REQUEST',
    GET_LOGIN_HISTORY_SUCCESS: 'GET_LOGIN_HISTORY_SUCCESS',
    GET_LOGIN_HISTORY_FAILURE: 'GET_LOGIN_HISTORY_FAILURE',

    GET_GROUP_SLOT_BOOKING_REQUEST: 'USERS_GET_GROUP_SLOT_BOOKING_REQUEST',
    GET_GROUP_SLOT_BOOKING_SUCCESS: 'USERS_GET_GROUP_SLOT_BOOKING_SUCCESS',
    GET_GROUP_SLOT_BOOKING_FAILURE: 'USERS_GET_GROUP_SLOT_BOOKING_FAILURE',

    GET_TRAINER_SLOT_BOOKING_REQUEST: 'USERS_GET_TRAINER_SLOT_BOOKING_REQUEST',
    GET_TRAINER_SLOT_BOOKING_SUCCESS: 'USERS_GET_TRAINER_SLOT_BOOKING_SUCCESS',
    GET_TRAINER_SLOT_BOOKING_FAILURE: 'USERS_GET_TRAINER_SLOT_BOOKING_FAILURE',

    GET_MASSAGE_SLOT_BOOKING_REQUEST: 'USERS_GET_MASSAGE_SLOT_BOOKING_REQUEST',
    GET_MASSAGE_SLOT_BOOKING_SUCCESS: 'USERS_GET_MASSAGE_SLOT_BOOKING_SUCCESS',
    GET_MASSAGE_SLOT_BOOKING_FAILURE: 'USERS_GET_MASSAGE_SLOT_BOOKING_FAILURE',

    GET_PROFILE_REQUEST: 'USERS_GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'USERS_GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'USERS_GET_PROFILE_FAILURE',

    GET_DEACTIVE_USER_REQUEST: 'GET_DEACTIVE_USER_REQUEST',
    GET_DEACTIVE_USER_SUCCESS: 'GET_DEACTIVE_USER_SUCCESS',
    GET_DEACTIVE_USER_FAILURE: 'GET_DEACTIVE_USER_FAILURE',

    LOGOUT: 'USERS_LOGOUT',


    FILE_UPLOAD_STATUS_VIDEO_REQUEST: 'FILE_UPLOAD_STATUS_VIDEO_REQUEST',
    FILE_UPLOAD_STATUS_VIDEO_SUCCESS: 'FILE_UPLOAD_STATUS_VIDEO_SUCCESS',
    FILE_UPLOAD_STATUS_VIDEO_FAILURE: 'FILE_UPLOAD_STATUS_VIDEO_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GETALL_USER_ACTIVE_REQUEST: 'GETALL_USER_ACTIVE_REQUEST',
    GETALL_USER_ACTIVE_SUCCESS: 'GETALL_USER_ACTIVE_SUCCESS',
    GETALL_USER_ACTIVE_FAILURE: 'GETALL_USER_ACTIVE_FAILURE',

    GETALL_USER_DEACTIVE_REQUEST: 'GETALL_USER_DEACTIVE_REQUEST',
    GETALL_USER_DEACTIVE_SUCCESS: 'GETALL_USER_DEACTIVE_SUCCESS',
    GETALL_USER_DEACTIVE_FAILURE: 'GETALL_USER_DEACTIVE_FAILURE',

    DISABLE_USER_REQUEST: 'DISABLE_USER_REQUEST',
    DISABLE_USER_SUCCESS: 'DISABLE_USER_SUCCESS',
    DISABLE_USER_FAILURE: 'DISABLE_USER_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    USER_VERIFY_EMAIL_REQUEST: 'USER_VERIFY_EMAIL_REQUEST',
    USER_VERIFY_EMAIL_SUCCESS: 'USER_VERIFY_EMAIL_SUCCESS',
    USER_VERIFY_EMAIL_FAILURE: 'USER_VERIFY_EMAIL_FAILURE',

    UPDATE_USER_PASSWORD_REQUEST: 'UPDATE_USER_PASSWORD_REQUEST',
    UPDATE_USER_PASSWORD_SUCCESS: 'UPDATE_USER_PASSWORD_SUCCESS',
    UPDATE_USER_PASSWORD_FAILURE: 'UPDATE_USER_PASSWORD_FAILURE',

    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',

    GETALL_SERIES_REQUEST: 'GETALL_SERIES_REQUEST',
    GETALL_SERIES_SUCCESS: 'GETALL_SERIES_SUCCESS',
    GETALL_SERIES_FAILURE: 'GETALL_SERIES_FAILURE',

    GET_ODDS_REQUEST: 'GET_ODDS_REQUEST',
    GET_ODDS_SUCCESS: 'GET_ODDS_SUCCESS',
    GET_ODDS_FAILURE: 'GET_ODDS_FAILURE',

    GET_MATCH_BY_MATCHID_REQUEST: 'GET_MATCH_BY_MATCHID_REQUEST',
    GET_MATCH_BY_MATCHID_SUCCESS: 'GET_MATCH_BY_MATCHID_SUCCESS',
    GET_MATCH_BY_MATCHID_FAILURE: 'GET_MATCH_BY_MATCHID_FAILURE',

    GETALL_SPORT_REQUEST: 'GETALL_SPORT_REQUEST',
    GETALL_SPORT_SUCCESS: 'GETALL_SPORT_SUCCESS',
    GETALL_SPORT_FAILURE: 'GETALL_SPORT_FAILURE',

    GETALL_MATCH_REQUEST: 'GETALL_MATCH_REQUEST',
    GETALL_MATCH_SUCCESS: 'GETALL_MATCH_SUCCESS',
    GETALL_MATCH_FAILURE: 'GETALL_MATCH_FAILURE',

    GETALL_MATCH_ACTIVE_REQUEST: 'GETALL_MATCH_ACTIVE_REQUEST',
    GETALL_MATCH_ACTIVE_SUCCESS: 'GETALL_MATCH_ACTIVE_SUCCESS',
    GETALL_MATCH_ACTIVE_FAILURE: 'GETALL_MATCH_ACTIVE_FAILURE',

    GETALL_PROFILE_REQUEST: 'GETALL_PROFILE_REQUEST',
    GETALL_PROFILE_SUCCESS: 'GETALL_PROFILE_SUCCESS',
    GETALL_PROFILE_FAILURE: 'GETALL_PROFILE_FAILURE',

    UPDATE_MATCH_TEAM_IMAGE_REQUEST: 'UPDATE_MATCH_TEAM_IMAGE_REQUEST',
    UPDATE_MATCH_TEAM_IMAGE_SUCCESS: 'UPDATE_MATCH_TEAM_IMAGE_SUCCESS',
    UPDATE_MATCH_TEAM_IMAGE_FAILURE: 'UPDATE_MATCH_TEAM_IMAGE_FAILURE',

    ADD_SPORT_REQUEST: 'ADD_SPORT_REQUEST',
    ADD_SPORT_SUCCESS: 'ADD_SPORT_SUCCESS',
    ADD_SPORT_FAILURE: 'ADD_SPORT_FAILURE',

    ADD_SERIES_REQUEST: 'ADD_SERIES_REQUEST',
    ADD_SERIES_SUCCESS: 'ADD_SERIES_SUCCESS',
    ADD_SERIES_FAILURE: 'ADD_SERIES_FAILURE',

    CREATE_BALANCE_REQUEST: 'CREATE_BALANCE_REQUEST',
    CREATE_BALANCE_SUCCESS: 'CREATE_BALANCE_SUCCESS',
    CREATE_BALANCE_FAILURE: 'CREATE_BALANCE_FAILURE',

    DEDUCT_BALANCE_REQUEST: 'DEDUCT_BALANCE_REQUEST',
    DEDUCT_BALANCE_SUCCESS: 'DEDUCT_BALANCE_SUCCESS',
    DEDUCT_BALANCE_FAILURE: 'DEDUCT_BALANCE_FAILURE',

    ADD_MATCH_REQUEST: 'ADD_MATCH_REQUEST',
    ADD_MATCH_SUCCESS: 'ADD_MATCH_SUCCESS',
    ADD_MATCH_FAILURE: 'ADD_MATCH_FAILURE',

    UPDATE_MATCH_MARKET_ID_REQUEST: 'UPDATE_MATCH_MARKET_ID_REQUEST',
    UPDATE_MATCH_MARKET_ID_SUCCESS: 'UPDATE_MATCH_MARKET_ID_SUCCESS',
    UPDATE_MATCH_MARKET_ID_FAILURE: 'UPDATE_MATCH_MARKET_ID_FAILURE',


    UPDATE_USERS_PASSWORD_REQUEST: 'UPDATE_USER_PASSWORD_REQUEST',
    UPDATE_USERS_PASSWORD_SUCCESS: 'UPDATE_USER_PASSWORD_SUCCESS',
    UPDATE_USERS_PASSWORD_FAILURE: 'UPDATE_USER_PASSWORD_FAILURE',


    UPDATE_MATCH_STATUS_REQUEST: 'UPDATE_MATCH_STATUS_REQUEST',
    UPDATE_MATCH_STATUS_SUCCESS: 'UPDATE_MATCH_STATUS_SUCCESS',
    UPDATE_MATCH_STATUS_FAILURE: 'UPDATE_MATCH_STATUS_FAILURE',


    FILE_UPLOAD_CLEAR_SUCCESS: 'FILE_UPLOAD_CLEAR_SUCCESS',

    // GETALL_USER_NOTIFY_REQUEST: 'GETALL_USER_NOTIFY_REQUEST',
    // GETALL_USER_NOTIFY_SUCCESS: 'GETALL_USER_NOTIFY_SUCCESS',
    // GETALL_USER_NOTIFY_FAILURE: 'GETALL_USER_NOTIFY_FAILURE',

    // UPDATE_USER_NOTIFY_REQUEST: 'UPDATE_USER_NOTIFY_REQUEST',
    // UPDATE_USER_NOTIFY_SUCCESS: 'UPDATE_USER_NOTIFY_SUCCESS',
    // UPDATE_USER_NOTIFY_FAILURE: 'UPDATE_USER_NOTIFY_FAILURE',


    // ADD_RESTAURANT_USER_REQUEST: 'ADD_RESTAURANT_USER_REQUEST',
    // ADD_RESTAURANT_USER_SUCCESS: 'ADD_RESTAURANT_USER_SUCCESS',
    // ADD_RESTAURANT_USER_FAILURE: 'ADD_RESTAURANT_USER_FAILURE',

    // FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    // FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    // FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    // GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    // GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    // GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',

    // ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    // ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    // ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    // ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    // ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    // ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    // DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    // DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    // DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    // UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    // UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    // UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    // UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    // UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    // UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    // STATS_REQUEST: 'STATS_REQUEST',
    // STATS_SUCCESS: 'STATS_SUCCESS',
    // STATS_FAILURE: 'STATS_FAILURE',

};
