import React from "react";

import Modal from 'react-modal';



export default function CreateAddCategoryModal(props) {

  let { addUserCreateModal, handleAddUserHideModal, errorsValet, inputAddUserChange, createRestoCategorySubmit, fieldsValet, handleFile, 
    // inputChangeIsVideo, isVideo,
     allCategoryParent, filesDetails
    //  handleFile2

  } = props;
  // console.log("isVideo_modal::", isVideo);

  return (

    <Modal
      isOpen={addUserCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500  modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">Add Valet Employee</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleAddUserHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>

            {/*Body*/}
            <div className="">
              <form autoComplete="off">
                {/* <div class="flex justify-center">
                  <div class="mb-3 w-full">
                    <label className="text-black text-sm font-medium ">Category :</label>
                    <select class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal  text-gray-700  bg-white bg-clip-padding bg-no-repeat  border border-solid border-gray-300 rounded transition ease-in-out  m-0  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example"
                      onChange={inputAddUserChange}
                      id="parentId"
                      name="parentId"
                      value={fieldsValet && fieldsValet["parentId"] ? fieldsValet["parentId"] : null}
                    >
                      <option selected>Plz Select Category</option>
                      {
                        allCategoryParent && allCategoryParent && allCategoryParent.length > 0 ?
                          allCategoryParent.map((element, index) => (
                            <option value={element.id}>{element && element.name ? element.name : "NA"}</option>
                          ))
                          : null
                      }
                    </select>
                    {errorsValet && errorsValet["parentId"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsValet["parentId"]}
                      </div>
                      : null}
                  </div>
                </div> */}

                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1 shadow-sm relative">Employee Name :
                    <input className={`px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsValet && !errorsValet["name"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="name" name="name" placeholder="Name" value={fieldsValet.name} type="text" onChange={inputAddUserChange} />
                    {errorsValet && errorsValet["name"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsValet["name"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1 shadow-sm relative">Employee Email :
                    <input className={`px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsValet && !errorsValet["name"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="email" name="email" placeholder="Email" value={fieldsValet.email} type="text" onChange={inputAddUserChange} />
                    {errorsValet && errorsValet["email"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsValet["email"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1 shadow-sm relative">Mobile No. :
                    <input className={`px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsValet && !errorsValet["mobile"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="mobile" name="mobile" placeholder="mobile" value={fieldsValet.mobile} type="text" onChange={inputAddUserChange} />
                    {errorsValet && errorsValet["mobile"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsValet["mobile"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1 shadow-sm relative">Password :
                    <input className={`px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsValet && !errorsValet["password"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="password" name="password" placeholder="password" value={fieldsValet.password} type="text" onChange={inputAddUserChange} />
                    {errorsValet && errorsValet["password"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsValet["password"]}
                      </div>
                      : null}
                  </div>
                </div>



                {/* <div className="flex flex-wrap w-full py-2">
                  <label htmlFor="image">
                    Upload Image
                  </label>
                  <input id="image" name="image" type="file" onChange={handleFile} />
                  <div className="flex space-x-4 mt-1">
                  
                  </div>
                </div>  */}

                <div className="w-60 my-2 mx-auto">
                  <button className="bg-blue-500 w-full mx-auto flex justify-center py-2 uppercase px-4 border text-lg xl font-semibold rounded-full text-white border-blue-500 hover:bg-blue hover:text-white focus:outline-none focus:border-blue-500 focus:shadow-outline-yellow active:bg-blue-500 transition duration-150 ease-in-out" type="button" onClick={createRestoCategorySubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
