import { userConstants, valetConstants } from '../_constants';
import { userService, valetService  } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const valetActions = {

    login,
    logout,
    disableValet,
    getAllValet,
    createValetUser,
    updateParkingImage,
    getValetUserList,
    deleteRestoCat,
    uploadImageClear,
    getValetUserById,
    updateValetUser,
    getParkingList,
    updateValetUserPassword,
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createValetUser(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        valetService .createValetUser(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Valet Employee Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getValetUserList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: valetConstants.ADD_VALET_REQUEST } }
    function success(users) { return { type: valetConstants.ADD_VALET_SUCCESS, users } }
    function failure(error) { return { type: valetConstants.ADD_VALET_FAILURE, error } }
}
function updateParkingImage(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        valetService.updateParkingImage(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Image Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getParkingList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: valetConstants.UPDATE_VALET_REQUEST } }
    function success(users) { return { type: valetConstants.UPDATE_VALET_SUCCESS, users } }
    function failure(error) { return { type: valetConstants.UPDATE_VALET_FAILURE, error } }
}
function updateValetUser(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        valetService.updateValetUser(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Valet Employee Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getValetUserList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: valetConstants.UPDATE_VALET_USER_REQUEST } }
    function success(users) { return { type: valetConstants.UPDATE_VALET_USER_SUCCESS, users } }
    function failure(error) { return { type: valetConstants.UPDATE_VALET_USER_FAILURE, error } }
}
function updateValetUserPassword(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        valetService.updateValetUserPassword(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Password Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getValetUserList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: valetConstants.UPDATE_VALET_PASSWORD_REQUEST } }
    function success(users) { return { type: valetConstants.UPDATE_VALET_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: valetConstants.UPDATE_VALET_PASSWORD_FAILURE, error } }
}
function getAllValet() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        valetService .getAllValet()
            .then(
                users => {
                    console.log("getAllValet $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: valetConstants.GETALL_VALET_REQUEST } }
    function success(users) { return { type: valetConstants.GETALL_VALET_SUCCESS, users } }
    function failure(error) { return { type: valetConstants.GETALL_VALET_FAILURE, error } }
}
function getValetUserById() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        valetService .getValetUserById()
            .then(
                users => {
                    console.log("getValetUserById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: valetConstants.GET_ID_VALET_REQUEST } }
    function success(users) { return { type: valetConstants.GET_ID_VALET_SUCCESS, users } }
    function failure(error) { return { type: valetConstants.GET_ID_VALET_FAILURE, error } }
}
function getValetUserList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        valetService .getValetUserList(data)
            .then(
                users => {
                    console.log("getValetUserList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: valetConstants.GET_LIST_VALET_REQUEST } }
    function success(users) { return { type: valetConstants.GET_LIST_VALET_SUCCESS, users } }
    function failure(error) { return { type: valetConstants.GET_LIST_VALET_FAILURE, error } }
}
function getParkingList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        valetService .getParkingList(data)
            .then(
                users => {
                    console.log("getParkingList $$$$ action:", users);
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: valetConstants.GET_LIST_PARKING_REQUEST } }
    function success(users) { return { type: valetConstants.GET_LIST_PARKING_SUCCESS, users } }
    function failure(error) { return { type: valetConstants.GET_LIST_PARKING_FAILURE, error } }
}


function deleteRestoCat(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        valetService .deleteRestoCat(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getValetUserList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: valetConstants.DELETE_VALET_REQUEST } }
    function success(users) { return { type: valetConstants.DELETE_VALET_SUCCESS, users } }
    function failure(error) { return { type: valetConstants.DELETE_VALET_FAILURE, error } }
}
function disableValet(data, paginationData) {

    return dispatch => {
        dispatch(request());
        valetService .disableValet(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getValetUserList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: valetConstants.DISABLE_VALET_REQUEST } }
    function success(users) { return { type: valetConstants.DISABLE_VALET_SUCCESS, users } }
    function failure(error) { return { type: valetConstants.DISABLE_VALET_FAILURE, error } }
}
