import { userConstants, massageConstants, classesConstants } from '../_constants';
import { userService, massageService, classesService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const classesActions = {

    login,
    logout,
    disableClasses,
    getAllmassage,
    createClasses,
    updateClassesUser,
    getClassesList,
    deleteClasses,
    uploadImageClear,
    getClassesUserById,
    getShowClassesBookSlotList,
    rejectBookClass,
    onlyBookingClassSlotAdmin,
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createClasses(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        classesService.createClasses(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Class Slot Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getClassesList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: classesConstants.ADD_CLASSES_REQUEST } }
    function success(users) { return { type: classesConstants.ADD_CLASSES_SUCCESS, users } }
    function failure(error) { return { type: classesConstants.ADD_CLASSES_FAILURE, error } }
}
function updateClassesUser(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        classesService.updateClassesUser(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Class Slot Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getClassesList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: classesConstants.UPDATE_CLASSES_REQUEST } }
    function success(users) { return { type: classesConstants.UPDATE_CLASSES_SUCCESS, users } }
    function failure(error) { return { type: classesConstants.UPDATE_CLASSES_FAILURE, error } }
}
function getAllmassage() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        massageService.getAllmassage()
            .then(
                users => {
                    console.log("getAllmassage $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.GETALL_MASSAGE_REQUEST } }
    function success(users) { return { type: massageConstants.GETALL_MASSAGE_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.GETALL_MASSAGE_FAILURE, error } }
}
function getClassesUserById() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        classesService.getClassesUserById()
            .then(
                users => {
                    console.log("getClassesUserById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: classesConstants.GET_ID_CLASSES_REQUEST } }
    function success(users) { return { type: classesConstants.GET_ID_CLASSES_SUCCESS, users } }
    function failure(error) { return { type: classesConstants.GET_ID_CLASSES_FAILURE, error } }
}
function getClassesList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        classesService.getClassesList(data)
            .then(
                users => {
                    console.log("getClassesList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: classesConstants.GET_LIST_CLASSES_REQUEST } }
    function success(users) { return { type: classesConstants.GET_LIST_CLASSES_SUCCESS, users } }
    function failure(error) { return { type: classesConstants.GET_LIST_CLASSES_FAILURE, error } }
}

function getShowClassesBookSlotList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        classesService.getShowClassesBookSlotList(data)
            .then(
                users => {
                    console.log("getShowClassesBookSlotList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: classesConstants.GET_LIST_CLASSES_BOOK_REQUEST } }
    function success(users) { return { type: classesConstants.GET_LIST_CLASSES_BOOK_SUCCESS, users } }
    function failure(error) { return { type: classesConstants.GET_LIST_CLASSES_BOOK_FAILURE, error } }
}


function onlyBookingClassSlotAdmin(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        classesService.onlyBookingClassSlotAdmin(data)
            .then(
                users => {
                    console.log("onlyBookingClassSlotAdmin $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: classesConstants.GET_CLASSES_BOOK_DETAIL_REQUEST } }
    function success(users) { return { type: classesConstants.GET_CLASSES_BOOK_DETAIL_SUCCESS, users } }
    function failure(error) { return { type: classesConstants.GET_CLASSES_BOOK_DETAIL_FAILURE, error } }
}


function deleteClasses(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        classesService.deleteClasses(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getClassesList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: classesConstants.DELETE_CLASSES_REQUEST } }
    function success(users) { return { type: classesConstants.DELETE_CLASSES_SUCCESS, users } }
    function failure(error) { return { type: classesConstants.DELETE_CLASSES_FAILURE, error } }
}
function disableClasses(data, paginationData) {

    return dispatch => {
        dispatch(request());
        classesService.disableClasses(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getClassesList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: classesConstants.DISABLE_CLASSES_REQUEST } }
    function success(users) { return { type: classesConstants.DISABLE_CLASSES_SUCCESS, users } }
    function failure(error) { return { type: classesConstants.DISABLE_CLASSES_FAILURE, error } }
}
function rejectBookClass(data, paginationData) {

    return dispatch => {
        dispatch(request());
        classesService.rejectBookClass(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getShowClassesBookSlotList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: classesConstants.DISABLE_CLASSES_BOOK_REQUEST } }
    function success(users) { return { type: classesConstants.DISABLE_CLASSES_BOOK_SUCCESS, users } }
    function failure(error) { return { type: classesConstants.DISABLE_CLASSES_BOOK_FAILURE, error } }
}
