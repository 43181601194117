import React from "react";
import { CgLogIn } from 'react-icons/cg';


export default function UpdateCategoryModal(props) {

  let { UpdateCategoryCreateModal, handleAppSeetingHideModal, errorsUpdateValet, inputChangeUpdateCategory, updateCategorySubmit, fieldsValetUpdate, handleFile, allCategory, filesDetails,handleAddUserHideModal
  } = props;
  console.log("MODAL__fieldsValetUpdate::", filesDetails);

  return (
    <div className={UpdateCategoryCreateModal ? "fixed w-full top-0 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto mt-2">
        {/*modal header*/}
        <div className="flex items-center justify-between p-6 py-2 bg-blue-500 border-b">
          <p className="text-[16px] font-medium text-white uppercase">Update Parking Image</p>
          <div className="rounded-full cursor-pointer modal-close ">
            <svg onClick={() => handleAppSeetingHideModal()} className="fill-current text-white/60 hover:text-white/100 " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>

        <div className="p-4">
   
          <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">

 





            <div className="flex justify-between px-2"   >
              <div className="flex flex-wrap w-full py-2">
                <label htmlFor="image">
                  Upload Image of Vehicle
                </label>
                <input id="image" name="image" type="file" onChange={handleFile} />
                <div className="flex space-x-4 mt-1">

                </div>
              </div>


              {
                filesDetails && filesDetails.imageURL ?
                  <>
                    <div>
                      <div className='flex justify-center'>
                        <img className="object-cover h-10 rounded-sm w-14" src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : null} alt="not found" />
                      </div>
                    </div>
                  </> :
                  <>
                    <div>
                      <div className='flex justify-center'>
                        <img className="object-cover h-10 rounded-sm w-14" src="/dist/img/profile.png" alt="not found" />
                      </div>
                    </div>
                  </>
              }





            </div>

            {/* btns */}
            <div className="flex justify-end py-0 space-x-4">
              <button className="bg-blue-500  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
                onClick={updateCategorySubmit}
              >Submit
                <CgLogIn size={18} className="ml-2" />
              </button>
            </div>

          </form>
        </div>
        {/*Footer*/}
      </div>
    </div>
  );
}
