import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { dashboard } from './dashboard.reducer';
import { restaurent } from './restaurent.reducer';
import { massage } from './massage.reducer';
import { valet } from './valet.reducer';
import { ems } from './ems.reducer';
import { classes } from './classes.reducer';
import { emsSlot } from './emsSlot.reducer';
import { massageSlot } from './massageSlot.reducer';
import { groupClass } from './groupClass.reducer';
import { groupClassCategory } from './groupClassCategory.reducer';
import {section} from './section.reducer'
import {plot} from './plot.reducer'
import {bookingList} from './bookingList.reducer'


const rootReducer = combineReducers({
  authentication,
  users,
  alert,
  dashboard,
  restaurent,
  massage,
  valet,
  ems,
  classes,
  emsSlot,
  massageSlot,
  groupClass,
  groupClassCategory,
  section,
  plot,
  bookingList,
});

export default rootReducer;
