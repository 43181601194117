import {
  groupClassConstants
} from '../_constants';

export function groupClass(state = {}, action) {
  // console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case groupClassConstants.GET_ID_GROUPCLASS_REQUEST:
      return {
        ...state
      };
    case groupClassConstants.GET_ID_GROUPCLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allrestaurent: action.users.getRestaurantUserById,
      };
    case groupClassConstants.GET_ID_GROUPCLASS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case groupClassConstants.ADD_GROUPCLASS_REQUEST:
      return {
        ...state
      };
    case groupClassConstants.ADD_GROUPCLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case groupClassConstants.ADD_GROUPCLASS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case groupClassConstants.GET_LIST_GROUPCLASS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case groupClassConstants.GET_LIST_GROUPCLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getUserGroupList.list,
        total: action.users.getUserGroupList.total
      };
    case groupClassConstants.GET_LIST_GROUPCLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case groupClassConstants.GET_LIST_PARKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case groupClassConstants.GET_LIST_PARKING_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        parkingItems: action.users.getParkingList.list,
        parkingTotal: action.users.getParkingList.total
      };
    case groupClassConstants.GET_LIST_PARKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case groupClassConstants.GETALL_GROUPCLASS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case groupClassConstants.GETALL_GROUPCLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allrestaurent: action.users.getRestaurantUserById,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case groupClassConstants.GETALL_GROUPCLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case groupClassConstants.UPDATE_GROUPCLASS_REQUEST:
      return {
        ...state
      };
    case groupClassConstants.UPDATE_GROUPCLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case groupClassConstants.UPDATE_GROUPCLASS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case groupClassConstants.UPDATE_GROUPCLASS_PASSWORD_REQUEST:
      return {
        ...state
      };
    case groupClassConstants.UPDATE_GROUPCLASS_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case groupClassConstants.UPDATE_GROUPCLASS_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case groupClassConstants.DELETE_GROUPCLASS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case groupClassConstants.DELETE_GROUPCLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case groupClassConstants.DELETE_GROUPCLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case groupClassConstants.DISABLE_GROUPCLASS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case groupClassConstants.DISABLE_GROUPCLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case groupClassConstants.DISABLE_GROUPCLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}