import {
  valetConstants
} from '../_constants';

export function valet(state = {}, action) {
  // console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case valetConstants.GET_ID_VALET_REQUEST:
      return {
        ...state
      };
    case valetConstants.GET_ID_VALET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allrestaurent: action.users.getRestaurantUserById,
      };
    case valetConstants.GET_ID_VALET_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case valetConstants.ADD_VALET_REQUEST:
      return {
        ...state
      };
    case valetConstants.ADD_VALET_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case valetConstants.ADD_VALET_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case valetConstants.GET_LIST_VALET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case valetConstants.GET_LIST_VALET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getValetUserList.list,
        total: action.users.getValetUserList.total
      };
    case valetConstants.GET_LIST_VALET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case valetConstants.GET_LIST_PARKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case valetConstants.GET_LIST_PARKING_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        parkingItems: action.users.getParkingList.list,
        parkingTotal: action.users.getParkingList.total
      };
    case valetConstants.GET_LIST_PARKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case valetConstants.GETALL_VALET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case valetConstants.GETALL_VALET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allrestaurent: action.users.getRestaurantUserById,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case valetConstants.GETALL_VALET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case valetConstants.UPDATE_VALET_REQUEST:
      return {
        ...state
      };
    case valetConstants.UPDATE_VALET_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case valetConstants.UPDATE_VALET_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case valetConstants.UPDATE_VALET_PASSWORD_REQUEST:
      return {
        ...state
      };
    case valetConstants.UPDATE_VALET_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case valetConstants.UPDATE_VALET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case valetConstants.UPDATE_VALET_USER_REQUEST:
      return {
        ...state
      };
    case valetConstants.UPDATE_VALET_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case valetConstants.UPDATE_VALET_USER_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case valetConstants.DELETE_VALET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case valetConstants.DELETE_VALET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case valetConstants.DELETE_VALET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case valetConstants.DISABLE_VALET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case valetConstants.DISABLE_VALET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case valetConstants.DISABLE_VALET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}