import { userConstants, restaurentConstants } from '../_constants';
import { userService, restaurentService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const restaurentActions = {

    login,
    logout,
    disablerestaurent,
    getAllrestaurent,
    createRestorestaurent,
    updateResto,
    getRestaurantUserList,
    deleteRestoCat,
    uploadImageClear,
    getRestaurantUserById,
    updateRestaurentUserPassword
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createRestorestaurent(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        restaurentService.createRestoRestaurent(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Restaurent Employee Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getRestaurantUserList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: restaurentConstants.ADD_RESTAURENT_REQUEST } }
    function success(users) { return { type: restaurentConstants.ADD_RESTAURENT_SUCCESS, users } }
    function failure(error) { return { type: restaurentConstants.ADD_RESTAURENT_FAILURE, error } }
}
function updateResto(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        restaurentService.updateResto(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Restaurent Employee Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getRestaurantUserList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: restaurentConstants.UPDATE_RESTAURENT_REQUEST } }
    function success(users) { return { type: restaurentConstants.UPDATE_RESTAURENT_SUCCESS, users } }
    function failure(error) { return { type: restaurentConstants.UPDATE_RESTAURENT_FAILURE, error } }
}
function updateRestaurentUserPassword(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        restaurentService.updateRestaurentUserPassword(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Password Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getRestaurantUserList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: restaurentConstants.UPDATE_RESTAURENT_PASSWORD_REQUEST } }
    function success(users) { return { type: restaurentConstants.UPDATE_RESTAURENT_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: restaurentConstants.UPDATE_RESTAURENT_PASSWORD_FAILURE, error } }
}
function getAllrestaurent() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        restaurentService.getAllrestaurent()
            .then(
                users => {
                    console.log("getAllrestaurent $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: restaurentConstants.GETALL_RESTAURENT_REQUEST } }
    function success(users) { return { type: restaurentConstants.GETALL_RESTAURENT_SUCCESS, users } }
    function failure(error) { return { type: restaurentConstants.GETALL_RESTAURENT_FAILURE, error } }
}
function getRestaurantUserById() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        restaurentService.getRestaurantUserById()
            .then(
                users => {
                    console.log("getRestaurantUserById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: restaurentConstants.GET_ID_RESTAURENT_REQUEST } }
    function success(users) { return { type: restaurentConstants.GET_ID_RESTAURENT_SUCCESS, users } }
    function failure(error) { return { type: restaurentConstants.GET_ID_RESTAURENT_FAILURE, error } }
}
function getRestaurantUserList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        restaurentService.getRestaurantUserList(data)
            .then(
                users => {
                    console.log("getRestaurantUserList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: restaurentConstants.GET_LIST_RESTAURENT_REQUEST } }
    function success(users) { return { type: restaurentConstants.GET_LIST_RESTAURENT_SUCCESS, users } }
    function failure(error) { return { type: restaurentConstants.GET_LIST_RESTAURENT_FAILURE, error } }
}


function deleteRestoCat(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        restaurentService.deleteRestoCat(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getRestaurantUserList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: restaurentConstants.DELETE_RESTAURENT_REQUEST } }
    function success(users) { return { type: restaurentConstants.DELETE_RESTAURENT_SUCCESS, users } }
    function failure(error) { return { type: restaurentConstants.DELETE_RESTAURENT_FAILURE, error } }
}
function disablerestaurent(data, paginationData) {

    return dispatch => {
        dispatch(request());
        restaurentService.disableRestaurent(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getRestaurantUserList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: restaurentConstants.DISABLE_RESTAURENT_REQUEST } }
    function success(users) { return { type: restaurentConstants.DISABLE_RESTAURENT_SUCCESS, users } }
    function failure(error) { return { type: restaurentConstants.DISABLE_RESTAURENT_FAILURE, error } }
}
