import React from "react";
import { CgLogIn } from 'react-icons/cg';

// 
export default function CreditActivityModal(props) {


  let { creditActivityOpenModal, handleCreditActivityHideModal, handleFile, errorsQuote, inputAddUserChange, createAddUserSubmit, filesDetails, fieldsQuote } = props;

  return (

    <div className={creditActivityOpenModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">
        {/*modal header*/}
        <div className="flex items-center justify-between p-6 py-2 bg-blue-500 border-b">
          <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Add User</p>
          <div className="rounded-full cursor-pointer modal-close ">
            <svg onClick={() => handleCreditActivityHideModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>

        <div className="p-4">
          <button className="text-sm text-gray-600 font-medium p-4 py-2 border border-gray-300 border-b-transparent bg-white -mb-0.5">
            Create User
          </button>
          <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">

            <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">

              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username"> Name :</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500   ${errorsQuote && !errorsQuote["name"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                  id="name" name="name" placeholder=" Name" value={fieldsQuote && fieldsQuote.name ? fieldsQuote.name : ''} type="text" onChange={inputAddUserChange} />
                {errorsQuote && errorsQuote["name"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsQuote["name"]}
                  </div>
                  : null}
              </div>

              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="number">Number:</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500   ${errorsQuote && !errorsQuote["number"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                  id="number" name="number" placeholder="Number" value={fieldsQuote && fieldsQuote.number ? fieldsQuote.number : ''} type="text" onChange={inputAddUserChange} />
                {errorsQuote && errorsQuote["number"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsQuote["number"]}
                  </div>
                  : null}
              </div>

              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Email :</label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsQuote && !errorsQuote["email"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                  id="email" name="email" placeholder="Email" value={fieldsQuote && fieldsQuote.email ? fieldsQuote.email : ''} type="text" onChange={inputAddUserChange} />
                {errorsQuote && errorsQuote["email"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsQuote["email"]}
                  </div>
                  : null}
              </div>


              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Password :</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsQuote && !errorsQuote["password"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                  id="password" name="password" placeholder="password" value={fieldsQuote && fieldsQuote.password ? fieldsQuote.password : ''} type="password" onChange={inputAddUserChange} />
                {errorsQuote && errorsQuote["password"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsQuote["password"]}
                  </div>
                  : null}
              </div>


            </div>

            <div class="flex  space-x-4 justify-between">
              <div class="overflow-hidden relative w-64 mt-4 mb-4 rounded-xl">
                <label class="bg-blue-400 hover:bg-blue-light cursor-pointer text-white font-bold py-2 px-4 w-full inline-flex items-center">
                  <svg fill="#FFF" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
                  </svg>
                  <span class="ml-2">Upload Profile</span>
                  <input onChange={handleFile} id="image" name="image" type="file" class="cursor-pointer absolute block py-2 px-4 w-full opacity-0 pin-r pin-t" accept="image/*" />
                </label>
              </div>


              {
                filesDetails && filesDetails.imageURL ?
                  <>
                    <div>
                      <div className='flex justify-center px-5 pt-3'>
                        <img className="object-cover w-16 h-16 rounded-full" src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : null} alt="not found" />
                      </div>
                    </div>
                  </> :
                  <>
                    <div>
                      <div className='flex justify-center pt-3'>
                        <img className="object-cover h-10 rounded-sm w-14" src="/dist/img/profile.png" alt="not found" />
                      </div>
                    </div>
                  </>
              }


            </div>








            <div className="flex justify-end py-0 space-x-4">
              <button className="bg-blue-500  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
                onClick={() => createAddUserSubmit()}>Submit
                <CgLogIn size={18} className="ml-2" />
              </button>
            </div>




          </form>



        </div>
        {/*Footer*/}

      </div >



      {/* <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Date Of Birth :</label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsQuote && !errorsQuote["dob"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                  id="dob" name="dob" placeholder="Date Of Birth" value={fieldsQuote && fieldsQuote.dob ? fieldsQuote.dob : ''} type="date" onChange={inputAddUserChange} />
                {errorsQuote && errorsQuote["dob"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsQuote["dob"]}
                  </div>
                  : null}
              </div> */}


      {/* 
              <div>
                <label for="frm-whatever" className='block text-xs font-medium text-gray-500 dark:text-white'>Gender</label>
                <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={inputAddUserChange}
                  id="gender"
                  value={fieldsQuote && fieldsQuote.gender ? fieldsQuote.gender : ''}
                  name="gender">

                  <option value="">Please choose&hellip;</option>
                  <option value="MALE">MALE</option>
                  <option value="FEMALE">FEMALE</option>
                  <option value="OTHER">OTHER</option>
                </select>
              </div> */}



      {/* <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">City :</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsQuote && !errorsQuote["city"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                  id="city" name="city" placeholder="City" value={fieldsQuote && fieldsQuote.city ? fieldsQuote.city : ''} type="text" onChange={inputAddUserChange} />
                {errorsQuote && errorsQuote["city"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsQuote["city"]}
                  </div>
                  : null}
              </div> */}
      {/* 
              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Pin Code :</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsQuote && !errorsQuote["pin"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                  id="pin" name="pin" placeholder="Pin Code" value={fieldsQuote && fieldsQuote.pin ? fieldsQuote.pin : ''} type="pin" onChange={inputAddUserChange} />
                {errorsQuote && errorsQuote["pin"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsQuote["pin"]}
                  </div>
                  : null}
              </div> */}



      {/* <div className="flex justify-between px-2 pt-3">
              <div className="flex flex-wrap w-full ">
                <label htmlFor="image">Upload Image </label>
                <input id="image" name="image" type="file" onChange={handleFile} />
              </div>
              <div className='flex justify-center'>
                <img className="object-cover w-12 h-12" src="/dist/img/profile.png" alt="not found" />
              </div>
            </div> */}


      {/* <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Confirm Password :</label>
                <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 ${errorsQuote && !errorsQuote["confirmPassword"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                  id="confirmPassword" name="confirmPassword" placeholder="Confirm Password" value={fieldsQuote && fieldsQuote.confirmPassword ? fieldsQuote.confirmPassword : ''} type="Password" onChange={inputAddUserChange} />
                {errorsQuote && errorsQuote["confirmPassword"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorsQuote["confirmPassword"]}
                  </div>
                  : null}
              </div> */}

    </div >













  );
}
