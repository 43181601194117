import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { BiRestaurant, BiBody, BiSupport, BiCategory } from "react-icons/bi";
import { FaUserEdit, FaUserCheck } from "react-icons/fa";
import { MdOutlineDashboard, MdRestaurantMenu, MdOutlineSportsGymnastics, MdOutlineSystemSecurityUpdateWarning } from "react-icons/md";
import { HiChevronRight, HiChevronDown } from "react-icons/hi";
import { GiCarWheel, GiGymBag } from "react-icons/gi";
import { MdOutlineClass } from 'react-icons/md';
import { RiParkingBoxLine, RiFileList2Line } from "react-icons/ri";
import { SiOpenaigym } from "react-icons/si";
import { TbMassage, TbChecklist, TbListCheck } from "react-icons/tb";
import { SlOrganization } from "react-icons/sl";
import { BsBookmarkFill } from "react-icons/bs";




function Sidebar({ location, history }) {

  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [user, setUser] = React.useState(0);
  const [ems, setEms] = React.useState(1);
  const [restro, setRestro] = React.useState(false);
  const [massage, setMassage] = React.useState(false);
  const [valet, setValet] = React.useState(false);
  const [classes, setClasses] = React.useState(false);
  const [section, setSection] = React.useState(false);
  const [bookingList, setbookingList] = React.useState(false);

  const onClickMenu = (url) => {
    history.push(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen)
    }, 150);

  }

  const handleResponse = (data) => {
    if (data && data.user == true) {
      setUser(true); setEms(false); setRestro(false); setMassage(false); setValet(false); setClasses(false);
    }
    else if (data && data.ems == true) {
      setUser(false); setEms(true); setRestro(false); setMassage(false); setValet(false); setClasses(false);
    }
    else if (data && data.restro == true) {
      setUser(false); setEms(false); setRestro(true); setMassage(false); setValet(false); setClasses(false);
    }
    else if (data && data.massage == true) {
      setUser(false); setEms(false); setRestro(false); setMassage(true); setValet(false); setClasses(false);
    }
    else if (data && data.valet == true) {
      setUser(false); setEms(false); setRestro(false); setMassage(false); setValet(true); setClasses(false);
    }
    else if (data && data.classes == true) {
      setUser(false); setEms(false); setRestro(false); setMassage(false); setValet(false); setClasses(true);
    }
    else if (data && data.section == true) {
      setUser(false); setEms(false); setRestro(false); setMassage(false); setValet(false); setClasses(false); setSection(true);
    }
    else if (data && data.bookingList == true) {
      setUser(false); setEms(false); setRestro(false); setMassage(false); setValet(false); setClasses(false); setbookingList(true);
    }
  }


  return (
    <>

      <button className={` ${navbarOpen === true ? 'hidden' : 'flex'} absolute top-0 left-0 px-5 items-center justify-center border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 lg:hidden `} onClick={() => setNavbarOpen(!navbarOpen)}>
        <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 15.0003V17.0003H18V15.0003H0ZM14.404 0.904297L12.99 2.3183L16.172 5.5003L12.99 8.6823L14.404 10.0963L19 5.5003L14.404 0.904297ZM0 8.0003V10.0003H9V8.0003H0ZM0 1.0003V3.0003H9V1.0003H0Z" fill="black" />
        </svg>
      </button>

      {navbarOpen ?
        <div className={` ${navbarOpen === false ? 'hidden' : ''} lg:hidden lg:flex-shrink-0 lg:static inset-0 z-50 fixed bg-black bg-opacity-50`} onClick={() => setNavbarOpen(!navbarOpen)}>
          <div className="absolute top-0 z-40 flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600 left-72 lg:left-80 " aria-label="Close sidebar">
            <svg className="w-6 h-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12">
              </path>
            </svg>
          </div>
        </div>
        : null}

      <div className={` ${navbarOpen === false ? 'hidden' : 'flex'} fixed left-0 inset-y-0 lg:static lg:flex w-72 z-50 flex-col `} onClick={() => setNavbarOpen(!navbarOpen)}>
        <div className="relative flex flex-col flex-1 h-full max-h-full w-72">
          <div onClick={() => onClickMenu('user')} className="z-50 flex items-center w-full h-20 px-2 py-2 pl-6 space-x-3 bg-black cursor-pointer">
            <img src="dist/img/BrothersSociety.jpeg" className="w-10" alt="Fitclub-logo" />
            <span className="text-xl font-semibold text-white capitalize ">Orcha Admin</span>
          </div>

          <nav className="relative z-10 flex flex-col flex-wrap items-center justify-between h-full px-6 overflow-auto overflow-y-auto bg-black w-72">
            <div className="flex flex-col flex-wrap items-center justify-between flex-1 w-full mx-auto">

              <div className={"relative z-40 flex-1 w-full "}>
                {user === true ?
                  <div>
                    <span className={` hover:bg-[#FFFFFF]/10 cursor-pointer rounded-md h-12 items-center flex justify-between px-3 py-2 text-[13px]  transition ease-in-out duration-150  ${location.pathname === "/app/portfolio" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`} onClick={() => setUser(false)}>
                      <div className="flex space-x-4 " >
                        <div className="">
                          <FaUserEdit size={20} />
                        </div>
                        <span className="text-[13px] ">User Management</span>
                      </div>
                      {
                        user === true ? <button className="text-red-500" ><HiChevronDown /></button> : <HiChevronRight />
                      }
                    </span>
                    <div onClick={() => onClickMenu('/app/user')} className={`text-sm p-3 flex items-center space-x-4 hover:bg-[#FFFFFF]/10 rounded-md ${location.pathname === "/app/user" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`}>
                      <div className="flex ml-4 space-x-3">
                        <FaUserCheck className="text-[1rem]" />
                        <p>Active User</p>
                      </div>
                    </div>
                    {/* <div onClick={() => onClickMenu('/app/deactiveUser')} className={`text-sm p-3 flex items-center space-x-4 hover:bg-[#FFFFFF]/10 rounded-md ${location.pathname === "/app/deactiveUser" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`}>
                      <div className="flex ml-4 space-x-3">
                        <FaUserCheck className="text-[1rem]" />
                        <p>Deactive User</p>
                      </div>
                    </div> */}
                  </div>
                  :
                  <span className={` hover:bg-[#FFFFFF]/10 cursor-pointer rounded-md h-12 items-center flex justify-between px-3 py-2 text-[13px]  transition ease-in-out duration-150  ${location.pathname === "/app/portfolio" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`} onClick={() => handleResponse({ user: true })}>
                    <div className="flex space-x-4 ">
                      <div className="">
                        <FaUserEdit size={20} />
                      </div>
                      <span className="text-[13px] ">User Management</span>
                    </div>
                    {
                      user === true ? <HiChevronDown /> : <button ><HiChevronRight /></button>
                    }
                  </span>
                }

                {
                  classes === true ?
                    <div>
                      <span className={` hover:bg-[#FFFFFF]/10 cursor-pointer rounded-md h-12 items-center flex justify-between px-3 py-2 text-[13px]  transition ease-in-out duration-150  ${location.pathname === "/app/portfolio" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`} onClick={() => setClasses(false)}>
                        <div className="flex space-x-4 ">
                          <div className="">
                            <MdOutlineClass size={20} />
                          </div>
                          <span className="text-[13px] ">Plot Management</span>
                        </div>
                        {
                          classes === true ? <button className="text-red-500" ><HiChevronDown /></button> : <HiChevronRight />
                        }
                      </span>
                      <div onClick={() => onClickMenu('/app/plotList')} className={` accor hover:bg-[#FFFFFF]/10 cursor-pointer rounded-md h-12 items-center flex justify-between px-3 py-2 text-[13px]  transition ease-in-out duration-150 ${location.pathname === "/app/plotList" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`}>
                        <div className="flex ml-4 space-x-3">
                          <SiOpenaigym className="text-[1rem]" />
                          <p>Plot List</p>
                        </div>
                      </div>







                    </div>
                    :
                    <span className={` hover:bg-[#FFFFFF]/10 cursor-pointer rounded-md h-12 items-center flex justify-between px-3 py-2 text-[13px]  transition ease-in-out duration-150  ${location.pathname === "/app/portfolio" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`} onClick={() => handleResponse({ classes: true })}>
                      <div className="flex space-x-4 ">
                        <div className="">
                          <MdOutlineClass size={20} />
                        </div>
                        <span className="text-[13px] ">Plot Management</span>
                      </div>
                      {
                        classes === true ? <HiChevronDown /> : <button ><HiChevronRight /></button>
                      }
                    </span >
                }


                {
                  section === true ?
                    <div>
                      <span className={` hover:bg-[#FFFFFF]/10 cursor-pointer rounded-md h-12 items-center flex justify-between px-3 py-2 text-[13px]  transition ease-in-out duration-150  ${location.pathname === "/app/portfolio" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`} onClick={() => setSection(false)}>
                        <div className="flex space-x-4 ">
                          <div className="">
                            <MdOutlineClass size={20} />
                          </div>
                          <span className="text-[13px] ">Section Management</span>
                        </div>
                        {
                          section === true ? <button className="text-red-500" ><HiChevronDown /></button> : <HiChevronRight />
                        }
                      </span>
                      <div onClick={() => onClickMenu('/app/section')} className={` accor hover:bg-[#FFFFFF]/10 cursor-pointer rounded-md h-12 items-center flex justify-between px-3 py-2 text-[13px]  transition ease-in-out duration-150 ${location.pathname === "/app/section" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`}>
                        <div className="flex ml-4 space-x-3">
                          <SiOpenaigym className="text-[1rem]" />
                          <p>Section List</p>
                        </div>
                      </div>



                    </div>
                    :
                    <span className={` hover:bg-[#FFFFFF]/10 cursor-pointer rounded-md h-12 items-center flex justify-between px-3 py-2 text-[13px]  transition ease-in-out duration-150  ${location.pathname === "/app/portfolio" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`} onClick={() => handleResponse({ section: true })}>
                      <div className="flex space-x-4 ">
                        <div className="">
                          <MdOutlineClass size={20} />
                        </div>
                        <span className="text-[13px] ">Section Management</span>
                      </div>
                      {
                        section === true ? <HiChevronDown /> : <button ><HiChevronRight /></button>
                      }
                    </span >
                }




                {
                  bookingList === true ?
                    <div>
                      <span className={` hover:bg-[#FFFFFF]/10 cursor-pointer rounded-md h-12 items-center flex justify-between px-3 py-2 text-[13px]  transition ease-in-out duration-150  ${location.pathname === "/app/portfolio" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`} onClick={() => setSection(false)}>
                        <div className="flex space-x-4 ">
                          <div className="">
                            <MdOutlineClass size={20} />
                          </div>
                          <span className="text-[13px] ">Booking </span>
                        </div>
                        {
                          bookingList === true ? <button className="text-red-500" ><HiChevronDown /></button> : <HiChevronRight />
                        }
                      </span>
                      <div onClick={() => onClickMenu('/app/bookingList')} className={` accor hover:bg-[#FFFFFF]/10 cursor-pointer rounded-md h-12 items-center flex justify-between px-3 py-2 text-[13px]  transition ease-in-out duration-150 ${location.pathname === "/app/bookingList" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`}>
                        <div className="flex ml-4 space-x-3">
                          <SiOpenaigym className="text-[1rem]" />
                          <p>Booking List</p>
                        </div>
                      </div>



                    </div>
                    :
                    <span className={` hover:bg-[#FFFFFF]/10 cursor-pointer rounded-md h-12 items-center flex justify-between px-3 py-2 text-[13px]  transition ease-in-out duration-150  ${location.pathname === "/app/portfolio" ? "text-[#75f94c] bg-[#FFFFFF]/10" : "text-white"}`} onClick={() => handleResponse({ bookingList: true })}>
                      <div className="flex space-x-4 ">
                        <div className="">
                          <MdOutlineClass size={20} />
                        </div>
                        <span className="text-[13px] ">Booking </span>
                      </div>
                      {
                        bookingList === true ? <HiChevronDown /> : <button ><HiChevronRight /></button>
                      }
                    </span >
                }

              </div >
            </div >
          </nav >

        </div >


      </div >
    </>
  );

}

export default withRouter(Sidebar);
