import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, emsSlotActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BiSearch, BiSearchAlt } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill, } from "react-icons/bs";

class emsBookList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: 0,
      fieldsCalender: '',
      nextData: 0,
      offset: 0,
    }
  }

  componentDidMount() {
    let temp = {
      currentDate: 0,
      nextDate: 0,
    }
    this.props.dispatch(emsSlotActions.getShowEmsBookSlotList(temp))

  }
  static getDerivedStateFromProps(nextProps, prevState) {

    // console.log("STATIC______nextProps.emsSlot.addUserSuccess", nextProps.emsSlot.addUserSuccess);

    if (nextProps) {
      return {
        ...nextProps,

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  disableUser = (element) => {
    console.log('elementelementelementelementelementelementelementelementelementelementelementelement', element);
    let datatemp = {
      "id": element.slotId,
    }
    let paginationdata = {
      // "keyWord": "",
      // "pageNo": 1,
      // "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status of booked slot?',
      message: `Are you sure cancel booked slot ${element.slotId.slotNo}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(emsSlotActions.rejectBookEms(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  handleChangeDateCelander = () => {
    // console.log("data_________________data1111111111111", data);
    // console.log("data_________2222222222222222222", data);
    // let { classes } = this.props
    // let { currentDate } = classes

    let temp = {
      "calenderData": this.state.fieldsCalender,
    }
    this.props.dispatch(emsSlotActions.getShowEmsBookSlotList(temp))

  }

  handleChangeDate = (data) => {
    console.log("data_________________data", data);
    let { emsSlot } = this.props
    let { currentDate } = emsSlot

    let temp = {
      currentDate: currentDate,
      nextDate: data
    }
    this.props.dispatch(emsSlotActions.getShowEmsBookSlotList(temp))

  }
  static getDerivedStateFromProps(nextProps, prevState) {
  }

  inputCalenderChange = (e) => {
    console.log('1111111111111111111111111111111', e.target.name, e.target.value);
    this.setState({ fieldsCalender: e.target.value });
  }
  inputEmsData = () => {

    let temp = {
      "calenderData": this.state.fieldsCalender,
    }
    console.log("temp_______________temp", temp);
    this.props.dispatch(emsSlotActions.getShowEmsBookSlotList(temp))
  }


  render() {

    let { emsSlot, users } = this.props
    let { currentDate, loading, emsBookedList } = emsSlot
    // let { emsBookedList } = users
    console.log("RENDER__________________________________________________", emsBookedList);
    console.log("currentDate________currentDate", currentDate);
    console.log("this.state.fieldsCalender", this.state.fieldsCalender);


    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-x-auto  overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="bg-white border rounded-md  p-6 space-y-6">

                  <div className=" px-2">
                    <h3 className="text-5xl font-semibold  ">EMS Book List</h3>
                  </div>
                  <div className='flex justify-between '>
                    <div  >

                      <div className="w-full cursor-pointer ">
                        <label class="block text-gray-700 text-sm" for="username"> Date</label>
                        <div className='flex'>
                          <input className={`px-3 py-2 placeholder-gray-400 text-gray-600 bg-gray-100 rounded text-sm shadow focus:outline-none border-gray-600 focus:ring-gray-600 w-full ease-linear  transition-all duration-150 ${this.state.errorsCalender && !this.state.errorsCalender["resultDate"] ? "placeholder-gray-500" : "border border-opacity-100  "}`}
                            id="resultDate" name="resultDate" placeholder="Select Date" value={this.state.fieldsCalender.date} type="date" onChange={this.inputCalenderChange} />
                          <button className='bg-gray-600 hover:bg-gray-100 border border-black text-white item-center h-10' onClick={() => this.inputEmsData()}><BiSearchAlt className='w-10 h-7 cursor-pointer  hover:text-gray-500' /></button>
                          {this.state.errorsCalender && this.state.errorsCalender["resultDate"] ?
                            <div className="invalid-feedback text-yellow-600">
                              {this.state.errorsCalender["resultDate"]}
                            </div>
                            : null}
                        </div>

                      </div>
                    </div>

                    <p className='border rounded-lg px-16 text-xl bg-gray-300 text-gray-700 font-bold py-4'>Date :-  {moment(new Date(parseInt(currentDate))).utcOffset("+05:30").format("DD/MM/YYYY")}</p>
                    <div className='flex justify-between space-x-8 px-10'>
                      <button onClick={() => this.handleChangeDate(0)}><BsFillArrowLeftCircleFill className='w-10 h-10 cursor-pointer hover:text-gray-500' /></button>
                      <button onClick={() => this.handleChangeDate(1)}><BsFillArrowRightCircleFill className='w-10 h-10 cursor-pointer hover:text-gray-500' /></button>
                    </div>
                  </div>


                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto  ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className=" ">
                          <table className="min-w-full border-0 divide-y  ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">userName</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Slot No</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Price</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Starting Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Ending Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>

                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Status </th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {
                                emsBookedList && emsBookedList.length > 0 ?
                                  emsBookedList.map((element, index) =>

                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-800 font-medium whitespace-nowrap capitalize" title={element.name} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element && element.userData && element.userData.name ? element.userData.name : "_"}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-800 font-medium whitespace-nowrap capitalize" title={element.name} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element && element.slotNo ? element.slotNo : "_"}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.price ? element.price : "_"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap uppercase">     {element && element.startingTime ? element.startingTime : "_"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap uppercase"> {element && element.endingTime ? element.endingTime : "_"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "_"))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>


                                      < td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isDisable == true ?
                                            <button class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUser(element)}>

                                              <label>Booked</label>
                                            </button>
                                            : <label class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-md shadow-sm " disabled >

                                              <label>Un Book</label>
                                            </label>}
                                        </span>
                                      </td>
                                      {/* <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isBooked == true ?
                                            <button class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-gray-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-gray-400 cursor-pointer" onClick={() => this.disableUser(element)}>

                                              <label>Booked</label>
                                            </button>
                                            : <label class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-gray-300 border rounded-md shadow-sm " disabled >

                                              <label>Not Booked</label>
                                            </label>}
                                        </span>
                                      </td> */}





                                    </tr>
                                  ) :

                                  (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    } */}
                  </div>
                </section>
              </div>
            </div >
          </main >
        </div >


      </>

    );
  }
}
function mapStateToProps(state) {
  const { emsSlot, users } = state;
  return {
    emsSlot, users
  };
}
export default connect(mapStateToProps)(emsBookList);
