import { userConstants, massageConstants } from '../_constants';
import { userService, massageService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const massageActions = {

    login,
    logout,
    disablemassage,
    getAllmassage,
    createMassageUser,
    updateMassageUser,
    getMassageUserList,
    deleteRestoCat,
    uploadImageClear,
    getMassageUserById,
    updateMassageUserPassword
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createMassageUser(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        massageService.createMassageUser(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Massage Trainer Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getMassageUserList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.ADD_MASSAGE_REQUEST } }
    function success(users) { return { type: massageConstants.ADD_MASSAGE_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.ADD_MASSAGE_FAILURE, error } }
}
function updateMassageUser(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        massageService.updateMassageUser(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Massage Trainer Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getMassageUserList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.UPDATE_MASSAGE_REQUEST } }
    function success(users) { return { type: massageConstants.UPDATE_MASSAGE_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.UPDATE_MASSAGE_FAILURE, error } }
}

function updateMassageUserPassword(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        massageService.updateMassageUserPassword(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Password Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getMassageUserList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.UPDATE_MASSAGE_USER_PASSWORD_REQUEST } }
    function success(users) { return { type: massageConstants.UPDATE_MASSAGE_USER_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.UPDATE_MASSAGE_USER_PASSWORD_FAILURE, error } }
}





function getAllmassage() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        massageService.getAllmassage()
            .then(
                users => {
                    console.log("getAllmassage $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.GETALL_MASSAGE_REQUEST } }
    function success(users) { return { type: massageConstants.GETALL_MASSAGE_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.GETALL_MASSAGE_FAILURE, error } }
}
function getMassageUserById() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        massageService.getMassageUserById()
            .then(
                users => {
                    console.log("getMassageUserById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.GET_ID_MASSAGE_REQUEST } }
    function success(users) { return { type: massageConstants.GET_ID_MASSAGE_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.GET_ID_MASSAGE_FAILURE, error } }
}
function getMassageUserList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        massageService.getMassageUserList(data)
            .then(
                users => {
                    console.log("getMassageUserList $$$$ action:", users);
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.GET_LIST_MASSAGE_REQUEST } }
    function success(users) { return { type: massageConstants.GET_LIST_MASSAGE_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.GET_LIST_MASSAGE_FAILURE, error } }
}


function deleteRestoCat(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        massageService.deleteRestoCat(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getMassageUserList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.DELETE_MASSAGE_REQUEST } }
    function success(users) { return { type: massageConstants.DELETE_MASSAGE_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.DELETE_MASSAGE_FAILURE, error } }
}
function disablemassage(data, paginationData) {

    return dispatch => {
        dispatch(request());
        massageService.disableMassage(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getMassageUserList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.DISABLE_MASSAGE_REQUEST } }
    function success(users) { return { type: massageConstants.DISABLE_MASSAGE_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.DISABLE_MASSAGE_FAILURE, error } }
}
