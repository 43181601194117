import React, { Component } from 'react';
import { connect } from 'react-redux';
import { classesActions, userActions, groupClassCategoryActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import CreateAddCategoryModal from "./components/CreateAddCategoryModal/CreateAddCategoryModal";
import UpdateCategoryModal from "./components/UpdateCategoryModal/UpdateCategoryModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";

class Classes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideo: false,
      isVideoUpdate: false,
      ClassesRowData: {},
      fieldsClasses: {},
      errorsClasses: {},
      fieldsClassesUpdate: {},
      errorsUpdateClasses: {},
      viewRowData: {},
      type: null,
      gameType: null,
      rankType: null,
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCategoryCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    //  let reqData = { "id": "642e67eae8b9716b9142c41b"}
    this.props.dispatch(classesActions.getClassesList(temp))
    this.props.dispatch(groupClassCategoryActions.getAllGroupClassCategory())




  }
  static getDerivedStateFromProps(nextProps, prevState) {

    // console.log("STATIC______nextProps.Classes.addUserSuccess", nextProps.Classes.addUserSuccess);

    if (nextProps.classes.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsClasses: {},
        errorsClasses: {},
        addUserCreateModal: false,
        UpdateCategoryCreateModal: false,
        moreDetailsCreateModal: false,

        type: null,
        gameType: null,
        rankType: null,

      }
    }


    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(classesActions.getClassesList(datatemp));
  }
  handleSearch = (event) => {
    // console.log("666666666666666", event.target.value);
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(classesActions.getClassesList(data));
  }
  disableUser = (element) => {
    let datatemp = {
      "id": element.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to Change Status Of User?',
      message: `Are you sure about slot no. ${element.slotNo}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(classesActions.disableClasses(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteCategory = (element) => {
    let datatemp = {
      "id": element.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete slot no. ${element.slotNo}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(classesActions.deleteClasses(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  verifyEmail = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to verifyEmail?',
      message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(classesActions.verifyEmail(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    // let req = {
    //     "id": data.id
    //   }
    //   this.props.dispatch(classesActions.getClassesUserById(req));

    this.setState({ moreDetailsCreateModal: true, ClassesRowData: data });
    console.log("fgfdgf1111111222211111111111111111111111111");
  }

  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }

  handleOpenCreateModalUpdatePassword = (data) => {
    this.setState({ UpdateCategoryCreateModal: true, fieldsClassesUpdate: data });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdateCategoryCreateModal: false });
  }

  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({ addUserCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }

  handleOpenCreateModal = () => {
    this.setState({ addUserCreateModal: true });
  }
  inputAddUserChange = (e) => {
    console.log("e_________________________", e.target.name, e.target.value);
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsClasses = this.state.fieldsClasses;
    let errorsClasses = this.state.errorsClasses;
    fieldsClasses[name] = value;
    console.log(name, value);
    errorsClasses[name] = "";
    this.setState({ fieldsClasses, errorsClasses });
  }
  inputChangeUpdateCategory = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsClassesUpdate = this.state.fieldsClassesUpdate;
    let errorsUpdateClasses = this.state.errorsUpdateClasses;
    fieldsClassesUpdate[name] = value;
    errorsUpdateClasses[name] = "";
    console.log(name, value);
    this.setState({ fieldsClassesUpdate, errorsUpdateClasses });
  }

  createRestoCategorySubmit = () => {

    let { users } = this.props;


    let { filesDetails,
      //  filesDetailsVideo
    } = users;
    console.log('filesDetailsfilesDetails__________', filesDetails);
    if (this.handleValidationAddUser()) {
      let reqData = {
        "groupClassCategoryId": this.state.fieldsClasses.groupClassCategoryId,
        "slotNo": this.state.fieldsClasses.slotNo,
        "price": this.state.fieldsClasses.price,
        "startingTime": this.state.fieldsClasses.startingTime,
        "endingTime": this.state.fieldsClasses.endingTime,
        "limit": this.state.fieldsClasses.limit,
        // "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,


      }

      console.log("createRestoCategory>>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(classesActions.createClasses(reqData));
    }

  }


  updateCategorySubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;

    if (this.handleValidationUpdateCategory()) {
      let reqData = {
        "id": this.state.fieldsClassesUpdate.id,
        "slotNo": this.state.fieldsClassesUpdate.slotNo,
        "price": this.state.fieldsClassesUpdate.price,
        "startingTime": this.state.fieldsClassesUpdate.startingTime,
        "endingTime": this.state.fieldsClassesUpdate.endingTime,
        "groupClassCategoryId": this.state.fieldsClassesUpdate.groupClassCategoryId,
        "limit": this.state.fieldsClassesUpdate.limit,
        // "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>000000000", reqData);

      this.props.dispatch(classesActions.updateClassesUser(reqData, paginationData));
    }

  }

  handleValidationUpdateCategory = () => {
    let fieldsClassesUpdate = this.state.fieldsClassesUpdate;
    let errorsUpdateClasses = {};
    let formIsValid = true;

    //name
    // if (!fieldsClassesUpdate["groupClassCategoryId.categoryType"] || fieldsClassesUpdate["groupClassCategoryId.categoryType"] === "") {
    //   formIsValid = false;
    //   errorsUpdateClasses["groupClassCategoryId.categoryType"] = "Cannot be empty";
    // }


    this.setState({ errorsUpdateClasses: errorsUpdateClasses });
    return formIsValid;
  }

  handleValidationAddUser = () => {
    let fieldsClasses = this.state.fieldsClasses;
    let errorsClasses = {};
    let formIsValid = true;

    //name
    if (!fieldsClasses["limit"] || fieldsClasses["limit"] === "") {
      formIsValid = false;
      errorsClasses["limit"] = "Cannot be empty limit";
    }
    if (!fieldsClasses["slotNo"] || fieldsClasses["slotNo"] === "") {
      formIsValid = false;
      errorsClasses["slotNo"] = "Cannot be empty slotNo";
    }
    // if (!fieldsClasses["image"] || fieldsClasses["image"] === "") {
    //   formIsValid = false;
    //   errorsClasses["image"] = "Cannot be empty image";
    // }

    this.setState({ errorsClasses: errorsClasses });
    return formIsValid;
  }

  handleSelectType = (e) => {
    console.log("handleSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }



  handleSelectRankType = (e) => {
    console.log("handleSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleUpdateSelectType = (e) => {
    console.log("handleUpdateSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }

  handleUpdateSelectGameType = (e) => {
    console.log("handleUpdateSelectGameType:::#", e.target.value);
    let { value } = e.target;
    this.setState({ gameType: value });
  }

  handleUpdateSelectRankType = (e) => {
    console.log("handleUpdateSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleFile = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.setState({ selectedFile: null });
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));

    }
    else {
      console.log("No File To Upload!")
    }

  }





  inputChangeIsVideo = (e) => {
    e.preventDefault();
    this.setState({ isVideo: !this.state.isVideo });
  }

  inputChangeIsVideoUpdate = (e) => {
    e.preventDefault();
    this.setState({ isVideoUpdate: !this.state.isVideoUpdate });
  }

  render() {

    let { classes, users, groupClassCategory } = this.props;
    let { items, total, loading } = classes;
    let { filesDetails } = users;
    let { allCategory } = groupClassCategory;
    console.log("RENDER11111111112222222222211111", allCategory);
    console.log("render55555555555555555", this.state.ClassesRowData);



    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-x-auto  overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="bg-white border rounded-md  p-6 space-y-6">

                  <div className="flex flex-wrap itClasses-center w-full justify-between ">
                    <div className="">
                      <h3 className="text-xl font-semibold  ">Classes Slot Management</h3>
                    </div>

                    <div className='flex space-x-6 '>

                      {/* <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form> */}

                      <button className="bg-blue-500 text-white py-2 text-sm px-2 rounded-md font-medium capitalize flex itClasses-center " onClick={() => this.handleOpenCreateModal()}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1" />  Add Classes Slot </button>

                    </div>

                  </div>





                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto  ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className=" ">
                          <table className="min-w-full border-0 divide-y  ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Class Name</th> */}
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Class Name</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Slot No.</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Price</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Starting Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">EndingTime</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Limit</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>

                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Status </th>
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-800 font-medium whitespace-nowrap capitalize" style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                     {element && element.groupClassCategoryId && element.groupClassCategoryId.categoryType ? element.groupClassCategoryId.categoryType : "-"}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.slotNo && element.slotNo ? element.slotNo : "-"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.price && element.price ? element.price : "-"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.startingTime && element.startingTime ? element.startingTime : "-"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.endingTime && element.endingTime ? element.endingTime : "-"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.limit && element.limit ? element.limit : "-"}</td>
                                      {/* 
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <div className='flex justify-center'>
                                          <img className="object-cover h-10 rounded-sm w-14" src={element && element.imageLinkUrl ? element.imageLinkUrl : "NA"} alt="not found" />
                                        </div>
                                      </td> */}

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>




                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isDisable == false ?
                                            <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUser(element)}>

                                              <label>Enabled</label>
                                            </div>
                                            : <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUser(element)}>

                                              <label>Disabled</label>
                                            </div>}
                                        </span>
                                      </td>


                                      <td className=" px-2 py-3 text-gray-600 whitespace-nowrap space-x-2 text-center flex justify-center">
                                        <div className='flex space-x-2'>
                                          {/* <span className="relative ">
                                            {element.isActive ? "ADDED" : 
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer  " onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                              <span className='tooltip-text bg-gray-500 p-6 mx-auto top-0 left-0 -mt-8 -ml-6 text-white text-sm rounded'>More Details</span>
                                              <BiDetail style={{ fontSize: "1.5rem" }} />
                                            </div>}
                                          </span> */}

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              <span className='tooltip-text bg-gray-500 p-6 mx-auto top-0 left-0 -mt-8  text-white text-sm rounded'>Edit</span>

                                              <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.deleteCategory(element)}>
                                              <span className='tooltip-text bg-gray-500 p-6 mx-auto top-0 left-0 -mt-8 -ml-2 text-white text-sm rounded'>Delete</span>
                                              <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>
                                        </div>
                                      </td>


                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <CreateAddCategoryModal
          addUserCreateModal={this.state.addUserCreateModal}
          fieldsClasses={this.state.fieldsClasses}
          errorsClasses={this.state.errorsClasses}
          allCategory={allCategory}
          inputAddUserChange={this.inputAddUserChange}
          handleFile={this.handleFile}
          handleFile2={this.handleFile2}
          createRestoCategorySubmit={this.createRestoCategorySubmit}
          handleSelectType={this.handleSelectType}
          handleSelectGameType={this.handleSelectGameType}
          handleSelectRankType={this.handleSelectRankType}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputChangeIsVideo={this.inputChangeIsVideo}
          isVideo={this.state.isVideo}
          filesDetails={filesDetails}
        />

        <UpdateCategoryModal
          UpdateCategoryCreateModal={this.state.UpdateCategoryCreateModal}
          fieldsClassesUpdate={this.state.fieldsClassesUpdate}
          errorsUpdateClasses={this.state.errorsUpdateClasses}
          inputChangeUpdateCategory={this.inputChangeUpdateCategory}
          updateCategorySubmit={this.updateCategorySubmit}

          handleFile={this.handleFile}
          allCategory={allCategory}
          handleFile2={this.handleFile2}
          handleUpdateSelectType={this.handleUpdateSelectType}
          handleUpdateSelectGameType={this.handleUpdateSelectGameType}
          handleUpdateSelectRankType={this.handleUpdateSelectRankType}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
          inputChangeIsVideoUpdate={this.inputChangeIsVideoUpdate}
          isVideoUpdate={this.state.isVideoUpdate}
          filesDetails={filesDetails}
        />

        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          ClassesRowData={this.state.ClassesRowData}
          // getProductByCategoryId={getProductByCategoryId}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        {/* <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        /> */}
      </>

    );
  }
}
function mapStateToProps(state) {
  const { classes, users, groupClassCategory, } = state;
  return {
    classes,
    users,
    groupClassCategory
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Classes);
