export const massageConstants = {

    GET_ID_MASSAGE_REQUEST: 'GET_ID_MASSAGE_REQUEST',
    GET_ID_MASSAGE_SUCCESS: 'GET_ID_MASSAGE_SUCCESS',
    GET_ID_MASSAGE_FAILURE: 'GET_ID_MASSAGE_FAILURE',

    GETALL_MASSAGE_REQUEST: 'GETALL_MASSAGE_REQUEST',
    GETALL_MASSAGE_SUCCESS: 'GETALL_MASSAGE_SUCCESS',
    GETALL_MASSAGE_FAILURE: 'GETALL_MASSAGE_FAILURE',

    GET_LIST_MASSAGE_REQUEST: 'GET_LIST_MASSAGE_REQUEST',
    GET_LIST_MASSAGE_SUCCESS: 'GET_LIST_MASSAGE_SUCCESS',
    GET_LIST_MASSAGE_FAILURE: 'GET_LIST_MASSAGE_FAILURE',


    ADD_MASSAGE_REQUEST: 'ADD_MASSAGE_REQUEST',
    ADD_MASSAGE_SUCCESS: 'ADD_MASSAGE_SUCCESS',
    ADD_MASSAGE_FAILURE: 'ADD_MASSAGE_FAILURE',

    DELETE_MASSAGE_REQUEST: 'DELETE_MASSAGE_REQUEST',
    DELETE_MASSAGE_SUCCESS: 'DELETE_MASSAGE_SUCCESS',
    DELETE_MASSAGE_FAILURE: 'DELETE_MASSAGE_FAILURE',

    UPDATE_MASSAGE_REQUEST: 'UPDATE_MASSAGE_REQUEST',
    UPDATE_MASSAGE_SUCCESS: 'UPDATE_MASSAGE_SUCCESS',
    UPDATE_MASSAGE_FAILURE: 'UPDATE_MASSAGE_FAILURE',


    UPDATE_MASSAGE_USER_PASSWORD_REQUEST: 'UPDATE_MASSAGE_USER_PASSWORD_REQUEST',
    UPDATE_MASSAGE_USER_PASSWORD_SUCCESS: 'UPDATE_MASSAGE_USER_PASSWORD_SUCCESS',
    UPDATE_MASSAGE_USER_PASSWORD_FAILURE: 'UPDATE_MASSAGE_USER_PASSWORD_FAILURE',

    DISABLE_MASSAGE_REQUEST: 'DISABLE_MASSAGE_REQUEST',
    DISABLE_MASSAGE_SUCCESS: 'DISABLE_MASSAGE_SUCCESS',
    DISABLE_MASSAGE_FAILURE: 'DISABLE_MASSAGE_FAILURE',

};
