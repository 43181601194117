export const valetConstants = {

    GET_ID_VALET_REQUEST: 'GET_ID_VALET_REQUEST',
    GET_ID_VALET_SUCCESS: 'GET_ID_VALET_SUCCESS',
    GET_ID_VALET_FAILURE: 'GET_ID_VALET_FAILURE',

    GETALL_VALET_REQUEST: 'GETALL_VALET_REQUEST',
    GETALL_VALET_SUCCESS: 'GETALL_VALET_SUCCESS',
    GETALL_VALET_FAILURE: 'GETALL_VALET_FAILURE',

    GET_LIST_VALET_REQUEST: 'GET_LIST_VALET_REQUEST',
    GET_LIST_VALET_SUCCESS: 'GET_LIST_VALET_SUCCESS',
    GET_LIST_VALET_FAILURE: 'GET_LIST_VALET_FAILURE',

    GET_LIST_PARKING_REQUEST: 'GET_LIST_PARKING_REQUEST',
    GET_LIST_PARKING_SUCCESS: 'GET_LIST_PARKING_SUCCESS',
    GET_LIST_PARKING_FAILURE: 'GET_LIST_PARKING_FAILURE',


    ADD_VALET_REQUEST: 'ADD_VALET_REQUEST',
    ADD_VALET_SUCCESS: 'ADD_VALET_SUCCESS',
    ADD_VALET_FAILURE: 'ADD_VALET_FAILURE',

    DELETE_VALET_REQUEST: 'DELETE_VALET_REQUEST',
    DELETE_VALET_SUCCESS: 'DELETE_VALET_SUCCESS',
    DELETE_VALET_FAILURE: 'DELETE_VALET_FAILURE',

    UPDATE_VALET_REQUEST: 'UPDATE_VALET_REQUEST',
    UPDATE_VALET_SUCCESS: 'UPDATE_VALET_SUCCESS',
    UPDATE_VALET_FAILURE: 'UPDATE_VALET_FAILURE',
    
    UPDATE_VALET_PASSWORD_REQUEST: 'UPDATE_VALET_PASSWORD_REQUEST',
    UPDATE_VALET_PASSWORD_SUCCESS: 'UPDATE_VALET_PASSWORD_SUCCESS',
    UPDATE_VALET_PASSWORD_FAILURE: 'UPDATE_VALET_PASSWORD_FAILURE',

    UPDATE_VALET_USER_REQUEST: 'UPDATE_VALET_USER_REQUEST',
    UPDATE_VALET_USER_SUCCESS: 'UPDATE_VALET_USER_SUCCESS',
    UPDATE_VALET_USER_FAILURE: 'UPDATE_VALET_USER_FAILURE',

    DISABLE_VALET_REQUEST: 'DISABLE_VALET_REQUEST',
    DISABLE_VALET_SUCCESS: 'DISABLE_VALET_SUCCESS',
    DISABLE_VALET_FAILURE: 'DISABLE_VALET_FAILURE',

};
