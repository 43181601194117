export const plotConstants = {

    GET_ID_PLOT_REQUEST: 'GET_ID_PLOT_REQUEST',
    GET_ID_PLOT_SUCCESS: 'GET_ID_PLOT_SUCCESS',
    GET_ID_PLOT_FAILURE: 'GET_ID_PLOT_FAILURE',

    GETALL_PLOT_REQUEST: 'GETALL_PLOT_REQUEST',
    GETALL_PLOT_SUCCESS: 'GETALL_PLOT_SUCCESS',
    GETALL_PLOT_FAILURE: 'GETALL_PLOT_FAILURE',

    GET_LIST_PLOT_REQUEST: 'GET_LIST_PLOT_REQUEST',
    GET_LIST_PLOT_SUCCESS: 'GET_LIST_PLOT_SUCCESS',
    GET_LIST_PLOT_FAILURE: 'GET_LIST_PLOT_FAILURE',

    GET_LIST_PARKING_REQUEST: 'GET_LIST_PARKING_REQUEST',
    GET_LIST_PARKING_SUCCESS: 'GET_LIST_PARKING_SUCCESS',
    GET_LIST_PARKING_FAILURE: 'GET_LIST_PARKING_FAILURE',


    ADD_PLOT_REQUEST: 'ADD_PLOT_REQUEST',
    ADD_PLOT_SUCCESS: 'ADD_PLOT_SUCCESS',
    ADD_PLOT_FAILURE: 'ADD_PLOT_FAILURE',

    DELETE_PLOT_REQUEST: 'DELETE_PLOT_REQUEST',
    DELETE_PLOT_SUCCESS: 'DELETE_PLOT_SUCCESS',
    DELETE_PLOT_FAILURE: 'DELETE_PLOT_FAILURE',

    UPDATE_PLOT_REQUEST: 'UPDATE_PLOT_REQUEST',
    UPDATE_PLOT_SUCCESS: 'UPDATE_PLOT_SUCCESS',
    UPDATE_PLOT_FAILURE: 'UPDATE_PLOT_FAILURE',

    UPDATE_PLOT_PASSWORD_REQUEST: 'UPDATE_PLOT_PASSWORD_REQUEST',
    UPDATE_PLOT_PASSWORD_SUCCESS: 'UPDATE_PLOT_PASSWORD_SUCCESS',
    UPDATE_PLOT_PASSWORD_FAILURE: 'UPDATE_PLOT_PASSWORD_FAILURE',

    DISABLE_PLOT_REQUEST: 'DISABLE_PLOT_REQUEST',
    DISABLE_PLOT_SUCCESS: 'DISABLE_PLOT_SUCCESS',
    DISABLE_PLOT_FAILURE: 'DISABLE_PLOT_FAILURE',

};
