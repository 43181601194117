import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, classesActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BiDetail } from "react-icons/bi";

class classBookListDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
    }
  }

  componentDidMount() {
    let data = {
      "calenderData": this.props.match.params.date,
      "slotId": this.props.match.params.slotId,
    }
    this.props.dispatch(classesActions.onlyBookingClassSlotAdmin(data))

  }
  static getDerivedStateFromProps(nextProps, prevState) {

    // console.log("STATIC______nextProps.classes.addUserSuccess", nextProps.classes.addUserSuccess);

    if (nextProps) {
      return {
        ...nextProps,

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }

  disableUser = (element) => {
    let datatemp = {
      "id": element.id,
    }
    let paginationdata = {
      // "keyWord": "",
      // "pageNo": 1,
      // "size": this.state.size
    }

    console.log("datatempdatatempdatatempdatatemp", datatemp);
    confirmAlert({


      title: 'Confirm to change status of booked slot?',
      message: `Are you sure cancel booked slot ${element.slotId.slotNo}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(classesActions.rejectBookClass(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  render() {

    let { classes } = this.props

    let { itams, loading, total, bookSlot, unBookSlot, totalslot } = classes

    console.log("totalDatatotalDatatotalData", itams);
    console.log("bookSlotbookSlotbookSlotbookSlot",bookSlot);



    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-x-auto  overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">



                <div className="bg-white border rounded-md  p-6 my-2" >

                  <div className=' bg-white shadow-xl   pb-6 my-4 flex justify-center'>


                    <span className=' text-3xl text-gray-600 font-bold'>  Date :-  {moment(new Date(parseInt(this.props.match.params.date))).utcOffset("+05:30").format("DD/MM/YYYY")}</span>


                  </div>
                  <div className='mx-auto gap-8 grid md:grid-cols-1 xl:grid-cols-3'>
                    <div className="pl-1 w-96 h-20 bg-green-400 rounded-lg shadow-md">
                      <div className="flex w-full h-full py-2 px-4 bg-white rounded-lg justify-between">
                        <div className="my-auto">
                          <p className="font-bold">Booked Slot</p>
                          <p className="text-lg">{bookSlot}</p>
                        </div>
                        <div className="my-auto">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    {/*  Monthly card end  */}
                    {/*  Annual card start */}
                    <div className="pl-1 w-96 h-20 bg-blue-500 rounded-lg shadow-md">
                      <div className="flex w-full h-full py-2 px-4 bg-white rounded-lg justify-between">
                        <div className="my-auto">
                          <p className="font-bold">Unbooked Slot</p>
                          <p className="text-lg">{unBookSlot && unBookSlot ? unBookSlot : 0}</p>
                        </div>
                        <div className="my-auto">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    {/*  Annual card end  */}
                    {/*  Tasks card start  */}
                    <div className="pl-1 w-96 h-20 bg-purple-500 rounded-lg shadow-md">
                      <div className="flex w-full h-full py-2 px-4 bg-white rounded-lg justify-between">
                        <div className="my-auto">
                          <p className="font-bold">Total</p>
                          <p className="text-lg">{totalslot}</p>
                        </div>
                        <div className="my-auto">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    {/*  Tasks card end  */}
                    {/*  Pending requests card start  */}

                  </div>
                </div>


                <section className="bg-white border rounded-md  p-6 space-y-6">


                  {/* 
                  <div className=" px-2 pt-4">
                    <h3 className="text-3xl font-semibold  ">Class Book List Detail</h3>
                  </div> */}


                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto  ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className=" ">
                          <table className="min-w-full border-0 divide-y  ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">UserName</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Mobile</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Slot No.</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Price</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Starting Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Ending Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>

                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Status </th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">View Details </th> */}
                                {/* <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {
                           itams && itams.length > 0 ?
                                  itams.map((element, index) =>
                                  (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-800 font-medium whitespace-nowrap capitalize" title={element.name} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element && element.userId && element.userId.name ? element.userId.name : "_"}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                      {element && element.userId && element.userId.mobile ? element.userId.mobile : "_"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap uppercase">     
                                      {element && element.slotId && element.slotId.slotNo ? element.slotId.slotNo : "_"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap uppercase"> 
                                      {element && element.slotId && element.slotId.price ? element.slotId.price : "_"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap uppercase"> 
                                      {element && element.slotId && element.slotId.startingTime ? element.slotId.startingTime : "_"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap uppercase"> 
                                      {element && element.slotId && element.slotId.endingTime ? element.slotId.endingTime : "_"}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isDisable == true ?
                                            <button class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUser(element)}>

                                              <label>Reject</label>
                                            </button>
                                            : <label class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-md shadow-sm " disabled >

                                              <label>Rejected</label>
                                            </label>}
                                        </span>
                                      </td>

                                      {/* <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isDisable == false ?
                                            <button class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-gray-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-gray-400 cursor-pointer" onClick={() => this.disableUser(element)}>

                                              <label>Booked</label>
                                            </button>
                                            : <label class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-gray-500 border rounded-md shadow-sm " disabled >

                                              <label>Not Booked</label>
                                            </label>}
                                        </span>
                                      </td> */}


                                      <td className=" px-2 py-3 text-gray-600 whitespace-nowrap space-x-2 text-center flex justify-center">
                                        <div className='flex space-x-2'>
                                          {/* <span className="relative ">
                                            {element.isActive ? "ADDED" :
                                              <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer  " onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                                <span className='tooltip-text bg-gray-500 p-6 mx-auto top-0 left-0 -mt-8 -ml-6 text-white text-sm rounded'>More Details</span>
                                                <BiDetail style={{ fontSize: "1.5rem" }} />
                                              </div>}
                                          </span> */}

                                          {/* <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              <span className='tooltip-text bg-gray-500 p-6 mx-auto top-0 left-0 -mt-8  text-white text-sm rounded'>Edit</span>

                                              <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.deleteCategory(element)}>
                                              <span className='tooltip-text bg-gray-500 p-6 mx-auto top-0 left-0 -mt-8 -ml-2 text-white text-sm rounded'>Delete</span>
                                              <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span> */}
                                        </div>
                                      </td>


                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>




      </>

    );
  }
}
function mapStateToProps(state) {
  const { classes } = state;
  return {
    classes
  };
}
export default connect(mapStateToProps)(classBookListDetail);
