
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const plotService = {
    login,
    logout,
    getAllPlotList,
    createPlot,
    updatePlot,
    getPlotList,
    deleteGroup,
    disablegroupClass,
   

};
function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getPlotList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
   console.log('requestOptionsrequestOptionsrequestOptionsrequestOptionsrequestOptions', requestOptions);
    return fetch(CONST.BACKEND_URL + `/getPlotList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getPlotList: data.data
            }
           console.log("getPlotListgetPlotListgetPlotList", userObj);

            return userObj;
        });
}

function getAllPlotList() {
    //// console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllPlotList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllPlotList: data.data
            }
           console.log("i am in service getAllgroupClass", userObj);

            return userObj;
        });
}


function deleteGroup(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deletePlot`, requestOptions)
        .then(handleResponse)
        .then(data => {


            let userObj = {
                deleteGroup: data.data
            }
           // console.log("i am in service''...>> deleteGroup ::", userObj);

            return userObj;
        });
}
function disablegroupClass(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateGroupStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateGroupStatus: data.data
            }
           // console.log("i am in service''...>> updateGroupStatus ::", userObj);

            return userObj;
        });
}

function updatePlot(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePlot`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updatePlot: data.data
            }
           // console.log("I am in service updatePlot", userObj);

            return userObj;
        });
}

function createPlot(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createPlot`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createPlot: data.data
            }
           // console.log("I am in service createPlot", userObj);

            return userObj;
        });
}



function handleResponse(response) {
    //// console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
           // console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
           // console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}