import {
  userConstants
} from '../_constants';

export function users(state = {}, action) {
  console.log("actionactionactionactionactionactionaction", action);

  switch (action.type) {

    case userConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_USER_STATUS_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_USER_STATUS_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case userConstants.UPDATE_USER_STATUS_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case userConstants.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getUserListItems: action.users.getUserList.list,
        total: action.users.getUserList.total
      };
    case userConstants.GET_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_TICKET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case userConstants.GET_TICKET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketItems: action.users.getTicketList.list,
        ticketTotal: action.users.getTicketList.total
      };
    case userConstants.GET_TICKET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.DELETE_TICKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_TICKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case userConstants.DELETE_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case userConstants.DISABLE_TICKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_TICKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case userConstants.DISABLE_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.APPROVE_TICKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.APPROVE_TICKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case userConstants.APPROVE_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        approveTicket: action.users.approveTicket
      };










    case userConstants.ADD_MATCH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_MATCH_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        createMatch: action.users.createMatch,
        // total: action.users.getUserActive.total
      };
    case userConstants.ADD_MATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.DEDUCT_BALANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DEDUCT_BALANCE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        deductUserBalance: action.users.deductUserBalance,
        // total: action.users.getUserActive.total
      };
    case userConstants.DEDUCT_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.CREATE_BALANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_BALANCE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        AddUserBalance: action.users.AddUserBalance,
        // total: action.users.getUserActive.total
      };
    case userConstants.CREATE_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    // case userConstants.DEDUCT_BALANCE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case userConstants.DEDUCT_BALANCE_SUCCESS:
    //   return {
    //     ...state,
    //     addUserSuccess: true,
    //     loading: false,
    //     deductUserBalance: action.users.deductUserBalance,
    //     // total: action.users.getUserActive.total
    //   };
    // case userConstants.DEDUCT_BALANCE_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error
    //   };

    case userConstants.ADD_SERIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_SERIES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        createSerie: action.users.createSerie,
        // total: action.users.getUserActive.total
      };
    case userConstants.ADD_SERIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.ADD_SPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_SPORT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        createSport: action.users.createMatch,
        // total: action.users.getUserActive.total
      };
    case userConstants.ADD_SPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GETALL_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        getUserProfile: null,
      };
    case userConstants.GETALL_PROFILE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getUserProfile: action.users.getUserProfile,
        total: action.users.getUserProfile.total
      };
    case userConstants.GETALL_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.UPDATE_MATCH_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_MATCH_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        updateMatchStatus: action.users.updateMatchStatus,
        // total: action.users.getUserActive.total
      };
    case userConstants.UPDATE_MATCH_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GETALL_MATCH_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_MATCH_ACTIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        isMatchImageUpdate: false,
        loading: false,
        filesDetails: {},
        allMatchActive: action.users.allMatchActive,
        // total: action.users.getUserActive.total
      };
    case userConstants.GETALL_MATCH_ACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GETALL_MATCH_REQUEST:
      return {
        ...state,
        loading: true,
        allMatch: null,
      };
    case userConstants.GETALL_MATCH_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // addUserSuccess: true,
        allMatch: action.users.allMatch,
        allMarket: null,
        // total: action.users.getUserActive.total
      };
    case userConstants.GETALL_MATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_MATCH_BY_MATCHID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_MATCH_BY_MATCHID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getMatchBymatchIdItems: action.users.getMatchBymatchId,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_MATCH_BY_MATCHID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_ODDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ODDS_SUCCESS:
      console.log("state state  ", state.getallOdds);
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getallOdds: action.users.getallOdds && action.users.getallOdds.marketId ? action.users.getallOdds : state.getallOdds,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_ODDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GETALL_SERIES_REQUEST:
      return {
        ...state,
        loading: true,
        allSeries: null,
      };
    case userConstants.GETALL_SERIES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allSeries: action.users.allSeries,
        allMarket: null,
        allMatch: null
      };
    case userConstants.GETALL_SERIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case userConstants.GETALL_SPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_SPORT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allSport: action.users.AllSport,
        allSeries: null,
        // total: action.users.getUserActive.total
      };
    case userConstants.GETALL_SPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GETALL_USER_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_ACTIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getUserActive.list,
        total: action.users.getUserActive.total
      };
    case userConstants.GETALL_USER_ACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GETALL_USER_DEACTIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_DEACTIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getUserDeactive.list,
        total: action.users.getUserDeactive.total
      };
    case userConstants.GETALL_USER_DEACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // items: action.users.disableUser.list,
        // total: action.users.disableUser.total
      };
    case userConstants.DISABLE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // items: action.users.deleteUser.list,
        // total: action.users.deleteUser.total
      };
    case userConstants.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.USER_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // items: action.users.verifyEmail.list,
        // total: action.users.verifyEmail.total
      };
    case userConstants.USER_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.UPDATE_USERS_PASSWORD_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_USERS_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_USERS_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };





    case userConstants.ADD_USER_REQUEST:
      return {
        ...state
      };
    case userConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.ADD_USER_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.LOGIN_TO_THIS_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_TO_THIS_ACCOUNT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        loginToThisAccountItems: action.users.loginToThisAccount
        // loginToThisAccountTotal: action.users.loginToThisAccount.total
      };
    case userConstants.LOGIN_TO_THIS_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_PARKING_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_PARKING_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userParkingItems: action.users.getUserParkingList,
        userParkingTotal: action.users.getUserParkingList.total
      };
    case userConstants.GET_TX_LIST_BY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_TX_LIST_BY_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_TX_LIST_BY_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userTxItems: action.users.getTxListByUser,
        userTxTotal: action.users.getTxListByUser.total
      };
    case userConstants.GET_USER_PARKING_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ORDER_LIST_BY_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ORDER_LIST_BY_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userOrderItems: action.users.getOrderListByUser,
        userOrderTotal: action.users.getOrderListByUser.total
      };
    case userConstants.GET_ORDER_LIST_BY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_LOGIN_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_LOGIN_HISTORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        loginHistory: action.users.getLoginHistoryListByUserId,
        loginHistoryTotal: action.users.getLoginHistoryListByUserId.total
      };
    case userConstants.GET_LOGIN_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_MATCH_TEAM_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_MATCH_TEAM_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        // addUserSuccess: true,
        isMatchImageUpdate: true,
        updateMatchTeamImageItems: action.users.updateMatchTeamImage
      };
    case userConstants.UPDATE_MATCH_TEAM_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_EMS_SLOT_BOOKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_EMS_SLOT_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        isMatchImageUpdate: false,
        emsSlotBookData: action.users.getEmsSlotBookingByUser,
        total: action.users.getEmsSlotBookingByUser
      };
    case userConstants.GET_EMS_SLOT_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_MASSAGE_SLOT_BOOKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_MASSAGE_SLOT_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        isMatchImageUpdate: false,
        massageSlotBookData: action.users.getMassageSlotBookingByUserId,
        total: action.users.getMassageSlotBookingByUserId
      };
    case userConstants.GET_MASSAGE_SLOT_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_GROUP_SLOT_BOOKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_GROUP_SLOT_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        isMatchImageUpdate: false,
        classesSlotBookData: action.users.getGroupClassSlotBookingByUserId,
        total: action.users.getGroupClassSlotBookingByUserId
      };
    case userConstants.GET_GROUP_SLOT_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_TRAINER_SLOT_BOOKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_TRAINER_SLOT_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        isMatchImageUpdate: false,
        TrainerSlotBookData: action.users.getTrainerSlotBookingByUserId
      };
    case userConstants.GET_TRAINER_SLOT_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.users.getProfile
      };
    case userConstants.GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_DEACTIVE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_DEACTIVE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        // getUserListIsDisable: action.users.getUserListIsDisable.list,
        // totalUser: action.users.getUserListIsDisable.total
      };
    case userConstants.GET_DEACTIVE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_MATCH_MARKET_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_MATCH_MARKET_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: true,
        updateMatchMarketIdItems: action.users.getProfile
      };
    case userConstants.UPDATE_MATCH_MARKET_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.FILE_UPLOAD_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        imageUploadSuccess: true,
        filesDetails: action.uploadImage.filesDetails
      };

    case userConstants.FILE_UPLOAD_CLEAR_SUCCESS:
      return {
        ...state,
        imageUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case userConstants.FILE_UPLOAD_STATUS_VIDEO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.FILE_UPLOAD_STATUS_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        imageUploadSuccess: true,
        filesDetailsVideo: action.uploadImage.videoDetails
      };
    case userConstants.FILE_UPLOAD_STATUS_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
      };

  

    default:
      return state
  }
}