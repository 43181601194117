export const emsSlotConstants = {

    GET_ID_EMS_SLOT_REQUEST: 'GET_ID_EMS_SLOT_REQUEST',
    GET_ID_EMS_SLOT_SUCCESS: 'GET_ID_EMS_SLOT_SUCCESS',
    GET_ID_EMS_SLOT_FAILURE: 'GET_ID_EMS_SLOT_FAILURE',

    GETALL_MASSAGE_REQUEST: 'GETALL_MASSAGE_REQUEST',
    GETALL_MASSAGE_SUCCESS: 'GETALL_MASSAGE_SUCCESS',
    GETALL_MASSAGE_FAILURE: 'GETALL_MASSAGE_FAILURE',

    GET_LIST_EMS_SLOT_REQUEST: 'GET_LIST_EMS_SLOT_REQUEST',
    GET_LIST_EMS_SLOT_SUCCESS: 'GET_LIST_EMS_SLOT_SUCCESS',
    GET_LIST_EMS_SLOT_FAILURE: 'GET_LIST_EMS_SLOT_FAILURE',

    GET_LIST_EMS_BOOK_REQUEST: 'GET_LIST_EMS_BOOK_REQUEST',
    GET_LIST_EMS_BOOK_SUCCESS: 'GET_LIST_EMS_BOOK_SUCCESS',
    GET_LIST_EMS_BOOK_FAILURE: 'GET_LIST_EMS_BOOK_FAILURE',


    ADD_EMS_SLOT_REQUEST: 'ADD_EMS_SLOT_REQUEST',
    ADD_EMS_SLOT_SUCCESS: 'ADD_EMS_SLOT_SUCCESS',
    ADD_EMS_SLOT_FAILURE: 'ADD_EMS_SLOT_FAILURE',

    DELETE_EMS_SLOT_REQUEST: 'DELETE_EMS_SLOT_REQUEST',
    DELETE_EMS_SLOT_SUCCESS: 'DELETE_EMS_SLOT_SUCCESS',
    DELETE_EMS_SLOT_FAILURE: 'DELETE_EMS_SLOT_FAILURE',

    UPDATE_EMS_SLOT_REQUEST: 'UPDATE_EMS_SLOT_REQUEST',
    UPDATE_EMS_SLOT_SUCCESS: 'UPDATE_EMS_SLOT_SUCCESS',
    UPDATE_EMS_SLOT_FAILURE: 'UPDATE_EMS_SLOT_FAILURE',

    DISABLE_EMS_SLOT_REQUEST: 'DISABLE_EMS_SLOT_REQUEST',
    DISABLE_EMS_SLOT_SUCCESS: 'DISABLE_EMS_SLOT_SUCCESS',
    DISABLE_EMS_SLOT_FAILURE: 'DISABLE_EMS_SLOT_FAILURE',

    DISABLE_EMS_BOOK_REQUEST: 'DISABLE_EMS_BOOK_REQUEST',
    DISABLE_EMS_BOOK_SUCCESS: 'DISABLE_EMS_BOOK_SUCCESS',
    DISABLE_EMS_BOOK_FAILURE: 'DISABLE_EMS_BOOK_FAILURE',

};
