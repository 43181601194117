import {

  classesConstants,
} from '../_constants';

export function classes(state = {}, action) {
  // console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case classesConstants.GET_ID_CLASSES_REQUEST:
      return {
        ...state
      };
    case classesConstants.GET_ID_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allems: action.users.getEmsUserById,
      };
    case classesConstants.GET_ID_CLASSES_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case classesConstants.ADD_CLASSES_REQUEST:
      return {
        ...state
      };
    case classesConstants.ADD_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case classesConstants.ADD_CLASSES_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case classesConstants.GET_LIST_CLASSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case classesConstants.GET_LIST_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getClassesList.list,
        total: action.users.getClassesList.total
      };
    case classesConstants.GET_LIST_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case classesConstants.GET_LIST_CLASSES_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case classesConstants.GET_LIST_CLASSES_BOOK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getShowClassesBookSlotList,
        calenderDay: action.users.getShowClassesBookSlotList.day,
        // currentDate: action.users.getShowClassesBookSlotList.currentDate,
        // total: action.users.getShowClassesBookSlotList.data.total
      };
    case classesConstants.GET_LIST_CLASSES_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case classesConstants.GET_CLASSES_BOOK_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case classesConstants.GET_CLASSES_BOOK_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        itams: action.users.onlyBookingClassSlotAdmin.list,
        totalslot: action.users.onlyBookingClassSlotAdmin.total,
        bookSlot: action.users.onlyBookingClassSlotAdmin.bookSlot,
        unBookSlot: action.users.onlyBookingClassSlotAdmin.data
      };
    case classesConstants.GET_CLASSES_BOOK_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case classesConstants.GETALL_CLASSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case classesConstants.GETALL_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allrestaurent: action.users.getRestaurantUserById,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case classesConstants.GETALL_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case classesConstants.UPDATE_CLASSES_REQUEST:
      return {
        ...state
      };
    case classesConstants.UPDATE_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case classesConstants.UPDATE_CLASSES_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case classesConstants.DELETE_CLASSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case classesConstants.DELETE_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case classesConstants.DELETE_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case classesConstants.DISABLE_CLASSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case classesConstants.DISABLE_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case classesConstants.DISABLE_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case classesConstants.DISABLE_CLASSES_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case classesConstants.DISABLE_CLASSES_BOOK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case classesConstants.DISABLE_CLASSES_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}