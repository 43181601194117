export const bookingListConstants = {

    GET_ID_BOOKINGLIST_REQUEST: 'GET_ID_BOOKINGLIST_REQUEST',
    GET_ID_BOOKINGLIST_SUCCESS: 'GET_ID_BOOKINGLIST_SUCCESS',
    GET_ID_BOOKINGLIST_FAILURE: 'GET_ID_BOOKINGLIST_FAILURE',

    GETALL_BOOKINGLIST_REQUEST: 'GETALL_BOOKINGLIST_REQUEST',
    GETALL_BOOKINGLIST_SUCCESS: 'GETALL_BOOKINGLIST_SUCCESS',
    GETALL_BOOKINGLIST_FAILURE: 'GETALL_BOOKINGLIST_FAILURE',

    GET_LIST_BOOKINGLIST_REQUEST: 'GET_LIST_BOOKINGLIST_REQUEST',
    GET_LIST_BOOKINGLIST_SUCCESS: 'GET_LIST_BOOKINGLIST_SUCCESS',
    GET_LIST_BOOKINGLIST_FAILURE: 'GET_LIST_BOOKINGLIST_FAILURE',

    UN_BOOK_REQUEST: 'UN_BOOK_REQUEST',
    UN_BOOK_SUCCESS: 'UN_BOOK_SUCCESS',
    UN_BOOK_FAILURE: 'UN_BOOK_FAILURE',

    UPDATE_ALL_STATUS_UN_BOOK_REQUEST: 'UPDATE_ALL_STATUS_UN_BOOK_REQUEST',
    UPDATE_ALL_STATUS_UN_BOOK_SUCCESS: 'UPDATE_ALL_STATUS_UN_BOOK_SUCCESS',
    UPDATE_ALL_STATUS_UN_BOOK_FAILURE: 'UPDATE_ALL_STATUS_UN_BOOK_FAILURE',

    GET_LIST_PARKING_REQUEST: 'GET_LIST_PARKING_REQUEST',
    GET_LIST_PARKING_SUCCESS: 'GET_LIST_PARKING_SUCCESS',
    GET_LIST_PARKING_FAILURE: 'GET_LIST_PARKING_FAILURE',


    ADD_BOOKINGLIST_REQUEST: 'ADD_BOOKINGLIST_REQUEST',
    ADD_BOOKINGLIST_SUCCESS: 'ADD_BOOKINGLIST_SUCCESS',
    ADD_BOOKINGLIST_FAILURE: 'ADD_BOOKINGLIST_FAILURE',

    DELETE_BOOKINGLIST_REQUEST: 'DELETE_BOOKINGLIST_REQUEST',
    DELETE_BOOKINGLIST_SUCCESS: 'DELETE_BOOKINGLIST_SUCCESS',
    DELETE_BOOKINGLIST_FAILURE: 'DELETE_BOOKINGLIST_FAILURE',

    UPDATE_BOOKINGLIST_REQUEST: 'UPDATE_BOOKINGLIST_REQUEST',
    UPDATE_BOOKINGLIST_SUCCESS: 'UPDATE_BOOKINGLIST_SUCCESS',
    UPDATE_BOOKINGLIST_FAILURE: 'UPDATE_BOOKINGLIST_FAILURE',

    UPDATE_BOOKINGLIST_PASSWORD_REQUEST: 'UPDATE_BOOKINGLIST_PASSWORD_REQUEST',
    UPDATE_BOOKINGLIST_PASSWORD_SUCCESS: 'UPDATE_BOOKINGLIST_PASSWORD_SUCCESS',
    UPDATE_BOOKINGLIST_PASSWORD_FAILURE: 'UPDATE_BOOKINGLIST_PASSWORD_FAILURE',

    DISABLE_BOOKINGLIST_REQUEST: 'DISABLE_BOOKINGLIST_REQUEST',
    DISABLE_BOOKINGLIST_SUCCESS: 'DISABLE_BOOKINGLIST_SUCCESS',
    DISABLE_BOOKINGLIST_FAILURE: 'DISABLE_BOOKINGLIST_FAILURE',

};
