import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from 'react-toastify';
export const userActions = {
    getAllSport,
    getAllSeries,
    getAllMatch,
    createSport,
    createSeries,
    createMatch,
    getProfile,
    login,
    logout,
    changePassword,
    registerUserAdmin,
    getUserActive,
    getUserDeactive,
    disableUser,
    deleteUser,
    verifyEmail,
    loginToThisAccount,
    getEmsSlotBookingByUser,
    getMassageSlotBookingByUserId,
    getGroupClassSlotBookingByUserId,
    getTrainerSlotBookingByUserId,
    getUserProfile,
    getAllMatchActive,
    updateMatchStatus,
    uploadImage,
    updateMatchMarketId,
    getOdds,
    getMatchBymatchId,
    updateMatchTeamImage,
    AddUserBalance,
    deductUserBalance,
    uploadVideo,
    getUserParkingList,
    getTxListByUser,
    getOrderListByUser,
    getLoginHistoryListByUserId,
    getTicketList,
    disableTicket,
    deleteTicket,
    approveTicket,
    getUserList,
    updateUser,
    updateUserpassword,
    uploadImageClear,
    getUserListIsDisable,
    updateUserStatusAdmin
};

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}


function updateUser(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.updateUser(data)
            .then(
                users => {
                    dispatch(alertActions.success("User Updated Successfully."));
                    dispatch(success(users))
                    dispatch(this.getUserList(tempData))
                    dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_FAILURE, error } }
}

function getUserList(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserList(data)
            .then(
                users => {
                    dispatch(success(users))
                    // dispatch(success(uploadImage));
                    dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_LIST_FAILURE, error } }
} console.log();


function getTicketList(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.getTicketList(data)
            .then(
                users => {
                    dispatch(success(users))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_TICKET_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_TICKET_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_TICKET_LIST_FAILURE, error } }
}
function deleteTicket(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.deleteTicket(data)
            .then(
                users => {
                    dispatch(success(users))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DELETE_TICKET_REQUEST } }
    function success(users) { return { type: userConstants.DELETE_TICKET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DELETE_TICKET_FAILURE, error } }
}
function disableTicket(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.disableTicket(data)
            .then(
                users => {
                    dispatch(success(users))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DISABLE_TICKET_REQUEST } }
    function success(users) { return { type: userConstants.DISABLE_TICKET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DISABLE_TICKET_FAILURE, error } }
}
function updateUserStatusAdmin(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.updateUserStatusAdmin(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getUserList(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_STATUS_ADMIN_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_STATUS_ADMIN_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_STATUS_ADMIN_FAILURE, error } }
}
function approveTicket(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.approveTicket(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getTicketList(tempData))

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.APPROVE_TICKET_REQUEST } }
    function success(users) { return { type: userConstants.APPROVE_TICKET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.APPROVE_TICKET_FAILURE, error } }
}

// function createMarket(data, tempData) {
//     return dispatch => {
//         dispatch(request());
//         userService.deductUserBalance(data)
//             .then(
//                 users => {
//                     dispatch(success(users))
//                     dispatch(alertActions.success("Deduct your balance Successfully."));
//                     // dispatch(this.getAllMarket(data));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.DEDUCT_BALANCE_REQUEST } }
//     function success(users) { return { type: userConstants.DEDUCT_BALANCE_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.DEDUCT_BALANCE_FAILURE, error } }
// }
function AddUserBalance(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.AddUserBalance(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(alertActions.success("Add your balance Successfully."));
                    // dispatch(this.getUserList(tempData));
                    dispatch(this.getUserList(tempData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_BALANCE_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_BALANCE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_BALANCE_FAILURE, error } }
}
function deductUserBalance(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.deductUserBalance(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(alertActions.success("Deduct User Balance Successfully."));
                    dispatch(this.getUserList(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DEDUCT_BALANCE_REQUEST } }
    function success(users) { return { type: userConstants.DEDUCT_BALANCE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DEDUCT_BALANCE_FAILURE, error } }
}

function createMatch(data, tempData) {
    console.log("tempData:::", tempData);
    return dispatch => {
        dispatch(request());
        userService.createMatch(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getAllMatch(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_MATCH_REQUEST } }
    function success(users) { return { type: userConstants.ADD_MATCH_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_MATCH_FAILURE, error } }
}
function updateMatchMarketId(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.updateMatchMarketId(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllMatch(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_MATCH_MARKET_ID_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_MATCH_MARKET_ID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_MATCH_MARKET_ID_FAILURE, error } }
}

function uploadImage(data) {
    return dispatch => {
        dispatch(request());
        userService.uploadImage(data)
            .then(
                uploadImage => {
                    toast("Image Uploaded successfully.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.FILE_UPLOAD_STATUS_REQUEST } }
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }

}

function uploadVideo(data) {
    return dispatch => {
        dispatch(request());
        userService.uploadVideo(data)
            .then(
                uploadImage => {
                    toast("Video Uploaded successfully.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.FILE_UPLOAD_STATUS_VIDEO_REQUEST } }
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_VIDEO_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_VIDEO_FAILURE, error } }

}

function createSeries(data) {
    return dispatch => {
        dispatch(request());
        userService.createSeries(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getAllSeries(data.sportId));
                },
                error => {
                    dispatch(alertActions.error(error));

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_SERIES_REQUEST } }
    function success(users) { return { type: userConstants.ADD_SERIES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_SERIES_FAILURE, error } }
}
function updateMatchStatus(data) {
    console.log("statusmatch::", data);
    return dispatch => {
        dispatch(request());
        userService.updateMatchStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllMatchActive());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_MATCH_STATUS_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_MATCH_STATUS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_MATCH_STATUS_FAILURE, error } }
}


function updateUserpassword(data) {
    console.log("statusmatch::", data);
    return dispatch => {
        dispatch(request());
        userService.updateUserpassword(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserList());
                    dispatch(alertActions.success('Password update successfully'))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USERS_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USERS_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USERS_PASSWORD_FAILURE, error } }
}

function createSport(data) {
    return dispatch => {
        dispatch(request());
        userService.createSport(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_SPORT_REQUEST } }
    function success(users) { return { type: userConstants.ADD_SPORT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_SPORT_FAILURE, error } }
}

function getUserProfile(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserProfile(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_PROFILE_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_PROFILE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_PROFILE_FAILURE, error } }
}

function getTxListByUser(data) {
    return dispatch => {
        dispatch(request());
        userService.getTxListByUser(data)
            .then(
                users =>
                    // console.log('users_____________11111111__', users),
                    dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_TX_LIST_BY_USER_REQUEST } }
    function success(users) { return { type: userConstants.GET_TX_LIST_BY_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_TX_LIST_BY_USER_FAILURE, error } }
}
function getUserParkingList(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserParkingList(data)
            .then(
                users =>
                    // console.log('users_____________11111111__', users),
                    dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_PARKING_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_PARKING_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_PARKING_LIST_FAILURE, error } }
}
function getOrderListByUser(data) {
    return dispatch => {
        dispatch(request());
        userService.getOrderListByUser(data)
            .then(
                users =>
                    // console.log('users_____________11111111__', users),
                    dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ORDER_LIST_BY_USER_REQUEST } }
    function success(users) { return { type: userConstants.GET_ORDER_LIST_BY_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ORDER_LIST_BY_USER_FAILURE, error } }
}
function getLoginHistoryListByUserId(data) {
    return dispatch => {
        dispatch(request());
        userService.getLoginHistoryListByUserId(data)
            .then(
                users =>
                    // console.log('users_____________11111111__', users),
                    dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_LOGIN_HISTORY_REQUEST } }
    function success(users) { return { type: userConstants.GET_LOGIN_HISTORY_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_LOGIN_HISTORY_FAILURE, error } }
}

function getEmsSlotBookingByUser(data) {
    return dispatch => {
        dispatch(request());
        userService.getEmsSlotBookingByUser(data)
            .then(
                users =>
                    // console.log('users_____________11111111__', users),
                    dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_EMS_SLOT_BOOKING_REQUEST } }
    function success(users) { return { type: userConstants.GET_EMS_SLOT_BOOKING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_EMS_SLOT_BOOKING_FAILURE, error } }
}

function getMassageSlotBookingByUserId(data) {
    return dispatch => {
        dispatch(request());
        userService.getMassageSlotBookingByUserId(data)
            .then(
                users =>
                    // console.log('users_____________11111111__', users),
                    dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_MASSAGE_SLOT_BOOKING_REQUEST } }
    function success(users) { return { type: userConstants.GET_MASSAGE_SLOT_BOOKING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_MASSAGE_SLOT_BOOKING_FAILURE, error } }
}

function getGroupClassSlotBookingByUserId(data) {
    return dispatch => {
        dispatch(request());
        userService.getGroupClassSlotBookingByUserId(data)
            .then(
                users =>
                    // console.log('users_____________11111111__', users),
                    dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_GROUP_SLOT_BOOKING_REQUEST } }
    function success(users) { return { type: userConstants.GET_GROUP_SLOT_BOOKING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_GROUP_SLOT_BOOKING_FAILURE, error } }
}

function getTrainerSlotBookingByUserId(data) {
    return dispatch => {
        dispatch(request());
        userService.getTrainerSlotBookingByUserId(data)
            .then(
                users =>
                    // console.log('users_____________11111111__', users),
                    dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_TRAINER_SLOT_BOOKING_REQUEST } }
    function success(users) { return { type: userConstants.GET_TRAINER_SLOT_BOOKING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_TRAINER_SLOT_BOOKING_FAILURE, error } }
}
function updateMatchTeamImage(data) {
    return dispatch => {
        dispatch(request());
        userService.updateMatchTeamImage(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllMatchActive());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_MATCH_TEAM_IMAGE_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_MATCH_TEAM_IMAGE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_MATCH_TEAM_IMAGE_FAILURE, error } }
}
function getAllMatchActive(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllMatchActive(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_MATCH_ACTIVE_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_MATCH_ACTIVE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_MATCH_ACTIVE_FAILURE, error } }
}
function getAllMatch(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllMatch(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_MATCH_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_MATCH_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_MATCH_FAILURE, error } }
}

function getAllSeries(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllSeries(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_SERIES_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SERIES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_SERIES_FAILURE, error } }
}
function getOdds(data) {
    return dispatch => {
        // dispatch(request());
        userService.getOdds(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    // function request() { return { type: userConstants.GET_ODDS_REQUEST } }
    function success(users) { return { type: userConstants.GET_ODDS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ODDS_FAILURE, error } }
}
function getMatchBymatchId(data) {
    return dispatch => {
        dispatch(request());
        userService.getMatchBymatchId(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_MATCH_BY_MATCHID_REQUEST } }
    function success(users) { return { type: userConstants.GET_MATCH_BY_MATCHID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_MATCH_BY_MATCHID_FAILURE, error } }
}

function getAllSport(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllSport(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_SPORT_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SPORT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_SPORT_FAILURE, error } }
}

function login(data, props) {

    console.log("dataACTION:::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", data);

    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}
function verifyEmail(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.verifyEmail(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserActive(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_VERIFY_EMAIL_REQUEST } }
    function success(users) { return { type: userConstants.USER_VERIFY_EMAIL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_VERIFY_EMAIL_FAILURE, error } }
}
function deleteUser(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.deleteUser(data)
            .then(
                users => {
                    dispatch(alertActions.success("User Deleted Successfully."));
                    dispatch(success(users));
                    dispatch(this.getUserList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DELETE_USER_REQUEST } }
    function success(users) { return { type: userConstants.DELETE_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DELETE_USER_FAILURE, error } }
}
function disableUser(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.disableUser(data)
            .then(
                users => {
                    dispatch(success(users));
                    // dispatch(alertActions.success("User Disable Successfully."));
                    // dispatch(this.getUserList(paginationData));
                    dispatch(this.getUserListIsDisable(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DISABLE_USER_REQUEST } }
    function success(users) { return { type: userConstants.DISABLE_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DISABLE_USER_FAILURE, error } }
}
function getUserActive(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserActive(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_USER_ACTIVE_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_USER_ACTIVE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_USER_ACTIVE_FAILURE, error } }
}
function getUserDeactive(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserDeactive(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_USER_DEACTIVE_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_USER_DEACTIVE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_USER_DEACTIVE_FAILURE, error } }
}

function registerUserAdmin(data, props) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        userService.registerUserAdmin(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("User Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getUserList(reqData));
                    dispatch(this.uploadImageClear());
                    // props.history.push("/app/user")
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_USER_FAILURE, error } }
}
function changePassword(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        userService.changePassword(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Password Updated successfully."));
                    dispatch(success(users));
                    dispatch(this.getUserActive(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_PASSWORD_FAILURE, error } }
}

function loginToThisAccount(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.loginToThisAccount(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$ loginToThisAccount $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.LOGIN_TO_THIS_ACCOUNT_REQUEST } }
    function success(users) { return { type: userConstants.LOGIN_TO_THIS_ACCOUNT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.LOGIN_TO_THIS_ACCOUNT_FAILURE, error } }
}

function getProfile(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getProfile(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getProfile $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_PROFILE_REQUEST } }
    function success(users) { return { type: userConstants.GET_PROFILE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_PROFILE_FAILURE, error } }
}
function getUserListIsDisable(data, reqData) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserListIsDisable(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getProfile $$$$ action:", users);
                    dispatch(success(users));
                    dispatch(this.getUserList(reqData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_DEACTIVE_USER_REQUEST } }
    function success(users) { return { type: userConstants.GET_DEACTIVE_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_DEACTIVE_USER_FAILURE, error } }
}

