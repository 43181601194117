import React from "react";
import { CgLogIn } from 'react-icons/cg';
import Modal from 'react-modal';



export default function CreateAddCategoryModal(props) {

  let { addUserCreateModal, handleAddUserHideModal, errorsMassage, inputAddUserChange, createMassageCategorySubmit, fieldsMassage, handleFile,
    // inputChangeIsVideo, isVideo,
    allCategoryParent, filesDetails
    //  handleFile2

  } = props;
  // console.log("isVideo_modal::", isVideo);

  return (

    <div className={addUserCreateModal ? "fixed w-full top-0 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
    <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto mt-2">
      {/*modal header*/}
      <div className="flex items-center justify-between p-6 py-2 bg-blue-500 border-b">
        <p className="text-[16px] font-medium text-white uppercase">Add Massage Trainer</p>
        <div className="rounded-full cursor-pointer modal-close ">
          <svg onClick={() => handleAddUserHideModal()} className="fill-current text-white/60 hover:text-white/100 " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
            </path>
          </svg>
        </div>
      </div>

      <div className="p-4">
        <button className="text-sm text-gray-600 font-medium p-4 py-2 border border-gray-300 border-b-transparent bg-white -mb-0.5">
          Create User
        </button>
        <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">

          <div className="w-full">
            <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Trainer Name :</label>
            <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsMassage && !errorsMassage["name"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="name" name="name" placeholder="Name" value={fieldsMassage.name} type="text" onChange={inputAddUserChange} />
                    {errorsMassage && errorsMassage["name"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsMassage["name"]}
                      </div>
                      : null}
          </div>

          <div className="w-full">
            <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Trainer Email :</label>
            <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500   ${errorsMassage && !errorsMassage["name"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="email" name="email" placeholder="Email" value={fieldsMassage.email} type="text" onChange={inputAddUserChange} />
                    {errorsMassage && errorsMassage["email"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsMassage["email"]}
                      </div>
                      : null}
          </div>

          <div className="w-full">
            <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Trainer Mobile No:</label>
            <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsMassage && !errorsMassage["mobile"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="mobile" name="mobile" placeholder="mobile" value={fieldsMassage.mobile} type="text" onChange={inputAddUserChange} />
                    {errorsMassage && errorsMassage["mobile"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsMassage["mobile"]}
                      </div>
                      : null}
          </div>

          <div className="w-full">
            <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Password :</label>
            <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsMassage && !errorsMassage["password"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="password" name="password" placeholder="password" value={fieldsMassage.password} type="text" onChange={inputAddUserChange} />
                    {errorsMassage && errorsMassage["password"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsMassage["password"]}
                      </div>
                      : null}
          </div>

          <div className="flex justify-between px-2"   >
            <div className="flex flex-wrap w-full py-2">
              <label htmlFor="image">
                Upload Image of Trainer
              </label>
              <input id="image" name="image" type="file" onChange={handleFile} />
              <div className="flex space-x-4 mt-1">

              </div>
            </div>


            {
              filesDetails && filesDetails.imageURL ?
                <>
                  <div>
                    <div className='flex justify-center'>
                      <img className="object-cover h-10 rounded-sm w-14" src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : null} alt="not found" />
                    </div>
                  </div>
                </> :
                <>
                  <div>
                    <div className='flex justify-center'>
                      <img className="object-cover h-10 rounded-sm w-14" src="/dist/img/profile.png" alt="not found" />
                    </div>
                  </div>
                </>
            }





          </div>

          {/* btns */}
          <div className="flex justify-end py-0 space-x-4">
            <button className="bg-blue-500  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
              onClick={createMassageCategorySubmit}
            >Submit
              <CgLogIn size={18} className="ml-2" />
            </button>
          </div>

        </form>
      </div>
      {/*Footer*/}
    </div>
  </div>




  );
}
