import { userConstants, groupClassCategoryConstants } from '../_constants';
import { userService, groupClassCategoryService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const groupClassCategoryActions = {

    login,
    logout,
    disableGroupClassCategory,
    getAllGroupClassCategory,
    createGroupClassCategoryUser,
    updateGroupClassCategoryUser,
    getGroupClassCategoryUserList,
    deleteRestoCat,
    uploadImageClear,
    getGroupClassCategoryUserById
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createGroupClassCategoryUser(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        groupClassCategoryService.createGroupClassCategoryUser(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Group Class Category Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getGroupClassCategoryUserList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: groupClassCategoryConstants.ADD_CLASS_CATEGORY_REQUEST } }
    function success(users) { return { type: groupClassCategoryConstants.ADD_CLASS_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: groupClassCategoryConstants.ADD_CLASS_CATEGORY_FAILURE, error } }
}
function updateGroupClassCategoryUser(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        groupClassCategoryService.updateGroupClassCategoryUser(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Group Class Category Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getGroupClassCategoryUserList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: groupClassCategoryConstants.UPDATE_CLASS_CATEGORY_REQUEST } }
    function success(users) { return { type: groupClassCategoryConstants.UPDATE_CLASS_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: groupClassCategoryConstants.UPDATE_CLASS_CATEGORY_FAILURE, error } }
}
function getAllGroupClassCategory() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        groupClassCategoryService.getAllGroupClassCategory()
            .then(
                users => {
                    console.log("getAllGroupClassCategory $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: groupClassCategoryConstants.GETALL_CLASS_CATEGORY_REQUEST } }
    function success(users) { return { type: groupClassCategoryConstants.GETALL_CLASS_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: groupClassCategoryConstants.GETALL_CLASS_CATEGORY_FAILURE, error } }
}
function getGroupClassCategoryUserById() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        groupClassCategoryService.getGroupClassCategoryUserById()
            .then(
                users => {
                    console.log("getGroupClassCategoryUserById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: groupClassCategoryConstants.GET_ID_CLASS_CATEGORY_REQUEST } }
    function success(users) { return { type: groupClassCategoryConstants.GET_ID_CLASS_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: groupClassCategoryConstants.GET_ID_CLASS_CATEGORY_FAILURE, error } }
}
function getGroupClassCategoryUserList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        groupClassCategoryService.getGroupClassCategoryUserList(data)
            .then(
                users => {
                    console.log("getGroupClassCategoryUserList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: groupClassCategoryConstants.GET_LIST_CLASS_CATEGORY_REQUEST } }
    function success(users) { return { type: groupClassCategoryConstants.GET_LIST_CLASS_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: groupClassCategoryConstants.GET_LIST_CLASS_CATEGORY_FAILURE, error } }
}


function deleteRestoCat(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        groupClassCategoryService.deleteRestoCat(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getGroupClassCategoryUserList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: groupClassCategoryConstants.DELETE_CLASS_CATEGORY_REQUEST } }
    function success(users) { return { type: groupClassCategoryConstants.DELETE_CLASS_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: groupClassCategoryConstants.DELETE_CLASS_CATEGORY_FAILURE, error } }
}
function disableGroupClassCategory(data, paginationData) {

    return dispatch => {
        dispatch(request());
        groupClassCategoryService.disableGroupClassCategory(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getGroupClassCategoryUserList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: groupClassCategoryConstants.DISABLE_CLASS_CATEGORY_REQUEST } }
    function success(users) { return { type: groupClassCategoryConstants.DISABLE_CLASS_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: groupClassCategoryConstants.DISABLE_CLASS_CATEGORY_FAILURE, error } }
}
