import React, { Component } from 'react';
import { connect } from 'react-redux';
import { massageSlotActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BiSearchAlt } from "react-icons/bi";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";

class massageBookList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: 0,
      fieldsCalender: '',
      nextData: 0,
      offset: 0,
    }
  }

  componentDidMount() {
    let temp = {
      currentDate: 0,
      nextDate: 0,
    }
    this.props.dispatch(massageSlotActions.getShowMassageBookSlotList(temp))

  }
  static getDerivedStateFromProps(nextProps, prevState) {

    // console.log("STATIC______nextProps.massageSlot.addUserSuccess", nextProps.massageSlot.addUserSuccess);

    if (nextProps) {
      return {
        ...nextProps,

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  disableUser = (element) => {
    let datatemp = {
      "id": element.id,
    }
    let paginationdata = {
      // "keyWord": "",
      // "pageNo": 1,
      // "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status of booked slot?',
      message: `Are you sure cancel booked slot ${element.slotId.slotNo}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(massageSlotActions.rejectBookMassage(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleChangeDateCelander = () => {
    // console.log("data_________________data1111111111111", data);
    // console.log("data_________2222222222222222222", data);
    // let { classes } = this.props
    // let { currentDate } = classes

    let temp = {
      "calenderData": this.state.fieldsCalender,
    }
    this.props.dispatch(massageSlotActions.getShowMassageBookSlotList(temp))

  }
  handleChangeDate = (data) => {
    console.log("data_________________data", data);
    let { massageSlot } = this.props
    let { currentDate } = massageSlot

    let temp = {
      currentDate: currentDate,
      nextDate: data
    }
    this.props.dispatch(massageSlotActions.getShowMassageBookSlotList(temp))

  }


  inputCalenderChange = (e) => {
    // e.preventDefault();
    console.log('1111111111111111111111111111111', e.target.name, e.target.value);

    this.setState({ fieldsCalender: e.target.value });

    let temp = {
      "calenderData": this.state.fieldsCalender,
    }
    this.props.dispatch(massageSlotActions.getShowMassageBookSlotList(temp))
  }


  inputMassageData = () => {

    let temp = {
      "calenderData": this.state.fieldsCalender,
    }
    console.log("temp_______________temp", temp);
    this.props.dispatch(massageSlotActions.getShowMassageBookSlotList(temp))
  }


  render() {

    let { massageSlot } = this.props
    let { massageBookSlot, currentDate,loading } = massageSlot
    console.log("massageBookSlot________massageBookSlot", massageBookSlot);
    console.log("currentDate________currentDate", currentDate);


    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-x-auto overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="p-6 space-y-6 bg-white border rounded-md">
                  <div className="px-2 ">
                    <h3 className="text-5xl font-semibold ">Massage Book List</h3>
                  </div>
                  <div className='flex justify-between '>
                    <div>
                    <div className="w-full cursor-pointer ">
                       <label class="block text-gray-700 text-sm" for="username"> Date</label>
                       <div className='flex'>
                        <input className={`px-3 py-2 placeholder-gray-400 text-gray-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring-gray-600 border-gray-600 w-full ease-linear  transition-all duration-150 ${this.state.errorsCalender && !this.state.errorsCalender["resultDate"] ? "placeholder-gray-500" : "border border-opacity-100 "}`}
                          id="resultDate" name="resultDate" placeholder="Select Date" value={this.state.fieldsCalender.date} type="date" onChange={this.inputCalenderChange} />
                            <button className='h-10 text-white bg-gray-600 border border-black hover:bg-gray-100 item-center' onClick={() => this.inputMassageData()}><BiSearchAlt  className='w-10 cursor-pointer h-7 hover:text-gray-500' /></button>
                        {this.state.errorsCalender && this.state.errorsCalender["resultDate"] ?
                          <div className="text-yellow-600 invalid-feedback">
                            {this.state.errorsCalender["resultDate"]}
                          </div>
                          : null}
                          </div>
                        
                      </div>
                    </div>
                    <p className='px-16 py-4 text-xl font-bold text-gray-700 bg-gray-300 border rounded-lg'>Date :-  {moment(new Date(parseInt(currentDate))).utcOffset("+05:30").format("DD/MM/YYYY")}</p>
                    <div className='flex justify-between px-10 space-x-8'>
                      <button onClick={() => this.handleChangeDate(0)}><BsFillArrowLeftCircleFill className='w-10 h-10 cursor-pointer hover:text-gray-500' /></button>
                      <button onClick={() => this.handleChangeDate(1)}><BsFillArrowRightCircleFill className='w-10 h-10 cursor-pointer hover:text-gray-500' /></button>
                    </div>
                  </div>



                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Slot No</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Price</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Starting Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Ending Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>

                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Status </th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {
                                 massageBookSlot && massageBookSlot.massageBookingSlotData.length > 0 ?
                                 massageBookSlot.massageBookingSlotData.map((element, index) =>
                                   (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                        <td className="px-6 py-3 text-sm text-gray-800 font-medium whitespace-nowrap capitalize" title={element.name} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element && element.slotNo ? element.slotNo : "_"}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.price ? element.price : "_"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap uppercase">     {element && element.startingTime ? element.startingTime : "_"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap uppercase"> {element && element.endingTime ? element.endingTime : "_"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isDisable === true ?
                                            <button class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUser(element)}>

                                              <label>Reject</label>
                                            </button>
                                            : <label class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-md shadow-sm " disabled >

                                              <label>Rejected</label>
                                            </label>}
                                        </span>
                                      </td>


                                      {/* <td className="flex justify-center px-2 py-3 space-x-2 text-center text-gray-600 whitespace-nowrap">
                                        <div className='flex space-x-2'>


                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>Edit</span>

                                              <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.deleteCategory(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-2 text-sm text-white bg-gray-500 rounded tooltip-text'>Delete</span>
                                              <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>
                                        </div>
                                      </td> */}


                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    } */}
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>


      </>

    );
  }
}
function mapStateToProps(state) {
  const { massageSlot } = state;
  return {
    massageSlot
  };
}
export default connect(mapStateToProps)(massageBookList);
