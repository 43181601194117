import {

  emsSlotConstants,
} from '../_constants';

export function emsSlot(state = {}, action) {
  // console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case emsSlotConstants.GET_ID_EMS_SLOT_REQUEST:
      return {
        ...state
      };
    case emsSlotConstants.GET_ID_EMS_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allems: action.users.getEmsUserById,
      };
    case emsSlotConstants.GET_ID_EMS_SLOT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case emsSlotConstants.ADD_EMS_SLOT_REQUEST:
      return {
        ...state
      };
    case emsSlotConstants.ADD_EMS_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case emsSlotConstants.ADD_EMS_SLOT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case emsSlotConstants.GET_LIST_EMS_SLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case emsSlotConstants.GET_LIST_EMS_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getEmsList.list,
        total: action.users.getEmsList.total
      };
    case emsSlotConstants.GET_LIST_EMS_SLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case emsSlotConstants.GET_LIST_EMS_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case emsSlotConstants.GET_LIST_EMS_BOOK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        emsBookedList: action.users.getShowEmsBookSlot.emsBookingSlotData,
        currentDate: action.users.getShowEmsBookSlot.currentDate
      };
    case emsSlotConstants.GET_LIST_EMS_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case emsSlotConstants.GETALL_EMS_SLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case emsSlotConstants.GETALL_EMS_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allrestaurent: action.users.getRestaurantUserById,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case emsSlotConstants.GETALL_EMS_SLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case emsSlotConstants.UPDATE_EMS_SLOT_REQUEST:
      return {
        ...state
      };
    case emsSlotConstants.UPDATE_EMS_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case emsSlotConstants.UPDATE_EMS_SLOT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case emsSlotConstants.DELETE_EMS_SLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case emsSlotConstants.DELETE_EMS_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case emsSlotConstants.DELETE_EMS_SLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case emsSlotConstants.DISABLE_EMS_SLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case emsSlotConstants.DISABLE_EMS_SLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case emsSlotConstants.DISABLE_EMS_SLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case emsSlotConstants.DISABLE_EMS_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case emsSlotConstants.DISABLE_EMS_BOOK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case emsSlotConstants.DISABLE_EMS_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}