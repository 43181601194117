import { userConstants, massageConstants, emsConstants } from '../_constants';
import { userService, massageService, emsService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const emsActions = {

    login,
    logout,
    disableEMS,
    getAllmassage,
    createEMSUser,
    updateEMSUser,
    getEMSUserList,
    deleteEMS,
    uploadImageClear,
    getEmsUserById,
    updateEMSUserPassword,
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createEMSUser(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        emsService.createEMSUser(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("EMS Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getEMSUserList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsConstants.ADD_EMS_REQUEST } }
    function success(users) { return { type: emsConstants.ADD_EMS_SUCCESS, users } }
    function failure(error) { return { type: emsConstants.ADD_EMS_FAILURE, error } }
}
function updateEMSUser(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        emsService.updateEMSUser(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("EMS Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getEMSUserList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsConstants.UPDATE_EMS_REQUEST } }
    function success(users) { return { type: emsConstants.UPDATE_EMS_SUCCESS, users } }
    function failure(error) { return { type: emsConstants.UPDATE_EMS_FAILURE, error } }
}

function updateEMSUserPassword(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        emsService.updateEMSUserPassword(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Password Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getEMSUserList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsConstants.UPDATE_PASSWORD_REQUEST } }
    function success(users) { return { type: emsConstants.UPDATE_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: emsConstants.UPDATE_PASSWORD_FAILURE, error } }
}
function getAllmassage() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        massageService.getAllmassage()
            .then(
                users => {
                    console.log("getAllmassage $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.GETALL_MASSAGE_REQUEST } }
    function success(users) { return { type: massageConstants.GETALL_MASSAGE_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.GETALL_MASSAGE_FAILURE, error } }
}
function getEmsUserById() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        emsService.getEmsUserById()
            .then(
                users => {
                    console.log("getEmsUserById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsConstants.GET_ID_EMS_REQUEST } }
    function success(users) { return { type: emsConstants.GET_ID_EMS_SUCCESS, users } }
    function failure(error) { return { type: emsConstants.GET_ID_EMS_FAILURE, error } }
}
function getEMSUserList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        emsService.getEMSUserList(data)
            .then(
                users => {
                    console.log("getEMSUserList $$$$ action:", users);
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsConstants.GET_LIST_EMS_REQUEST } }
    function success(users) { return { type: emsConstants.GET_LIST_EMS_SUCCESS, users } }
    function failure(error) { return { type: emsConstants.GET_LIST_EMS_FAILURE, error } }
}


function deleteEMS(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        emsService.deleteEMS(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getEMSUserList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsConstants.DELETE_EMS_REQUEST } }
    function success(users) { return { type: emsConstants.DELETE_EMS_SUCCESS, users } }
    function failure(error) { return { type: emsConstants.DELETE_EMS_FAILURE, error } }
}
function disableEMS(data, paginationData) {

    return dispatch => {
        dispatch(request());
        emsService.disableEMS(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getEMSUserList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsConstants.DISABLE_EMS_REQUEST } }
    function success(users) { return { type: emsConstants.DISABLE_EMS_SUCCESS, users } }
    function failure(error) { return { type: emsConstants.DISABLE_EMS_FAILURE, error } }
}
