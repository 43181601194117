import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
// import LoadingOverlay from 'react-loading-overlay';


class Login extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
    }
  }

  inputChange = (e) => {
    // console.log("11111111111111111111",e.target.name);
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  }


  loginSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let { userName, password, } = this.state.fieldslogin;
      console.log("asdfasdf  ", userName, password);
      this.props.dispatch(userActions.login({ userName: userName, password: password, }, this.props));
    }

  }

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Email
    if (!fieldslogin["userName"] || fieldslogin["userName"] === "") {
      formIsValid = false;
      errorslogin["userName"] = "Cannot be empty";
    }

    //Password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Please enter password!";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  componentDidMount() {

  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  render() {
    // let { users } = this.props;
    // let { loading } = users;

    return (
      <>

        {/* <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinnerlayerlisk-chat-admin/public/dist/img/download (3).jpeg
            text='Please wait...' />
        </div> */}

        {/* Whole Structure */}
        <div className="bg-center bg-no-repeat bg-cover " style={{ backgroundImage: `url("dist/img/BrothersSociety.jpeg")` }}>

          <section className="flex items-center justify-center min-h-screen backdrop-blur-sm">
            {/* login container */}
            <div className="flex items-center max-w-3xl p-5 shadow-lg bg-white/50 rounded-2xl">
              {/* form */}
              <div className="px-8 md:w-1/2 md:px-8">

                <div className="z-50 w-full px-8 space-y-2 h-18">
                  {/* <div className="flex justify-center">
                    <img src="dist/img/fitclub-logo.png" className="w-12 " alt="logo.png" />
                  </div> */}
                  <span className="flex justify-center text-3xl font-bold text-black first-letter:uppercase">Admin Panel</span>
                </div>

                <h2 className="mt-6 text-2xl font-bold text-[#002D74]">Login</h2>

                <form action className="flex flex-col gap-4 mt-4">
                  <div className="relative">
                    <input className={`px-10 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!this.state.errorslogin["userName"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="userName" name="userName" placeholder="User name" type="text" onChange={this.inputChange} />
                    {this.state.errorslogin["userName"] ?
                      <div className="text-xs text-red-600 invalid-feedback">
                        {this.state.errorslogin["userName"]}
                      </div>
                      : null}
                    {/* icon userName */}
                    <span className="absolute text-gray-500 top-2 left-2"><svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg></span>
                  </div>

                  <div className="relative">
                    <input className={`border-1 px-10 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!this.state.errorslogin["password"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="password" name="password" placeholder="password" type="password" onChange={this.inputChange} />
                    {this.state.errorslogin["password"] ?
                      <div className="text-xs text-red-600 invalid-feedback">
                        {this.state.errorslogin["password"]}
                      </div>
                      : null}
                    {/* Icon password */}
                    <span className="absolute text-gray-500 top-2 left-2 "><svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg></span>
                  </div>
                  <button className="bg-[#002D74] rounded text-white py-3 hover:scale-105 duration-300" type="button" onClick={this.loginSubmit}>Login</button>

                </form>

              </div>
              {/* image */}
              <div className="hidden w-1/2 md:block">
                <img className="rounded-2xl h-[550px]" src="dist/img/mobile-apps-banner-right.jpg" alt="loginsideimg.png" />
              </div>
            </div>
          </section>
        </div>

        {/* Whole Structure End*/}


      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users
  };
}
export default connect(mapStateToProps)(Login);
