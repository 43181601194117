import React from "react";

import Modal from 'react-modal';



export default function CreateAddCategoryModal(props) {

  let { addUserCreateModal, handleAddUserHideModal, errorsValet, inputAddUserChange, createRestoCategorySubmit, fieldsValet, handleFile } = props;

  return (

    <Modal isOpen={addUserCreateModal}>


      <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between">
              <p className="text-2xl font-bold">Add Valet Employee</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleAddUserHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>

            {/*Body*/}
            <div className="">
              <form autoComplete="off">
                {/* <div class="flex justify-center">
                  <div class="mb-3 w-full">
                    <label className="text-sm font-medium text-black ">Category :</label>
                    <select class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal  text-gray-700  bg-white bg-clip-padding bg-no-repeat  border border-solid border-gray-300 rounded transition ease-in-out  m-0  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example"
                      onChange={inputAddUserChange}
                      id="parentId"
                      name="parentId"
                      value={fieldsValet && fieldsValet["parentId"] ? fieldsValet["parentId"] : null}
                    >
                      <option selected>Plz Select Category</option>
                      {
                        allCategoryParent && allCategoryParent && allCategoryParent.length > 0 ?
                          allCategoryParent.map((element, index) => (
                            <option value={element.id}>{element && element.name ? element.name : "NA"}</option>
                          ))
                          : null
                      }
                    </select>
                    {errorsValet && errorsValet["parentId"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsValet["parentId"]}
                      </div>
                      : null}
                  </div>
                </div> */}

                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">Employee Name :
                    <input className={`px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsValet && !errorsValet["name"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="name" name="name" placeholder="Name" value={fieldsValet.name} type="text" onChange={inputAddUserChange} />
                    {errorsValet && errorsValet["name"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsValet["name"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">Employee Email :
                    <input className={`px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsValet && !errorsValet["name"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="email" name="email" placeholder="Email" value={fieldsValet.email} type="text" onChange={inputAddUserChange} />
                    {errorsValet && errorsValet["email"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsValet["email"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">Mobile No. :
                    <input className={`px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsValet && !errorsValet["mobile"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="mobile" name="mobile" placeholder="mobile" value={fieldsValet.mobile} type="text" onChange={inputAddUserChange} />
                    {errorsValet && errorsValet["mobile"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsValet["mobile"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">Password :
                    <input className={`px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsValet && !errorsValet["password"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="password" name="password" placeholder="password" value={fieldsValet.password} type="text" onChange={inputAddUserChange} />
                    {errorsValet && errorsValet["password"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsValet["password"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="flex justify-between px-2"   >
                  <div className="flex flex-wrap w-full py-2">
                    <label htmlFor="image">
                      Upload Image
                    </label>
                    <input id="image" name="image" type="file" onChange={handleFile} />
                    <div className="flex mt-1 space-x-4">

                    </div>
                  </div>
                  <div>
                    <div className='flex justify-center'>
                      <img className="object-cover h-10 rounded-sm w-14" src="/dist/img/profile.png" alt="not found" />
                    </div>
                  </div>
                </div>

                {/* <div className="flex flex-wrap w-full py-2">
                  <label htmlFor="image">
                    Upload Image
                  </label>
                  <input id="image" name="image" type="file" onChange={handleFile} />
                  <div className="flex mt-1 space-x-4">
                  
                  </div>
                </div>  */}

                <div className="mx-auto my-2 w-60">
                  <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-blue-500 border border-blue-500 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-blue-500 focus:shadow-outline-yellow active:bg-blue-500" type="button" onClick={createRestoCategorySubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
