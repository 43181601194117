import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
// import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import AddBalanceModal from './components/AddBalanceModal/AddBalanceModal';
import DeductBalanceModal from './components/AddBalanceModal/DeductBalanceModal';
import CreditActivityModal from "./components/CreditActivityModal/CreditActivityModal";
import UpdateUserModal from "./components/UpdateUserModal/UpdateUserModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd } from "react-icons/hi";
import UpdateUserPasswordModal from './components/UpdateUserPasswordModal/UpdateUserPasswordModal';
import { BiSearch } from "react-icons/bi";
import { RiLockPasswordLine, } from "react-icons/ri";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit, } from "react-icons/md";
import './style.css'

class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideo: false,
      isVideoUpdate: false,
      userRowData: {},
      fieldsQuote: {},
      errorsQuote: {},
      fieldsUserUpdate: {},
      fieldsUserPasswordUpdate: {},
      errorsUpdateQuote: {},
      errorsUpdatePassword: {},
      viewRowData: {},
      type: null,
      gameType: null,
      rankType: null,
      addQuoteModal: false,
      moreDetailsCreateModal: false,
      UpdateQuoteCreateModal: false,
      loginToThisAccountModal: false,
      creditActivityOpenModal: false,
      creditpasswordOpenModal: false,
      // fieldsUserUpdate: {},
      fieldsUser: {},
      // errorsUpdateQuote: {},
      errorsUpdate: {},
      // AddBalanceModal: false,
      // addQuoteModal: false,
      DeductBalanceModal: false,
      balance: '',
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let reqData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserList(reqData));

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        fieldsUserUpdate: {},
        fieldsQuote: {},
        fieldsUserPasswordUpdate: {},
        errorsUpdateQuote: {},
        errorsUpdatePassword: {},
        UpdateQuoteCreateModal: false,
        creditpasswordOpenModal: false,
        creditActivityOpenModal: false,
        addQuoteModal: false,
        fieldsUser: {},
        errorsUpdateQuoteAdd: {},
        errorsUpdate: {},
        // AddBalanceModal: false,
        // addQuoteModal: false,
        DeductBalanceModal: false,
        balance: '',
        idParam: '',
        fieldsUserdeduct: ''

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserList(data));
  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to disables ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.getUserListIsDisable(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  disableUserAdmin = (data) => {
    console.log('datadatadatadatadatadatadatadata', data.id);
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to disable admin User?',
      message: `Are you sure to disables admin ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.updateUserStatusAdmin(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  enableUserAdmin = (data) => {
    console.log('datadatadatadatadatadatadatadata', data.id);
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to enable admin User?',
      message: `Are you sure to enable for admin ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.updateUserStatusAdmin(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  deleteUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.deleteUser(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  handleOpenCreateModalMoreDetails = (data) => {

    this.setState({ moreDetailsCreateModal: true, userRowData: data });
    console.log("fgfdgf1111111222211111111111111111111111111");
  }


  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }

  handleOpenCreateModalUpdatePassword = (data) => {
    this.setState({ UpdateQuoteCreateModal: true, fieldsUserUpdate: data });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdateQuoteCreateModal: false });
  }

  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(userActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({ addQuoteModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }

  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ addQuoteModal: true });
  }
  inputAddUserChange = (e) => {
    console.log(e.target.name, 'e___________________________', e.target.value);
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsQuote = this.state.fieldsQuote;
    let errorsQuote = this.state.errorsQuote;
    fieldsQuote[name] = value;
    console.log(name, value);
    errorsQuote[name] = "";
    this.setState({ fieldsQuote, errorsQuote });
  }
  inputChangeUpdateUser = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUserUpdate = this.state.fieldsUserUpdate;
    let errorsUpdateQuote = this.state.errorsUpdateQuote;
    fieldsUserUpdate[name] = value;
    errorsUpdateQuote[name] = "";
    console.log(name, value);
    this.setState({ fieldsUserUpdate, errorsUpdateQuote });
  }
  inputChangeUpdatePasswordUser = (e) => {
    e.preventDefault();
    console.log("1111111111111", e.target.value);
    let { name, value } = e.target;
    let fieldsUserPasswordUpdate = this.state.fieldsUserPasswordUpdate;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUserPasswordUpdate[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUserPasswordUpdate, errorsUpdatePassword });
  }

  createAddUserSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;
    if (this.handleValidationAddUser()) {
      console.log('111111111111111111111');
      let reqData = {
        "name": this.state.fieldsQuote.name,
        "number": this.state.fieldsQuote.number,
        "email": this.state.fieldsQuote.email,
        "password": this.state.fieldsQuote.password,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
      }

      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(userActions.registerUserAdmin(reqData, this.props));
    }

  }
  handleFile = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.setState({ selectedFile: null });
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));

    }
    else {
      console.log("No File To Upload!")
    }

  }

  handleValidationAddUser = () => {
    let fieldsQuote = this.state.fieldsQuote;
    let errorsQuote = {};
    let formIsValid = true;

    //name
    if (!fieldsQuote["name"] || fieldsQuote["name"] === "") {
      formIsValid = false;
      errorsQuote["name"] = "Cannot be empty";
    }
    //number
    if (!fieldsQuote["number"] || fieldsQuote["number"] === "") {
      formIsValid = false;
      errorsQuote["number"] = "Cannot be empty";
    }

    //Email
    if (!fieldsQuote["email"] || fieldsQuote["email"] === "") {
      formIsValid = false;
      errorsQuote["email"] = "Please Enter Email Address";
    }
    if (typeof fieldsQuote["email"] !== "undefined" && fieldsQuote["email"] !== "") {
      let lastAtPos = fieldsQuote["email"].lastIndexOf('@');
      let lastDotPos = fieldsQuote["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fieldsQuote["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fieldsQuote["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errorsQuote["email"] = "Enter valid email!";
      }
    }

    //password
    if (!fieldsQuote["password"] || fieldsQuote["password"] === "") {
      formIsValid = false;
      errorsQuote["password"] = "Cannot be empty";
    }

    console.log('errorsUpdateQuoteerrorsUpdateQuoteerrorsUpdateQuote', errorsQuote);
    this.setState({ errorsQuote: errorsQuote });
    return formIsValid;
  }

  updateUserSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;

    if (this.handleValidationUpdateUser()) {
      let reqData = {
        "id": this.state.fieldsUserUpdate.id,
        "name": this.state.fieldsUserUpdate.name,
        "number": this.state.fieldsUserUpdate.number,
        "email": this.state.fieldsUserUpdate.email,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsUserUpdate.image,
      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>", reqData);

      this.props.dispatch(userActions.updateUser(reqData, paginationData));
    }

  }
  handleValidationUpdateUser = () => {
    let fieldsUserUpdate = this.state.fieldsUserUpdate;
    let errorsUpdateQuote = {};
    let formIsValid = true;

    //name
    if (!fieldsUserUpdate["name"] || fieldsUserUpdate["name"] === "") {
      formIsValid = false;
      errorsUpdateQuote["name"] = "Cannot be empty";
    }
    //number
    if (!fieldsUserUpdate["number"] || fieldsUserUpdate["number"] === "") {
      formIsValid = false;
      errorsUpdateQuote["number"] = "Cannot be empty";
    }
    //Email
    if (!fieldsUserUpdate["email"] || fieldsUserUpdate["email"] === "") {
      formIsValid = false;
      errorsUpdateQuote["email"] = "Please Enter Email Address";
    }
    if (typeof fieldsUserUpdate["email"] !== "undefined" && fieldsUserUpdate["email"] !== "") {
      let lastAtPos = fieldsUserUpdate["email"].lastIndexOf('@');
      let lastDotPos = fieldsUserUpdate["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fieldsUserUpdate["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fieldsUserUpdate["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errorsUpdateQuote["email"] = "Enter valid email!";
      }
    }

    console.log("errorsUpdateQuoteerrorsUpdateQuoteerrorsUpdateQuote", errorsUpdateQuote);
    this.setState({ errorsUpdateQuote: errorsUpdateQuote });
    return formIsValid;
  }

  updateUserPasswordSubmit = () => {
    // let { users } = this.props;
    // let { filesDetails } = users;

    if (this.handleValidationUpdateUserPassword()) {
      let reqData = {
        "id": this.state.fieldsUserPasswordUpdate.id,
        "password": this.state.fieldsUserPasswordUpdate.password,
        // "confirmPassword": this.state.fieldsUserPasswordUpdate.confirmPassword,

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>", reqData);

      this.props.dispatch(userActions.changePassword(reqData, paginationData));
    }

  }



  handleValidationUpdateUserPassword = () => {
    let fieldsUserPasswordUpdate = this.state.fieldsUserPasswordUpdate;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //name
    if (!fieldsUserPasswordUpdate["password"] || fieldsUserPasswordUpdate["password"] === "") {
      formIsValid = false;
      errorsUpdatePassword["password"] = "Cannot be empty";
    }


    console.log("errorsUpdatePassworderrorsUpdatePassworderrorsUpdatePassword", errorsUpdatePassword);
    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }

  handleCreditActivityOpenModal = () => {
    this.setState({ creditActivityOpenModal: true, })
  }
  handlePasswordModal = (data) => {
    this.setState({ creditpasswordOpenModal: true, fieldsUserPasswordUpdate: data })
  }
  handleCreditpasswordHideModal = () => {
    // this.state.fieldsUserPasswordUpdate.confirmPassword = ''
    // this.state.fieldsUserPasswordUpdate.password = ''
    this.setState({ creditpasswordOpenModal: false, })
  }
  handleCreditActivityHideModal = () => {
    this.setState({ creditActivityOpenModal: false, fieldsQuote: {}, errorsQuote: {} })
  }

  handleUpdatePasswordHideModalAdd = () => {
    this.setState({ AddBalanceModal: false, balance: '' });
  }
  handleUpdateHideModal = () => {
    this.setState({ DeductBalanceModal: false, balance: '' });
  }
  handleOpenCreateModalUpdatePasswordAddBal = (data) => {
    this.setState({ idParam: data && data.id });
    this.setState({ AddBalanceModal: true, fieldsUserUpdate: { id: this.props.match.params.id } });


    console.log("11111111111", data && data.id);
  }
  handleOpenCreateModalPassword = (data) => {
    this.setState({ DeductBalanceModal: true, fieldsUserdeduct: data.id });
  }

  addBalanceSubmit = () => {


    if (this.handleValidationUpdateUserAdd()) {
      let reqData = {
        "id": this.state.idParam,
        "balance": parseInt(this.state.balance),

      }
      // let paginationData = {
      //   "userId": this.props.match.params.id,
      // }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>", reqData);

      this.props.dispatch(userActions.AddUserBalance(reqData,));
      // this.setState({ balance: "" })
      // }

    }
  }
  deductBalanceSubmit = () => {


    if (this.handleValidationUpdateUserDeduct()) {
      let reqData = {
        "id": this.state.fieldsUserdeduct,
        //  "id": "6440f9285c24af37f564427e",
        "amount": parseInt(this.state.balance),

      }
      let dataRes = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>", reqData);

      this.props.dispatch(userActions.deductUserBalance(reqData, dataRes));
      // }

    }
  }

  handleValidationUpdateUserDeduct = () => {
    let balance = this.state.balance;
    let errorsUpdate = {};
    let formIsValid = true;

    //name
    if (!balance || balance === "") {
      formIsValid = false;
      errorsUpdate["balance"] = "Cannot be empty";
    }


    console.log("errorsUpdateerrorsUpdateerrorsUpdate", errorsUpdate);
    this.setState({ errorsUpdate: errorsUpdate });
    return formIsValid;
  }

  handleValidationUpdateUserAdd = () => {
    let balance = this.state.balance;
    let errorsUpdateQuoteAdd = {};
    let formIsValid = true;

    //name
    if (!balance || balance === "") {
      formIsValid = false;
      errorsUpdateQuoteAdd["balance"] = "Cannot be empty";
    }


    console.log("errorsUpdateQuoteAdderrorsUpdateQuoteAdderrorsUpdateQuoteAdd", errorsUpdateQuoteAdd);
    this.setState({ errorsUpdateQuoteAdd: errorsUpdateQuoteAdd });
    return formIsValid;
  }

  setBalance = (e) => {
    e.preventDefault();
    let value = e.target.value;
    console.log('value__________', value);
    this.setState({ balance: value })
  }


  render() {

    let { users } = this.props;
    let { filesDetails, getUserListItems, total, loading } = users;
    console.log("idParamidParamidParam", this.state.idParam);

    console.log("this.props.match.params.idthis.props.match.params.id", this.state.fieldsUserdeduct);

    console.log('fieldsUserPasswordUpdate', this.state.fieldsUserPasswordUpdate);


    console.log('getUserListItemsgetUserListItemsgetUserListItemsgetUserListItems', getUserListItems);




    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="p-6 space-y-6 bg-white border rounded-md">

                  <div className="flex flex-wrap items-center justify-between w-full ">
                    <div className="">
                      <h3 className="font-medium text-xl text-[#3D405B] whitespace-nowrap">Active User list</h3>
                    </div>

                    <div className='flex space-x-6 '>
                      <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form>
                      <button className="flex items-center px-6 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md" onClick={() => this.handleCreditActivityOpenModal()}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" /> Add User </button>
                    </div>

                  </div>



                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md">
                    <div className="max-w-full overflow-x-auto">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Name</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Number</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Email</th>
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">image</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Admin</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Status</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                getUserListItems && getUserListItems.length > 0 ?
                                  getUserListItems.map((element, index) => (<React.Fragment key={element.id}>
                                    {/* {element && element.isDisabled === false ? */}
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm font-medium text-gray-800 capitalize whitespace-nowrap">
                                        {element && element.name ? element.name : "NA"}</td>
                                      <td className="px-6 py-3 text-sm font-medium text-gray-800 capitalize whitespace-nowrap">
                                        {element && element.number ? element.number : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.email ? element.email : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <div className='flex justify-center'>
                                          <img className="object-cover h-10 rounded-sm w-14" src={element && element.imageLinkUrl
                                            ? element.imageLinkUrl
                                            : "/dist/img/profile.png"} alt="" />
                                        </div>
                                      </td>
                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isAdmin === false ?
                                            <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.enableUserAdmin(element)}>
                                              <label>Enable</label>
                                            </div>
                                            : <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUserAdmin(element)}>
                                              <label>Disabled</label>
                                            </div>}
                                        </span>
                                      </td>
                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isDisabled === false ?
                                            <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUser(element)}>
                                              <label>Enable</label>
                                            </div>
                                            : <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUser(element)}>
                                              <label>Disabled</label>
                                            </div>}
                                        </span>
                                      </td>

                                      <td className="flex justify-center px-2 py-3 space-x-2 text-center text-gray-600 whitespace-nowrap">
                                        <div className='flex space-x-2'>
                                          <span className="relative ">
                                            {element.isActive ? "ADDED" : <div class="targetablepx-4 tooltip p-2 rounded-full font-medium hover:bg-blue-100 cursor-pointer  " onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>More Details</span>
                                              <BiDetail style={{ fontSize: "1.5rem" }} />
                                            </div>}
                                          </span>
                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.deleteUser(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-2 text-sm text-white bg-gray-500 rounded tooltip-text'>Delete</span>
                                              <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>
                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full font-medium hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>Edit</span>
                                              <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative ">
                                            {element.isActive ? "ADDED" : <div class="targetablepx-4 tooltip p-2 rounded-full font-medium hover:bg-blue-100 cursor-pointer  " onClick={() => this.handlePasswordModal(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>Password</span>
                                              <RiLockPasswordLine style={{ fontSize: "1.5rem" }} />
                                            </div>}
                                          </span>


                                        </div>
                                      </td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>

                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <CreditActivityModal
          handleCreditActivityHideModal={this.handleCreditActivityHideModal}
          creditActivityOpenModal={this.state.creditActivityOpenModal}
          addQuoteModal={this.state.addQuoteModal}
          fieldsQuote={this.state.fieldsQuote}
          errorsQuote={this.state.errorsQuote}
          inputAddUserChange={this.inputAddUserChange}
          createAddUserSubmit={this.createAddUserSubmit}
          handleFile={this.handleFile}
          handleFile2={this.handleFile2}
          createQuoteSubmit={this.createQuoteSubmit}
          handleSelectType={this.handleSelectType}
          handleSelectGameType={this.handleSelectGameType}
          handleSelectRankType={this.handleSelectRankType}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputChangeIsVideo={this.inputChangeIsVideo}
          filesDetails={filesDetails}
          isVideo={this.state.isVideo}
        />

        <UpdateUserModal
          UpdateQuoteCreateModal={this.state.UpdateQuoteCreateModal}
          fieldsUserUpdate={this.state.fieldsUserUpdate}
          errorsUpdateQuote={this.state.errorsUpdateQuote}
          inputChangeUpdateUser={this.inputChangeUpdateUser}
          updateUserSubmit={this.updateUserSubmit}
          filesDetails={filesDetails}
          handleUpdatePasswordHideModal={this.handleUpdatePasswordHideModal}
          handleFile={this.handleFile}

        />

        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          userRowData={this.state.userRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        <AddBalanceModal
          AddBalanceModal={this.state.AddBalanceModal}
          fieldsUserUpdate={this.state.fieldsUserUpdate}
          balance={this.state.balance}
          inputChangeUpdateUser={this.inputChangeUpdateUser}
          setBalance={this.setBalance}
          addBalanceSubmit={this.addBalanceSubmit}
          handleAppSeetingHideModalAdd={this.handleUpdatePasswordHideModalAdd}
          handleFile={this.handleFile}

        />
        <DeductBalanceModal
          DeductBalanceModal={this.state.DeductBalanceModal}
          balance={this.state.balance}
          fieldsUser={this.state.fieldsUser}
          errorsUpdate={this.state.errorsUpdate}
          inputChangeUpdateUser={this.inputChangeUpdateUser}
          setBalance={this.setBalance}
          deductBalanceSubmit={this.deductBalanceSubmit}
          handleAppSeetingHideModal={this.handleUpdateHideModal}
          handleFile={this.handleFile}

        />


        <UpdateUserPasswordModal
          creditpasswordOpenModal={this.state.creditpasswordOpenModal}
          fieldsUserPasswordUpdate={this.state.fieldsUserPasswordUpdate}
          errorsUpdatePassword={this.state.errorsUpdatePassword}
          inputChangeUpdatePasswordUser={this.inputChangeUpdatePasswordUser}
          updateUserPasswordSubmit={this.updateUserPasswordSubmit}
          handleCreditpasswordHideModal={this.handleCreditpasswordHideModal}
          handleFile={this.handleFile}
          filesDetails={filesDetails}

        />


      </>

    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(Quote);
