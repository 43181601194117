import {sectionConstants} from '../_constants';

export function section(state = {}, action) {
  // console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case sectionConstants.GET_ID_SECTION_REQUEST:
      return {
        ...state
      };
    case sectionConstants.GET_ID_SECTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allrestaurent: action.users.getRestaurantUserById,

      };
    case sectionConstants.GET_ID_SECTION_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case sectionConstants.ADD_SECTION_REQUEST:
      return {
        ...state
      };
    case sectionConstants.ADD_SECTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case sectionConstants.ADD_SECTION_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case sectionConstants.GET_LIST_SECTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sectionConstants.GET_LIST_SECTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        itemsSection: action.users.getSectionList.list,
        totalSection: action.users.getSectionList.total
      };
    case sectionConstants.GET_LIST_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case sectionConstants.GET_LIST_SECTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sectionConstants.GET_LIST_SECTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // parkingItems: action.users.getSectionList.list,
        // parkingTotal: action.users.getSectionList.total
      };
    case sectionConstants.GET_LIST_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case sectionConstants.GETALL_SECTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sectionConstants.GETALL_SECTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllSection: action.users.getAllSectionList,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case sectionConstants.GETALL_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case sectionConstants.UPDATE_SECTION_REQUEST:
      return {
        ...state
      };
    case sectionConstants.UPDATE_SECTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case sectionConstants.UPDATE_SECTION_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case sectionConstants.UPDATE_SECTION_PASSWORD_REQUEST:
      return {
        ...state
      };
    case sectionConstants.UPDATE_SECTION_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case sectionConstants.UPDATE_SECTION_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case sectionConstants.DELETE_SECTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sectionConstants.DELETE_SECTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sectionConstants.DELETE_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case sectionConstants.DISABLE_SECTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sectionConstants.DISABLE_SECTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sectionConstants.DISABLE_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state
  }
}