import React from "react";
import { CgLogIn } from 'react-icons/cg';
import Modal from 'react-modal';


export default function UpdateCategoryModal(props) {

  let { UpdateCategoryCreateModal, handleAppSeetingHideModal, errorsUpdateClasses, inputChangeUpdateCategory, updateCategorySubmit, fieldsClassesUpdate, handleFile, allCategory, filesDetails
  } = props;
  console.log("MODAL__fieldsClassesUpdate::", fieldsClassesUpdate);


  return (
    <div className={UpdateCategoryCreateModal ? "fixed w-full top-0 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto mt-2">
        {/*modal header*/}
        <div className="flex items-center justify-between p-6 py-2 bg-blue-500 border-b">
          <p className="text-[16px] font-medium text-white uppercase">Update Classes Slot</p>
          <div className="rounded-full cursor-pointer modal-close ">
            <svg onClick={() => handleAppSeetingHideModal()} className="fill-current text-white/60 hover:text-white/100 " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>


        <div className="p-4">
          <button className="text-base text-gray-600 p-4 py-2 border border-gray-300 border-b-transparent bg-white -mb-0.5 font-bold">
            Update Slot No  {fieldsClassesUpdate.slotNo}
          </button>
          <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">

            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Class Name:</label>
              <select class="form-select border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputChangeUpdateCategory} id="groupClassCategoryId" name="groupClassCategoryId"
                value={fieldsClassesUpdate && fieldsClassesUpdate["groupClassCategoryId"] ? fieldsClassesUpdate["groupClassCategoryId"] : null} >
                <option selected>Plz Select Category</option>
                {
                  allCategory && allCategory && allCategory.length > 0 ?
                    allCategory.map((element, index) => (
                      <option value={element && element.id ? element.id : null}>{element && element.categoryType ? element.categoryType : "NA"}</option>
                    )) : null
                }
              </select>
              {errorsUpdateClasses && errorsUpdateClasses["groupClassCategoryId"] ?
                <div className="invalid-feedback text-yellow-600">
                  {errorsUpdateClasses["groupClassCategoryId"]}
                </div>
                : null}
            </div>

            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Slot No :</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-red-500  ${errorsUpdateClasses && !errorsUpdateClasses["slotNo"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                id="slotNo" name="slotNo" placeholder="Slot No" value={fieldsClassesUpdate.slotNo} type="slotNo" onChange={inputChangeUpdateCategory} />
              {errorsUpdateClasses && errorsUpdateClasses["slotNo"] ?
                <div className="invalid-feedback text-yellow-600">
                  {errorsUpdateClasses["slotNo"]}
                </div>
                : null}
            </div>

            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Price :</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-red-500  ${errorsUpdateClasses && !errorsUpdateClasses["slotNo"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                id="price" name="price" placeholder="Price" value={fieldsClassesUpdate.price} type="price" onChange={inputChangeUpdateCategory} />
              {errorsUpdateClasses && errorsUpdateClasses["price"] ?
                <div className="invalid-feedback text-yellow-600">
                  {errorsUpdateClasses["price"]}
                </div>
                : null}
            </div>

            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Starting Time :</label>
              <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-red-500 ${errorsUpdateClasses && !errorsUpdateClasses["name"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                id="startingTime" name="startingTime" placeholder="Starting Time" value={fieldsClassesUpdate.startingTime} type="default" onChange={inputChangeUpdateCategory} />
              {errorsUpdateClasses && errorsUpdateClasses["startingTime"] ?
                <div className="invalid-feedback text-yellow-600">
                  {errorsUpdateClasses["startingTime"]}
                </div>
                : null}
            </div>

            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Ending Time :</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-red-500 ${errorsUpdateClasses && !errorsUpdateClasses["name"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                id="endingTime" name="endingTime" placeholder="Ending Time" value={fieldsClassesUpdate.endingTime} type="default" onChange={inputChangeUpdateCategory} />
              {errorsUpdateClasses && errorsUpdateClasses["endingTime"] ?
                <div className="invalid-feedback text-yellow-600">
                  {errorsUpdateClasses["endingTime"]}
                </div>
                : null}
            </div>


            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Limit :</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-red-500 ${errorsUpdateClasses && !errorsUpdateClasses["limit"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                id="limit" name="limit" placeholder="Limit" value={fieldsClassesUpdate.limit} type="default" onChange={inputChangeUpdateCategory} />
              {errorsUpdateClasses && errorsUpdateClasses["limit"] ?
                <div className="invalid-feedback text-yellow-600">
                  {errorsUpdateClasses["limit"]}
                </div>
                : null}
            </div>





            {/* btns */}
            <div className="flex justify-end py-0 space-x-4">
              <button className="bg-blue-500  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
                onClick={updateCategorySubmit}
              >Submit
                <CgLogIn size={18} className="ml-2" />
              </button>
            </div>

          </form>

        </div>
        {/*Footer*/}

      </div>
    </div>




  );
}
