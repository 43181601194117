import {
  groupClassCategoryConstants
} from '../_constants';

export function groupClassCategory(state = {}, action) {
//  console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case groupClassCategoryConstants.GET_ID_CLASS_CATEGORY_REQUEST:
      return {
        ...state
      };
    case groupClassCategoryConstants.GET_ID_CLASS_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allrestaurent: action.users.getRestaurantUserById,
      };
    case groupClassCategoryConstants.GET_ID_CLASS_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case groupClassCategoryConstants.ADD_CLASS_CATEGORY_REQUEST:
      return {
        ...state
      };
    case groupClassCategoryConstants.ADD_CLASS_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case groupClassCategoryConstants.ADD_CLASS_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case groupClassCategoryConstants.GET_LIST_CLASS_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case groupClassCategoryConstants.GET_LIST_CLASS_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getGroupClassCategoryList.list,
        total: action.users.getGroupClassCategoryList.total
      };
    case groupClassCategoryConstants.GET_LIST_CLASS_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case groupClassCategoryConstants.GETALL_CLASS_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case groupClassCategoryConstants.GETALL_CLASS_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allCategory: action.users.getAllGroupClassCategory,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case groupClassCategoryConstants.GETALL_CLASS_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case groupClassCategoryConstants.UPDATE_CLASS_CATEGORY_REQUEST:
      return {
        ...state
      };
    case groupClassCategoryConstants.UPDATE_CLASS_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case groupClassCategoryConstants.UPDATE_CLASS_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case groupClassCategoryConstants.DELETE_CLASS_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case groupClassCategoryConstants.DELETE_CLASS_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case groupClassCategoryConstants.DELETE_CLASS_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case groupClassCategoryConstants.DISABLE_CLASS_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case groupClassCategoryConstants.DISABLE_CLASS_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case groupClassCategoryConstants.DISABLE_CLASS_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}