import React from "react";
import Modal from 'react-modal';
import moment from 'moment'

const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};

export default function ViewMoreDetailsModal(props) {

  let { moreDetailsCreateModal, handleMoreDetailsHideModal, massageSlotRowData } = props;
  console.log("massageSlotRowData__MODAL", massageSlotRowData);

  return (

    <Modal
      isOpen={moreDetailsCreateModal}
      style={customStyles}
      ariaHideApp={false}
    // contentLabel="Example Modal"
    >


      <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-1/2 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-4xl h-2/3">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between">
              <p className="py-2 text-2xl font-bold">View more</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleMoreDetailsHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="">

              <form autoComplete="off">
                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">Slot No :</label>
                    <input className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                      value={massageSlotRowData.slotNo} type="text" disabled />
                  </div>
                </div>


                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">price :</label>
                    <input className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                      value={massageSlotRowData.price} type="text" disabled />
                  </div>
                </div>

                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">Starting Time :</label>
                    <input className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                      value={massageSlotRowData.startingTime} type="text" disabled />
                  </div>
                </div>
                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">Ending Time :</label>
                    <input className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                      value={massageSlotRowData.endingTime} type="text" disabled />
                  </div>
                </div>

                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">created Date
                      :</label>
                    <input className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                      value={moment(new Date(parseInt(massageSlotRowData.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")} type="text" disabled />
                  </div>
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
