import {
  plotConstants
} from '../_constants';

export function plot(state = {}, action) {
  // console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case plotConstants.GET_ID_PLOT_REQUEST:
      return {
        ...state
      };
    case plotConstants.GET_ID_PLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllPlotList: action.users.getAllPlotList,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case plotConstants.GET_ID_PLOT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case plotConstants.ADD_PLOT_REQUEST:
      return {
        ...state
      };
    case plotConstants.ADD_PLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case plotConstants.ADD_PLOT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case plotConstants.GET_LIST_PLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case plotConstants.GET_LIST_PLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getPlotList: action.users.getPlotList.list,
        total: action.users.getPlotList.total
      };
    case plotConstants.GET_LIST_PLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case plotConstants.GET_LIST_PLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case plotConstants.GETALL_PLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case plotConstants.GETALL_PLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allrestaurent: action.users.getRestaurantUserById,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case plotConstants.GETALL_PLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case plotConstants.UPDATE_PLOT_REQUEST:
      return {
        ...state
      };
    case plotConstants.UPDATE_PLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case plotConstants.UPDATE_PLOT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case plotConstants.UPDATE_PLOT_PASSWORD_REQUEST:
      return {
        ...state
      };
    case plotConstants.UPDATE_PLOT_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case plotConstants.UPDATE_PLOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case plotConstants.DELETE_PLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case plotConstants.DELETE_PLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case plotConstants.DELETE_PLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case plotConstants.DISABLE_PLOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case plotConstants.DISABLE_PLOT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case plotConstants.DISABLE_PLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}