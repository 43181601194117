export const massageSlotConstants = {

    GET_ID_MASSAGE_SLOT_REQUEST: 'GET_ID_MASSAGE_SLOT_REQUEST',
    GET_ID_MASSAGE_SLOT_SUCCESS: 'GET_ID_MASSAGE_SLOT_SUCCESS',
    GET_ID_MASSAGE_SLOT_FAILURE: 'GET_ID_MASSAGE_SLOT_FAILURE',

    GETALL_MASSAGE_REQUEST: 'GETALL_MASSAGE_REQUEST',
    GETALL_MASSAGE_SUCCESS: 'GETALL_MASSAGE_SUCCESS',
    GETALL_MASSAGE_FAILURE: 'GETALL_MASSAGE_FAILURE',

    GET_LIST_MASSAGE_SLOT_REQUEST: 'GET_LIST_MASSAGE_SLOT_REQUEST',
    GET_LIST_MASSAGE_SLOT_SUCCESS: 'GET_LIST_MASSAGE_SLOT_SUCCESS',
    GET_LIST_MASSAGE_SLOT_FAILURE: 'GET_LIST_MASSAGE_SLOT_FAILURE',

    GET_LIST_MASSAGE_BOOK_REQUEST: 'GET_LIST_MASSAGE_BOOK_REQUEST',
    GET_LIST_MASSAGE_BOOK_SUCCESS: 'GET_LIST_MASSAGE_BOOK_SUCCESS',
    GET_LIST_MASSAGE_BOOK_FAILURE: 'GET_LIST_MASSAGE_BOOK_FAILURE',


    ADD_MASSAGE_SLOT_REQUEST: 'ADD_MASSAGE_SLOT_REQUEST',
    ADD_MASSAGE_SLOT_SUCCESS: 'ADD_MASSAGE_SLOT_SUCCESS',
    ADD_MASSAGE_SLOT_FAILURE: 'ADD_MASSAGE_SLOT_FAILURE',

    DELETE_MASSAGE_SLOT_REQUEST: 'DELETE_MASSAGE_SLOT_REQUEST',
    DELETE_MASSAGE_SLOT_SUCCESS: 'DELETE_MASSAGE_SLOT_SUCCESS',
    DELETE_MASSAGE_SLOT_FAILURE: 'DELETE_MASSAGE_SLOT_FAILURE',

    UPDATE_MASSAGE_SLOT_REQUEST: 'UPDATE_MASSAGE_SLOT_REQUEST',
    UPDATE_MASSAGE_SLOT_SUCCESS: 'UPDATE_MASSAGE_SLOT_SUCCESS',
    UPDATE_MASSAGE_SLOT_FAILURE: 'UPDATE_MASSAGE_SLOT_FAILURE',

    DISABLE_MASSAGE_SLOT_REQUEST: 'DISABLE_MASSAGE_SLOT_REQUEST',
    DISABLE_MASSAGE_SLOT_SUCCESS: 'DISABLE_MASSAGE_SLOT_SUCCESS',
    DISABLE_MASSAGE_SLOT_FAILURE: 'DISABLE_MASSAGE_SLOT_FAILURE',

    DISABLE_MASSAGE_BOOK_REQUEST: 'DISABLE_MASSAGE_BOOK_REQUEST',
    DISABLE_MASSAGE_BOOK_SUCCESS: 'DISABLE_MASSAGE_BOOK_SUCCESS',
    DISABLE_MASSAGE_BOOK_FAILURE: 'DISABLE_MASSAGE_BOOK_FAILURE',

};
