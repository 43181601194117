import { userConstants, bookingListConstants } from '../_constants';
import { userService, bookingListService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const bookingListActions = {

    login,
    logout,
    getBookingList,
    uploadImageClear,
    bookAndUnBook,
    updateStatusAllUnBook
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    // console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}



function getBookingList(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        bookingListService.getBookingList(data)
            .then(
                users => {
                    console.log("getBookingList $$$$ action:", users);
                    dispatch(success(users));
                    // dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: bookingListConstants.GET_LIST_BOOKINGLIST_REQUEST } }
    function success(users) { return { type: bookingListConstants.GET_LIST_BOOKINGLIST_SUCCESS, users } }
    function failure(error) { return { type: bookingListConstants.GET_LIST_BOOKINGLIST_FAILURE, error } }
}

function bookAndUnBook(data, paginationdata) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        bookingListService.bookAndUnBook(data)
            .then(
                users => {
                    console.log("bookAndUnBook $$$$ action:", users);
                    dispatch(success(users));
                    dispatch(this.getBookingList(paginationdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: bookingListConstants.UN_BOOK_REQUEST } }
    function success(users) { return { type: bookingListConstants.UN_BOOK_SUCCESS, users } }
    function failure(error) { return { type: bookingListConstants.UN_BOOK_FAILURE, error } }
}

function updateStatusAllUnBook(paginationdata) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        bookingListService.updateStatusAllUnBook()
            .then(
                users => {
                    console.log("updateStatusAllUnBook $$$$ action:", users);
                    dispatch(success(users));
                    dispatch(this.getBookingList(paginationdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: bookingListConstants.UPDATE_ALL_STATUS_UN_BOOK_REQUEST } }
    function success(users) { return { type: bookingListConstants.UPDATE_ALL_STATUS_UN_BOOK_SUCCESS, users } }
    function failure(error) { return { type: bookingListConstants.UPDATE_ALL_STATUS_UN_BOOK_FAILURE, error } }
}


