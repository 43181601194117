import { userConstants, massageConstants, massageSlotConstants } from '../_constants';
import { userService, massageService, massageSlotService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const massageSlotActions = {

    login,
    logout,
    updateMassageStatus,
    getAllmassage,
    createMassage,
    updateMassage,
    getMassageList,
    deleteMassage,
    uploadImageClear,
    getMassageSlotSlotUserById,
    getShowMassageBookSlotList,
    rejectBookMassage,
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createMassage(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        massageSlotService.createMassage(data)
            .then(

                users => {
                    console.log("users___________users:::", users);
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Massage Slot Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getMassageList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageSlotConstants.ADD_MASSAGE_SLOT_REQUEST } }
    function success(users) { return { type: massageSlotConstants.ADD_MASSAGE_SLOT_SUCCESS, users } }
    function failure(error) { return { type: massageSlotConstants.ADD_MASSAGE_SLOT_FAILURE, error } }
}
function updateMassage(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        massageSlotService.updateMassage(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Massage Slot Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getMassageList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageSlotConstants.UPDATE_MASSAGE_SLOT_REQUEST } }
    function success(users) { return { type: massageSlotConstants.UPDATE_MASSAGE_SLOT_SUCCESS, users } }
    function failure(error) { return { type: massageSlotConstants.UPDATE_MASSAGE_SLOT_FAILURE, error } }
}
function getAllmassage() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        massageService.getAllmassage()
            .then(
                users => {
                    console.log("getAllmassage $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.GETALL_MASSAGE_REQUEST } }
    function success(users) { return { type: massageConstants.GETALL_MASSAGE_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.GETALL_MASSAGE_FAILURE, error } }
}
function getMassageSlotSlotUserById() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        massageSlotService.getMassageSlotSlotUserById()
            .then(
                users => {
                    console.log("getMassageSlotSlotUserById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageSlotConstants.GET_ID_MASSAGE_SLOT_REQUEST } }
    function success(users) { return { type: massageSlotConstants.GET_ID_MASSAGE_SLOT_SUCCESS, users } }
    function failure(error) { return { type: massageSlotConstants.GET_ID_MASSAGE_SLOT_FAILURE, error } }
}
function getMassageList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        massageSlotService.getMassageList(data)
            .then(
                users => {
                    console.log("getMassageList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageSlotConstants.GET_LIST_MASSAGE_SLOT_REQUEST } }
    function success(users) { return { type: massageSlotConstants.GET_LIST_MASSAGE_SLOT_SUCCESS, users } }
    function failure(error) { return { type: massageSlotConstants.GET_LIST_MASSAGE_SLOT_FAILURE, error } }
}
function getShowMassageBookSlotList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        massageSlotService.getShowMassageBookSlotList(data)
            .then(
                users => {
                    console.log("getShowMassageBookSlotList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageSlotConstants.GET_LIST_MASSAGE_BOOK_REQUEST } }
    function success(users) { return { type: massageSlotConstants.GET_LIST_MASSAGE_BOOK_SUCCESS, users } }
    function failure(error) { return { type: massageSlotConstants.GET_LIST_MASSAGE_BOOK_FAILURE, error } }
}


function deleteMassage(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        massageSlotService.deleteMassage(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getMassageList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageSlotConstants.DELETE_MASSAGE_SLOT_REQUEST } }
    function success(users) { return { type: massageSlotConstants.DELETE_MASSAGE_SLOT_SUCCESS, users } }
    function failure(error) { return { type: massageSlotConstants.DELETE_MASSAGE_SLOT_FAILURE, error } }
}
function updateMassageStatus(data, paginationData) {

    return dispatch => {
        dispatch(request());
        massageSlotService.updateMassageStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getMassageList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageSlotConstants.DISABLE_MASSAGE_SLOT_REQUEST } }
    function success(users) { return { type: massageSlotConstants.DISABLE_MASSAGE_SLOT_SUCCESS, users } }
    function failure(error) { return { type: massageSlotConstants.DISABLE_MASSAGE_SLOT_FAILURE, error } }
}
function rejectBookMassage(data, paginationData) {

    return dispatch => {
        dispatch(request());
        massageSlotService.rejectBookMassage(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getShowMassageBookSlotList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageSlotConstants.DISABLE_MASSAGE_BOOK_REQUEST } }
    function success(users) { return { type: massageSlotConstants.DISABLE_MASSAGE_BOOK_SUCCESS, users } }
    function failure(error) { return { type: massageSlotConstants.DISABLE_MASSAGE_BOOK_FAILURE, error } }
}
