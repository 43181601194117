import {
  bookingListConstants
} from '../_constants';

export function bookingList(state = {}, action) {
  // console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case bookingListConstants.GET_ID_BOOKINGLIST_REQUEST:
      return {
        ...state
      };
    case bookingListConstants.GET_ID_BOOKINGLIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllPlotList: action.users.getAllPlotList,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case bookingListConstants.GET_ID_BOOKINGLIST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case bookingListConstants.ADD_BOOKINGLIST_REQUEST:
      return {
        ...state
      };
    case bookingListConstants.ADD_BOOKINGLIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case bookingListConstants.ADD_BOOKINGLIST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case bookingListConstants.GET_LIST_BOOKINGLIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bookingListConstants.GET_LIST_BOOKINGLIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getBookingList: action.users.getBookingList.list,
        total: action.users.getBookingList.total
      };
    case bookingListConstants.GET_LIST_BOOKINGLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case bookingListConstants.GET_LIST_BOOKINGLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case bookingListConstants.GETALL_BOOKINGLIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bookingListConstants.GETALL_BOOKINGLIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allrestaurent: action.users.getRestaurantUserById,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case bookingListConstants.GETALL_BOOKINGLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case bookingListConstants.UPDATE_BOOKINGLIST_REQUEST:
      return {
        ...state
      };
    case bookingListConstants.UPDATE_BOOKINGLIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case bookingListConstants.UPDATE_BOOKINGLIST_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case bookingListConstants.UPDATE_BOOKINGLIST_PASSWORD_REQUEST:
      return {
        ...state
      };
    case bookingListConstants.UPDATE_BOOKINGLIST_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case bookingListConstants.UPDATE_BOOKINGLIST_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case bookingListConstants.DELETE_BOOKINGLIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bookingListConstants.DELETE_BOOKINGLIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case bookingListConstants.DELETE_BOOKINGLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case bookingListConstants.DISABLE_BOOKINGLIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bookingListConstants.DISABLE_BOOKINGLIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case bookingListConstants.DISABLE_BOOKINGLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case bookingListConstants.UN_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bookingListConstants.UN_BOOK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case bookingListConstants.UN_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case bookingListConstants.UPDATE_ALL_STATUS_UN_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bookingListConstants.UPDATE_ALL_STATUS_UN_BOOK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case bookingListConstants.UPDATE_ALL_STATUS_UN_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}