import { userConstants, massageConstants, emsSlotConstants } from '../_constants';
import { userService, massageService, emsSlotService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const emsSlotActions = {

    login,
    logout,
    updateEmsStatus,
    getAllmassage,
    createEms,
    updateEms,
    getShowEmsBookSlotList,
    getEmsList,
    deleteEms,
    uploadImageClear,
    getEmsSlotUserById,
    rejectBookEms,
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createEms(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        emsSlotService.createEms(data)
            .then(

                users => {
                    console.log("users___________users:::", users);
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("EmsSlot Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getEmsList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsSlotConstants.ADD_EMS_SLOT_REQUEST } }
    function success(users) { return { type: emsSlotConstants.ADD_EMS_SLOT_SUCCESS, users } }
    function failure(error) { return { type: emsSlotConstants.ADD_EMS_SLOT_FAILURE, error } }
}
function updateEms(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        emsSlotService.updateEms(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("EMS Slot Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getEmsList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsSlotConstants.UPDATE_EMS_SLOT_REQUEST } }
    function success(users) { return { type: emsSlotConstants.UPDATE_EMS_SLOT_SUCCESS, users } }
    function failure(error) { return { type: emsSlotConstants.UPDATE_EMS_SLOT_FAILURE, error } }
}
function getAllmassage() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        massageService.getAllmassage()
            .then(
                users => {
                    console.log("getAllmassage $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: massageConstants.GETALL_MASSAGE_REQUEST } }
    function success(users) { return { type: massageConstants.GETALL_MASSAGE_SUCCESS, users } }
    function failure(error) { return { type: massageConstants.GETALL_MASSAGE_FAILURE, error } }
}
function getEmsSlotUserById() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        emsSlotService.getEmsSlotUserById()
            .then(
                users => {
                    console.log("getEmsSlotUserById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsSlotConstants.GET_ID_EMS_SLOT_REQUEST } }
    function success(users) { return { type: emsSlotConstants.GET_ID_EMS_SLOT_SUCCESS, users } }
    function failure(error) { return { type: emsSlotConstants.GET_ID_EMS_SLOT_FAILURE, error } }
}
function getEmsList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        emsSlotService.getEmsList(data)
            .then(
                users => {
                    console.log("getEmsList $$$$ action:", users);
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsSlotConstants.GET_LIST_EMS_SLOT_REQUEST } }
    function success(users) { return { type: emsSlotConstants.GET_LIST_EMS_SLOT_SUCCESS, users } }
    function failure(error) { return { type: emsSlotConstants.GET_LIST_EMS_SLOT_FAILURE, error } }
}
function getShowEmsBookSlotList(data) {
    console.log("data m kya aa rha h::action:::getShowEmsBookSlot", data);
    return dispatch => {
        dispatch(request());
        emsSlotService.getShowEmsBookSlotList(data)
            .then(
                users => {
                    console.log("getShowEmsBookSlotList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsSlotConstants.GET_LIST_EMS_BOOK_REQUEST } }
    function success(users) { return { type: emsSlotConstants.GET_LIST_EMS_BOOK_SUCCESS, users } }
    function failure(error) { return { type: emsSlotConstants.GET_LIST_EMS_BOOK_FAILURE, error } }
}


function deleteEms(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        emsSlotService.deleteEms(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getEmsList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsSlotConstants.DELETE_EMS_SLOT_REQUEST } }
    function success(users) { return { type: emsSlotConstants.DELETE_EMS_SLOT_SUCCESS, users } }
    function failure(error) { return { type: emsSlotConstants.DELETE_EMS_SLOT_FAILURE, error } }
}
function updateEmsStatus(data, paginationData) {

    return dispatch => {
        dispatch(request());
        emsSlotService.updateEmsStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getEmsList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsSlotConstants.DISABLE_EMS_SLOT_REQUEST } }
    function success(users) { return { type: emsSlotConstants.DISABLE_EMS_SLOT_SUCCESS, users } }
    function failure(error) { return { type: emsSlotConstants.DISABLE_EMS_SLOT_FAILURE, error } }
}
function rejectBookEms(data, paginationData) {

    return dispatch => {
        dispatch(request());
        emsSlotService.rejectBookEms(data)
            .then(
                users => {
                    console.log('usersusersusersusersusersusers', users);
                    dispatch(success(users));
                    dispatch(this.getShowEmsBookSlotList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: emsSlotConstants.DISABLE_EMS_BOOK_REQUEST } }
    function success(users) { return { type: emsSlotConstants.DISABLE_EMS_BOOK_SUCCESS, users } }
    function failure(error) { return { type: emsSlotConstants.DISABLE_EMS_BOOK_FAILURE, error } }
}
