
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const userService = {
    login,
    getProfile,
    logout,
    changePassword,
    registerUserAdmin,
    getUserActive,
    getUserDeactive,
    getEmsSlotBookingByUser,
    getUserParkingList,
    getTxListByUser,
    getOrderListByUser,
    getLoginHistoryListByUserId,
    getTrainerSlotBookingByUserId,
    getMassageSlotBookingByUserId,
    getGroupClassSlotBookingByUserId,
    disableUser,
    deleteUser,
    verifyEmail,
    loginToThisAccount,
    getAllSport,
    getAllSeries,
    getAllMatch,
    createSport,
    updateUserpassword,
    createSeries,
    createMatch,
    getUserProfile,
    getAllMatchActive,
    updateMatchStatus,
    uploadImage,
    updateMatchMarketId,
    getOdds,
    getMatchBymatchId,
    updateMatchTeamImage,
    AddUserBalance,
    uploadVideo,
    deductUserBalance,
    getUserListIsDisable,
    getTicketList,
    deleteTicket,
    disableTicket,
    approveTicket,
    getUserList,
    updateUser,
    updateUserStatusAdmin


};

function updateUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateUser: data.data
            }
            console.log("i am in service updateUser ::", userObj);

            return userObj;
        });
}

function getUserList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    console.log('requestOptionsrequestOptionsrequestOptionsrequestOptions', requestOptions);
    return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log("i am in service getUserList ::", data.data);

            let userObj = {
                getUserList: data.data
            }
            console.log("i am in service getUserList ::", userObj);

            return userObj;
        });
}

function getTicketList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTicketList `, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTicketList: data.data
            }
            console.log("i am in service getTicketList  ::", userObj);

            return userObj;
        });
}
function deleteTicket(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteTicket `, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteTicket: data.data
            }
            console.log("i am in service deleteTicket  ::", userObj);

            return userObj;
        });
}
function disableTicket(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTicketStatus `, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                disableTicket: data.data
            }
            console.log("i am in service disableTicket  ::", userObj);

            return userObj;
        });
}
function updateUserStatusAdmin(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUserStatusAdmin `, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateUserStatusAdmin: data.data
            }
            console.log("i am in service updateUserStatusAdmin  ::", userObj);

            return userObj;
        });
}
function approveTicket(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/approveTicket`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                approveTicket: data.data
            }
            console.log("i am in service approveTicket ::", userObj);

            return userObj;
        });
}


function updateMatchMarketId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatchMarketId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateMatchMarketIdItems: data.data
            }
            console.log("i am in service updateMatchMarketId ::", userObj);

            return userObj;
        });
}


// function updateUserPassword(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateUserPassword`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 updateUserpasswordItem: data.data
//             }
//             console.log("i am in service updateUserPassword ::", userObj);

//             return userObj;
//         });
// }



function deductUserBalance(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deductUserBalance`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deductUserBalance: data.data
            }
            console.log("i am in service deductUserBalance ::", userObj);

            return userObj;
        });
}
function AddUserBalance(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/AddUserBalance`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                AddUserBalance: data.data
            }
            console.log("i am in service AddUserBalance ::", userObj);

            return userObj;
        });
}
function createMatch(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createMatch`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createSerie: data.data
            }
            console.log("i am in service createMatchcreateMatchcreateMatchcreateMatch ::", userObj);

            return userObj;
        });
}

function uploadVideo(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                videoDetails: res.data
            }
            console.log("servive_uploadImage", userObj);
            return userObj;
        });
}

function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res.data
            }
            console.log("servive_uploadImage", userObj);
            return userObj;
        });
}

function createSeries(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createSeries`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createSerie: data.data
            }
            console.log("i am in service createSeriescreateSeriescreateSeriescreateSeries ::", userObj);

            return userObj;
        });
}

function updateMatchStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatchStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateMatchStatus: data.data
            }
            // console.log("i am in service updateMatchStatus ::", userObj);

            return userObj;
        });
}
function createSport(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createSport`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createMatch: data.data
            }
            // console.log("i am in service createSportcreateSportcreateSportcreateSport ::", userObj);

            return userObj;
        });
}
function updateUserpassword(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUserpassword`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateUserpassword: data.data
            }
            // console.log("i am in service updateUserpasswordupdateUserpasswordupdateUserpasswordupdateUserpassword ::", userObj);

            return userObj;
        });
}

function getAllMatchActive(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "GET",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllMatchActive`, requestOptions)
        // return fetch(CONST.BACKEND_URL + `/getAllMatchActive?sportId=4&seriesId=11365612`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                allMatchActive: data.data
            }
            console.log("i am in service getAllMatchActive:::::::::::", userObj);

            return userObj;
        });
}
function getAllMatch(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "GET",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllMatch?sportId=${data.sportId}&seriesId=${data.seriesId}`, requestOptions)
        // return fetch(CONST.BACKEND_URL + `/getAllMatch?sportId=4&seriesId=11365612`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                allMatch: data.data
            }
            console.log("i am in service getAllMatchgetAllMatchgetAllMatchgetAllMatch:::::::::::", userObj);

            return userObj;
        });
}
function updateMatchTeamImage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatchTeamImage`, requestOptions)
        // return fetch(CONST.BACKEND_URL + `/updateMatchTeamImage?matchId=4&seriesId=11365612`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateMatchTeamImage: data
            }
            console.log("i am in service updateMatchTeamImage:::::::::::123", userObj);

            return userObj;
        });
}

function getUserProfile(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserProfile`, requestOptions)
        // return fetch(CONST.BACKEND_URL + `/getAllMarket?matchId=4&seriesId=11365612`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserProfile: data.data
            }
            console.log("i am in service getAllMarket:::::::::::123", userObj);

            return userObj;
        });
}

function getMatchBymatchId(data) {
    // console.log("getMatchBymatchIdSERVICE1", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getMatchBymatchId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getMatchBymatchId: data
            }
            // console.log("i am in service getMatchBymatchId ::", userObj);

            return userObj;
        });
}
function getOdds(marketId) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "GET",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getOdds?marketId=${marketId}`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getallOdds: data
            }
            // console.log("i am in service getOdds ::", userObj);

            return userObj;
        });
}
function getAllSeries(sportId) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "GET",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllSeries?sportId=${sportId}`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                allSeries: data.data
            }
            // console.log("i am in service getAllSeriesgetAllSeriesgetAllSeries ::", userObj);

            return userObj;
        });
}

function getAllSport(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "GET",
        headers: header,
    }
    return fetch(CONST.BACKEND_URL + `/getActiveSport`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                AllSport: data.data
            }
            // console.log("i am in service allSportallSportallSportallSport ::", userObj);

            return userObj;
        });
}


function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        // "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    console.log("requestOptions@@@@@@@@@@@@@@@@@@@@@@@@@@", requestOptions);

    return fetch(CONST.BACKEND_URL + `/adminLogin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }
            return userObj;
        });
}

function verifyEmail(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/validateOtpAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                verifyEmail: data.data
            }
            // console.log("i am in service''...>> verifyEmail ::", userObj);

            return userObj;
        });
}
function deleteUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteUser: data.data
            }
            // console.log("i am in service''...>> deleteUser ::", userObj);

            return userObj;
        });
}
function disableUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUserStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                disableUser: data.data
            }
            // console.log("i am in service''...>> disableUser ::", userObj);

            return userObj;
        });
}
function getUserActive(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserActive: data.data
            }
            // console.log("i am in service getUserActive ::", userObj);

            return userObj;
        });
}
function getTxListByUser(data) {
    console.log('data___data____data:::????', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTxListByUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTxListByUser: data.data
            }
            console.log("i am in service getTxListByUser111111111 ::", userObj);

            return userObj;
        });
}
function getUserParkingList(data) {
    console.log('data___data____data:::????', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserParkingList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserParkingList: data.data
            }
            console.log("i am in service getUserParkingList111111111 ::", userObj);

            return userObj;
        });
}
function getOrderListByUser(data) {
    console.log('data___data____data:::????', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getOrderListByUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getOrderListByUser: data.data
            }
            console.log("i am in service getOrderListByUser111111111 ::", userObj);

            return userObj;
        });
}
function getLoginHistoryListByUserId(data) {
    console.log('data___data____data:::????', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getLoginHistoryListByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getLoginHistoryListByUserId: data.data
            }
            console.log("i am in service getLoginHistoryListByUserId111111111 ::", userObj);

            return userObj;
        });
}
function getEmsSlotBookingByUser(data) {
    console.log('data___data____data:::????', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getEmsSlotBookingByUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getEmsSlotBookingByUser: data.data
            }
            console.log("i am in service getEmsSlotBookingByUser111111111 ::", userObj);

            return userObj;
        });
}
function getMassageSlotBookingByUserId(data) {
    console.log('data___data____data:::????', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getMassageSlotBookingByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getMassageSlotBookingByUserId: data.data
            }
            console.log("i am in service getMassageSlotBookingByUserId111111111 ::", userObj);

            return userObj;
        });
}
function getGroupClassSlotBookingByUserId(data) {
    console.log('data___data____data:::????', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getGroupClassSlotBookingByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getGroupClassSlotBookingByUserId: data.data
            }
            console.log("i am in service getGroupClassSlotBookingByUserId111111111 ::", userObj);

            return userObj;
        });
}
function getTrainerSlotBookingByUserId(data) {
    console.log('data___data____data:::????', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTrainerSlotBookingByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTrainerSlotBookingByUserId: data.data
            }
            console.log("i am in service getTrainerSlotBookingByUserId111111111 ::", userObj);

            return userObj;
        });
}
function getUserDeactive(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserDeactive: data.data
            }
            // console.log("i am in service getUserDeactive ::", userObj);

            return userObj;
        });
}

function registerUserAdmin(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createUser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createUser: data.data
            }
            // console.log("I am in service createUser", userObj);

            return userObj;
        });
}
function changePassword(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/changePassword`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                changePassword: data.data
            }
            // console.log("I am in service UpdatePasswordUser", userObj);

            return userObj;
        });
}

function loginToThisAccount(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/loginToUserAccount`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                loginToThisAccount: data.data
            }
            // console.log("i am in service loginToThisAccount {{{{{{{}}}}}}}::", userObj);

            return userObj;
        });
}

function getProfile(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getProfile: data.data
            }
            // console.log("i am in service loginToThisAccount {{{{{{{}}}}}}}::", userObj);

            return userObj;
        });
}
function getUserListIsDisable(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUserStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log("122222222222222112121212121212121212122", data);
            let userObj = {
                updateUserStatus: data.data
            }
            console.log("i am in service loginToThisAccount {{{{{{{}}}}}}}::", userObj);

            return userObj;
        });
}



function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}