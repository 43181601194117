import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Header from "../Header";
// import Dashboard from "../../pages/dashboard";
import Sidebar from "../Sidebar";
import User from "../../pages/User/User";
import moreDetails from "../../pages/User/moreDetails";
import SendNotification from "../../components/SendNotification/SendNotification";
import restaurent from "../../pages/Restaurent/restaurent";
import massage from "../../pages/Massage/massage";
import valet from "../../pages/Valet/valet";
import emsBookList from "../../pages/EMSSlot/emsBookList";
import parking from "../../pages/Parking/parking";
import Support from "../../pages/Support/Support";
import ems from "../../pages/EMS/ems";
import classes from "../../pages/Classes/classes";
import emsSlot from "../../pages/EMSSlot/emsSlot";
import massageSlot from "../../pages/massageSlot/massageSlot";
import massageBookList from "../../pages/massageSlot/massageBookList";
import groupList from "../../pages/groupList/groupList";
import plotList from "../../pages/plotList/plot";
import sectionList from "../../pages/section/sectionList";
import groupClassCategory from "../../pages/groupClassCategory/groupClassCategory";
import classBookList from "../../pages/Classes/classBookList";
import classBookListDetail from "../../pages/Classes/classBookListDetail";
import deactiveUser from "../../pages/User/deactiveUser";
import bookingList from "../../pages/bookingList/bookingList";
function Layout(props) {
  return (
    <div >
      <>
        <div className="overflow-hidden">
          <div className="bg-gray-100 ">
            <div className="flex flex-col justify-center min-h-screen">
              <div className="flex h-screen overflow-hidden" >
                <Sidebar />
                <div className="flex flex-col flex-1 w-0 overflow-hidden">
                  <Header history={props.history} />
                  <Switch>
                    {/* <Route path="/app/dashboard" component={withRouter(Dashboard)} /> */}
                    <Route path="/app/user" component={withRouter(User)} />
                    <Route path="/app/moreDetails/:id" component={withRouter(moreDetails)} />
                    <Route path="/app/sendNotification" component={withRouter(SendNotification)} />
                    <Route path="/app/emsBookList" component={withRouter(emsBookList)} />
                    <Route path="/app/restaurent" component={withRouter(restaurent)} />
                    <Route path="/app/massage" component={withRouter(massage)} />
                    <Route path="/app/valet" component={withRouter(valet)} />
                    <Route path="/app/ems" component={withRouter(ems)} />
                    <Route path="/app/classes" component={withRouter(classes)} />
                    <Route path="/app/emsSlot" component={withRouter(emsSlot)} />
                    <Route path="/app/massageSlot" component={withRouter(massageSlot)} />
                    <Route path="/app/massageBookList" component={withRouter(massageBookList)} />
                    <Route path="/app/classBookList" component={withRouter(classBookList)} />
                    <Route path="/app/classBookListDetail/:slotId/:date" component={withRouter(classBookListDetail)} />

                    <Route path="/app/parking" component={withRouter(parking)} />
                    <Route path="/app/support" component={withRouter(Support)} />
                    <Route path="/app/groupList" component={withRouter(groupList)} />
                    <Route path="/app/plotList" component={withRouter(plotList)} />
                    <Route path="/app/section" component={withRouter(sectionList)} />
                    <Route path="/app/groupClassCategory" component={withRouter(groupClassCategory)} />
                    <Route path="/app/deactiveUser" component={withRouter(deactiveUser)} />
                    <Route path="/app/bookingList" component={withRouter(bookingList)} />

                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
