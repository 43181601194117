import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './style.css';
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import moment from 'moment';
import AddBalanceModal from './components/AddBalanceModal/AddBalanceModal';
import DeductBalanceModal from './components/AddBalanceModal/DeductBalanceModal';
// import DeductBalanceModal from './components/DeductBalanceModal/DeductBalanceModal';



class moreDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 10,
      pageNo: 1,
      keyWord: '',
      offset: 0,
      AddBalanceModal: false,
      emsfield: true,
      massagefield: false,
      attendencefield: false,
      orderfield: false,
      classfield: false,
      loginHistoryfield: false,
      parkingfield: false,
      txfield: false,
      DeductBalanceModal: false,
      DeductBalanceModal: false,
      fieldsUserUpdate: {},
      fieldsUser: {},
      errorsUpdateQuote: {},
      errorsUpdate: {},
      balance: ''

    }
  }




  componentDidMount() {
    let data = {
      "userId": this.props.match.params.id

    }
    let reqData = {
      "userId": this.props.match.params.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size

    }
    console.log('reqData_______1111111111_____reqData', reqData);
    this.props.dispatch(userActions.getOrderListByUser(reqData))
    this.props.dispatch(userActions.getUserParkingList(reqData))
    this.props.dispatch(userActions.getTxListByUser(reqData))
    this.props.dispatch(userActions.getEmsSlotBookingByUser(reqData))
    this.props.dispatch(userActions.getMassageSlotBookingByUserId(reqData))
    this.props.dispatch(userActions.getGroupClassSlotBookingByUserId(reqData))
    this.props.dispatch(userActions.getUserProfile(data))
    this.props.dispatch(userActions.getLoginHistoryListByUserId(data))

  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      console.log('999999999999');
      return {
        ...nextProps,
        fieldsUserUpdate: {},
        fieldsUser: {},
        errorsUpdateQuote: {},
        errorsUpdate: {},
        AddBalanceModal: false,
        addQuoteModal: false,
        DeductBalanceModal: false,
        balance: ''

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClickEms = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "userId": this.props.match.params.id,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getEmsSlotBookingByUser(datatemp))
    // this.props.dispatch(userActions.getMassageSlotBookingByUserId(datatemp))
    // this.props.dispatch(userActions.getGroupClassSlotBookingByUserId(datatemp))
    // this.props.dispatch(userActions.getTxListByUser(datatemp))
  }
  handlePageClickTx = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "userId": this.props.match.params.id,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    // this.props.dispatch(userActions.getEmsSlotBookingByUser(datatemp))
    // this.props.dispatch(userActions.getMassageSlotBookingByUserId(datatemp))
    // this.props.dispatch(userActions.getGroupClassSlotBookingByUserId(datatemp))
    this.props.dispatch(userActions.getTxListByUser(datatemp))
  }
  handlePageClickMassage = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "userId": this.props.match.params.id,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    // this.props.dispatch(userActions.getEmsSlotBookingByUser(datatemp))
    this.props.dispatch(userActions.getMassageSlotBookingByUserId(datatemp))
    // this.props.dispatch(userActions.getGroupClassSlotBookingByUserId(datatemp))
    // this.props.dispatch(userActions.getTxListByUser(datatemp))
  }
  handlePageClickClass = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "userId": this.props.match.params.id,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    // this.props.dispatch(userActions.getEmsSlotBookingByUser(datatemp))
    // this.props.dispatch(userActions.getMassageSlotBookingByUserId(datatemp))
    this.props.dispatch(userActions.getGroupClassSlotBookingByUserId(datatemp))
    // this.props.dispatch(userActions.getTxListByUser(datatemp))
  }

  handleOpenCreateModalUpdatePassword = (data) => {
    this.setState({ AddBalanceModal: true, fieldsUserUpdate: { id: this.props.match.params.id } });
  }
  handleOpenCreateModalPassword = (data) => {
    this.setState({ DeductBalanceModal: true, fieldsUser: { id: this.props.match.params.id } });
  }


  // handlehideAndShow = (e) => {
  //   console.log("datadatadatadatadatadatadatadatadatadata", e.data);
  //   if (e && e.data == 1) {
  //     this.setState({ emsfield: true, massagefield: false, classfield: false, parkingfield: false, txfield: false, orderfield: false, loginHistoryfield: false, attendencefield: false })
  //   }
  //   if (e && e.data == 2) {
  //     this.setState({ massagefield: true, emsfield: false, classfield: false, parkingfield: false, txfield: false, orderfield: false, loginHistoryfield: false , attendencefield: false  })
  //   }
  //   if (e && e.data == 3) {
  //     this.setState({ classfield: true, emsfield: false, massagefield: false, parkingfield: false, txfield: false, orderfield: false, loginHistoryfield: false, attendencefield: false  })
  //   }
  //   if (e && e.data == 4) {
  //     this.setState({ parkingfield: true, emsfield: false, massagefield: false, txfield: false, orderfield: false, loginHistoryfield: false, classfield: false, attendencefield: false  })
  //   }
  //   if (e && e.data == 5) {
  //     this.setState({ txfield: true, parkingfield: false, emsfield: false, massagefield: false, orderfield: false, loginHistoryfield: false, classfield: false, attendencefield: false  })
  //   }
  //   if (e && e.data == 6) {
  //     this.setState({ orderfield: true, parkingfield: false, emsfield: false, massagefield: false, txfield: false, loginHistoryfield: false, classfield: false, attendencefield: false  })
  //   }
  //   if (e && e.data == 7) {
  //     this.setState({ loginHistoryfield: true, parkingfield: false, emsfield: false, massagefield: false, txfield: false, orderfield: false, classfield: false, attendencefield: false  })
  //   }
  //   if (e && e.data == 8) {
  //     this.setState({ loginHistoryfield: false, parkingfield: false, emsfield: false, massagefield: false, txfield: false, orderfield: false, classfield: false, attendencefield: true  })
  //   }
  // }


  handleUpdatePasswordHideModal = () => {
    this.setState({ AddBalanceModal: false });
  }
  handleUpdateHideModal = () => {
    this.setState({ DeductBalanceModal: false });
  }




  addBalanceSubmit = () => {


    if (this.handleValidationUpdateUser()) {
      let reqData = {
        "id": this.state.fieldsUserUpdate.id,
        "balance": parseInt(this.state.balance),

      }
      let paginationData = {
        "userId": this.props.match.params.id,
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>", reqData);

      this.props.dispatch(userActions.AddUserBalance(reqData, paginationData));
      // this.setState({ balance: "" })
      // }

    }
  }
  deductBalanceSubmit = () => {


    if (this.handleValidationUpdateUserDeduct()) {
      let reqData = {
        "id": this.state.fieldsUser.id,
        "amount": parseInt(this.state.balance),

      }
      let paginationData = {
        "userId": this.props.match.params.id,
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>", reqData);

      this.props.dispatch(userActions.deductUserBalance(reqData, paginationData));
      // }

    }
  }

  handleValidationUpdateUserDeduct = () => {
    let balance = this.state.balance;
    let errorsUpdate = {};
    let formIsValid = true;

    //name
    if (!balance || balance === "") {
      formIsValid = false;
      errorsUpdate["balance"] = "Cannot be empty";
    }


    console.log("errorsUpdateerrorsUpdateerrorsUpdate", errorsUpdate);
    this.setState({ errorsUpdate: errorsUpdate });
    return formIsValid;
  }

  handleValidationUpdateUser = () => {
    let balance = this.state.balance;
    let errorsUpdateQuote = {};
    let formIsValid = true;

    //name
    if (!balance || balance === "") {
      formIsValid = false;
      errorsUpdateQuote["balance"] = "Cannot be empty";
    }


    console.log("errorsUpdateQuoteerrorsUpdateQuoteerrorsUpdateQuote", errorsUpdateQuote);
    this.setState({ errorsUpdateQuote: errorsUpdateQuote });
    return formIsValid;
  }

  setBalance = (e) => {
    e.preventDefault();
    let value = e.target.value;
    // console.log('value__________', value);
    this.setState({ balance: value })
  }

  render() {

    let { users } = this.props;
    let { loading, total, emsSlotBookData, massageSlotBookData, classesSlotBookData, userParkingItems, userParkingTotal, userOrderItems, userOrderTotal, userTxItems, userTxTotal, loginHistory, loginHistoryTotal } = users;
    let { getUserProfile } = users;

    // console.log('RENDER______________________________________________________', loginHistory, loginHistoryTotal);
    // console.log("render111111111111111", getUserProfile);
    // console.log('userTxItems::::::::::::::', userTxItems);
    // console.log('massageSlotBookData::::::::::::::', massageSlotBookData);
    // console.log('classesSlotBookData::::::::::::::', classesSlotBookData);
    // console.log("render______this.state.fieldsUserUpdate::::", total);


    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>


        <div className="flex flex-col flex-1 overflow-hidden overflow-x-auto overflow-y-auto">
          <main className="relative flex-1 ">

            <div className='flex w-full px-4 py-4 bg-gray-100 shadow-lg lg:space-x-10 sm:space-x-2' >


              <div className='flex justify-start'>
                {/* <img src="https://play-lh.googleusercontent.com/UjaAdTYsArv7zAJbqGWjQw2ftuOtnAlvokffC3TQQ2K12mwk0YdXUF2wZBTBA2kDZIk" alt="" className='rounded-full w-44 h-44' /> */}
                <img src={getUserProfile && getUserProfile.imageLinkUrl ? getUserProfile.imageLinkUrl : "/dist/img/profile.png"} alt="" className='rounded-full w-44 h-44' />

              </div>

              <div className='items-center py-10'>
                <h2 className='px-4 text-4xl font-semibold'>{getUserProfile && getUserProfile.name ? getUserProfile.name : "xxx"}</h2>

                <div className='flex'>
                  <div>

                    <p className='px-4 py-1'>{getUserProfile && getUserProfile.email ? getUserProfile.email : "xx@gmail.com"}</p>
                    <p className='px-4 '>{getUserProfile && getUserProfile.mobile ? getUserProfile.mobile : "xxxxxxxx23"}</p>
                    <p className='px-4 '>{getUserProfile && getUserProfile.pin ? getUserProfile.pin : "xxx044"}</p>
                  </div>
                  <div>

                    <p className='px-4 py-1'>{getUserProfile && getUserProfile.gender ? getUserProfile.gender : "xx"}</p>
                    <p className='px-4 '>{getUserProfile && getUserProfile.city ? getUserProfile.city : "xx"}</p>
                    <p className='px-4 '><b>Balance : </b>{getUserProfile && getUserProfile.balance ? getUserProfile.balance : 0}</p>
                  </div>
                </div>
              </div>
            </div>


            {/* <div className='w-full px-4 py-4 bg-white shadow-lg '>
              <div className='flex justify-end px-5 space-x-4'>

                <div onClick={() => this.handleOpenCreateModalUpdatePassword()} class="relative cursor-pointer inline-block px-10 py-2 font-medium group">
                  <span class="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span class="absolute inset-0 w-full h-full bg-white border-2 border-black group-hover:bg-black"></span>
                  <span class="relative text-base text-black group-hover:text-white">Add Balance</span>
                </div>


                <div onClick={() => this.handleOpenCreateModalPassword()} class="relative cursor-pointer inline-block px-7 py-2 font-medium group">
                  <span class="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span class="absolute inset-0 w-full h-full bg-white border-2 border-black group-hover:bg-black"></span>
                  <span class="relative text-black group-hover:text-white">Deduct Balance</span>
                </div>
              </div>
            </div> */}


            <div className='flex justify-between pt-2 bg-white'>

              <div className='w-full space-x-5 ' >
                {/* <button className='px-4 py-2 text-center bg-gray-300 border border-black rounded-md'
                  onClick={() => this.handlehideAndShow({ data: 1 })}
                >EMS Slot Booking</button>
                <button className='px-4 py-2 text-center bg-gray-300 border border-black rounded-md' onClick={() => this.handlehideAndShow({ data: 2 })}>Massage Slot Booking</button>
                <button className='px-4 py-2 text-center bg-gray-300 border border-black rounded-md' onClick={() => this.handlehideAndShow({ data: 3 })}>Class Slot Booking</button>
                <button className='px-4 py-2 text-center bg-gray-300 border border-black rounded-md' onClick={() => this.handlehideAndShow({ data: 4 })}>User Parking List</button> */}

                <div onClick={() => this.handlehideAndShow({ data: 1 })} class="relative cursor-pointer inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-gray-700 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                  <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-gray-700 group-hover:h-full"></span>
                  <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">EMS Slot Booking</span>
                </div>
                <div onClick={() => this.handlehideAndShow({ data: 2 })} class="relative cursor-pointer inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-gray-700 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                  <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-gray-700 group-hover:h-full"></span>
                  <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">Massage Slot Booking</span>
                </div>

                <div onClick={() => this.handlehideAndShow({ data: 3 })} class="relative cursor-pointer inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-gray-700 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                  <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-gray-700 group-hover:h-full"></span>
                  <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">Class Slot Booking</span>
                </div>
                <div onClick={() => this.handlehideAndShow({ data: 4 })} class="relative cursor-pointer inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-gray-700 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                  <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-gray-700 group-hover:h-full"></span>
                  <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">User Parking List</span>
                </div>


                <div onClick={() => this.handlehideAndShow({ data: 5 })} class="relative cursor-pointer inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-gray-700 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                  <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-gray-700 group-hover:h-full"></span>
                  <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">User Tx List</span>
                </div>

                <div onClick={() => this.handlehideAndShow({ data: 6 })} class="relative cursor-pointer inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-gray-700 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                  <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-gray-700 group-hover:h-full"></span>
                  <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">User Order List</span>
                </div>
                <div onClick={() => this.handlehideAndShow({ data: 7 })} class="relative cursor-pointer inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-gray-700 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                  <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-gray-700 group-hover:h-full"></span>
                  <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">Login History</span>
                </div>
                <div onClick={() => this.handlehideAndShow({ data: 8 })} class="relative cursor-pointer inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-gray-700 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                  <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-gray-700 group-hover:h-full"></span>
                  <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white"> 
Attendance History</span>
                </div>
              </div>
            </div>
            {this.state && this.state.emsfield == true ?
              <section className="p-6 space-y-6 bg-white border rounded-md">

                <div className="flex-wrap justify-between w-full item-center">
                  <div className="">
                    <h3 className="text-xl font-semibold ">EMS Slot Booking</h3>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Slot No</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Price</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Starting Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Ending Time</th>

                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>


                              </tr>
                            </thead>

                            <tbody>

                              {
                                emsSlotBookData && emsSlotBookData.list && emsSlotBookData.list.length > 0 ?
                                  emsSlotBookData.list.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.slotId && element.slotId.slotNo ? element.slotId.slotNo : "NA"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.slotId && element.slotId.price ? element.slotId.price : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 uppercase whitespace-nowrap">{element && element.slotId && element.slotId.startingTime ? element.slotId.startingTime : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 uppercase whitespace-nowrap">{element && element.slotId && element.slotId.endingTime ? element.slotId.endingTime : "NA"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClickEms}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClickEms}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </div>
              </section> : null}
            {this.state && this.state.massagefield == true ?
              <section className="p-6 space-y-6 bg-white border rounded-md">

                <div className="flex-wrap justify-between w-full item-center">
                  <div className="">
                    <h3 className="text-xl font-semibold ">Massage Slot Booking</h3>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Slot No</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Price</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Starting Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Ending Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>


                              </tr>
                            </thead>

                            <tbody>
                              {
                                massageSlotBookData && massageSlotBookData.list && massageSlotBookData.list.length > 0 ?
                                  massageSlotBookData.list.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.slotId && element.slotId.slotNo ? element.slotId.slotNo : "NA"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.slotId && element.slotId.price ? element.slotId.price : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 uppercase whitespace-nowrap">{element && element.slotId && element.slotId.startingTime ? element.slotId.startingTime : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 uppercase whitespace-nowrap">{element && element.slotId && element.slotId.endingTime ? element.slotId.endingTime : "NA"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClickMassage}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClickMassage}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </div>
              </section>
              : null}

            {this.state && this.state.classfield == true ?
              <section className="p-6 space-y-6 bg-white border rounded-md">

                <div className="flex-wrap justify-between w-full item-center">
                  <div className="">
                    <h3 className="text-xl font-semibold ">Class Slot Booking</h3>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Slot No</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Price</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Starting Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Ending Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>


                              </tr>
                            </thead>

                            <tbody>
                              {
                                classesSlotBookData && classesSlotBookData.list && classesSlotBookData.list.length > 0 ?
                                  classesSlotBookData.list.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.slotId && element.slotId.slotNo ? element.slotId.slotNo : "NA"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.slotId && element.slotId.price ? element.slotId.price : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 uppercase whitespace-nowrap">{element && element.slotId && element.slotId.startingTime ? element.slotId.startingTime : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 uppercase whitespace-nowrap">{element && element.slotId && element.slotId.endingTime ? element.slotId.endingTime : "NA"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClickClass}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClickClass}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </div>
              </section> : null}

            {this.state && this.state.parkingfield == true ?
              <section className="p-6 space-y-6 bg-white border rounded-md">

                <div className="flex-wrap justify-between w-full item-center">
                  <div className="">
                    <h3 className="text-xl font-semibold ">User Parking List</h3>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Parked</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Valet Name</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Valet Mobile</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Image</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>


                              </tr>
                            </thead>

                            <tbody>
                              {
                                userParkingItems && userParkingItems.list && userParkingItems.list.length > 0 ?
                                  userParkingItems.list.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.isParked == true ? "Yes" : "NA"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.valetId && element.valetId.name ? element.valetId.name : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.valetId && element.valetId.mobile ? element.valetId.mobile : "NA"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <div className=''>
                                          <img className="object-cover h-10 rounded-sm w-14" src={element && element.imageLinkUrl ? element.imageLinkUrl : "/dist/img/profile.png"} alt="not found" />
                                        </div>
                                      </td>


                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            userParkingTotal && userParkingTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={userParkingTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            userParkingTotal && userParkingTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={userParkingTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </div>
              </section> : null}

            {this.state && this.state.txfield == true ?
              <section className="p-6 space-y-6 bg-white border rounded-md">

                <div className="flex-wrap justify-between w-full item-center">
                  <div className="">
                    <h3 className="text-xl font-semibold ">User Tx List</h3>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Tx Type</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Amount</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>


                              </tr>
                            </thead>

                            <tbody>
                              {
                                userTxItems && userTxItems.list && userTxItems.list.length > 0 ?
                                  userTxItems.list.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.txType ? element.txType : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.amount ? element.amount : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            userTxTotal && userTxTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={userTxTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClickTx}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            userTxTotal && userTxTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={userTxTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClickTx}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </div>
              </section>
              : null}

            {this.state && this.state.orderfield == true ?
              <section className="p-6 space-y-6 bg-white border rounded-md">

                <div className="flex-wrap justify-between w-full item-center">
                  <div className="">
                    <h3 className="text-xl font-semibold ">User Order List</h3>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Order Id</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Total Price</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Status</th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Product Description</th>
                              <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Image</th> */}
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>


                              </tr>
                            </thead>

                            <tbody>
                              {
                                userOrderItems && userOrderItems.list && userOrderItems.list.length > 0 ?
                                  userOrderItems.list.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.orderId ? element.orderId : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.totalPrice ? element.totalPrice : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.status ? element.status : "NA"}</td>



                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.productId && element.productId.des ? element.productId.des : "NA"}</td> */}
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 uppercase whitespace-nowrap">{element && element.productId.imageLinkUrl && element.productId.imageLinkUrl ? element.productId.imageLinkUrl : "NA"}</td> */}


                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            userOrderTotal && userOrderTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={userOrderTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            userOrderTotal && userOrderTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={userOrderTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </div>
              </section>
              : null}
            {this.state && this.state.loginHistoryfield == true ?
              <section className="p-6 space-y-6 bg-white border rounded-md">

                <div className="flex-wrap justify-between w-full item-center">
                  <div className="">
                    <h3 className="text-xl font-semibold ">Login History</h3>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">IP Address</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Description</th>

                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>


                              </tr>
                            </thead>

                            <tbody>
                              {
                                loginHistory && loginHistory.list && loginHistory.list.length > 0 ?
                                  loginHistory.list.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.ipAddress ? element.ipAddress : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.desc ? element.desc : "NA"}</td>

                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.productId && element.productId.name ? element.productId.name : "NA"}</td> */}

                                      {/* <td className="px-6 py-3 text-sm text-gray-600 uppercase whitespace-nowrap">{element && element.productId.imageLinkUrl && element.productId.imageLinkUrl ? element.productId.imageLinkUrl : "NA"}</td> */}


                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            loginHistoryTotal && loginHistoryTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={loginHistoryTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            loginHistoryTotal && loginHistoryTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={loginHistoryTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </div>
              </section>
              : null}
            {this.state && this.state.attendencefield == true ?
              <section className="p-6 space-y-6 bg-white border rounded-md">

                <div className="flex-wrap justify-between w-full item-center">
                  <div className="">
                    <h3 className="text-xl font-semibold ">Attendance History</h3>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Attendance</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Day</th>

                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>


                              </tr>
                            </thead>

                            <tbody>
                              {
                                loginHistory && loginHistory.list && loginHistory.list.length > 0 ?
                                  loginHistory.list.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.ipAddress ? element.ipAddress : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.desc ? element.desc : "NA"}</td>

                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element && element.productId && element.productId.name ? element.productId.name : "NA"}</td> */}

                                      {/* <td className="px-6 py-3 text-sm text-gray-600 uppercase whitespace-nowrap">{element && element.productId.imageLinkUrl && element.productId.imageLinkUrl ? element.productId.imageLinkUrl : "NA"}</td> */}


                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            loginHistoryTotal && loginHistoryTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={loginHistoryTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            loginHistoryTotal && loginHistoryTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={loginHistoryTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </div>
              </section>
              : null}
          </main>
        </div>


        <AddBalanceModal
          AddBalanceModal={this.state.AddBalanceModal}
          fieldsUserUpdate={this.state.fieldsUserUpdate}
          balance={this.state.balance}
          errorsUpdateQuote={this.state.errorsUpdateQuote}
          inputChangeUpdateUser={this.inputChangeUpdateUser}
          setBalance={this.setBalance}
          addBalanceSubmit={this.addBalanceSubmit}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
          handleFile={this.handleFile}

        />
        <DeductBalanceModal
          DeductBalanceModal={this.state.DeductBalanceModal}
          balance={this.state.balance}
          fieldsUser={this.state.fieldsUser}
          errorsUpdate={this.state.errorsUpdate}
          inputChangeUpdateUser={this.inputChangeUpdateUser}
          setBalance={this.setBalance}
          deductBalanceSubmit={this.deductBalanceSubmit}
          handleAppSeetingHideModal={this.handleUpdateHideModal}
          handleFile={this.handleFile}

        />


      </>

    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(moreDetails);
