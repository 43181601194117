import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, classesActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BiSearch, BiSearchAlt } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import ViewMoreDetailsModal from './components/ViewMoreDetailsModal/ViewMoreDetailsModal';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";

class classBookList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClassesRowData: {},
      currentDate: 0,
      fieldsCalender: '',
      calenderData: '',
      nextData: 0,
      offset: 0,
    }
  }

  componentDidMount() {
    let temp = {
      currentDate: 0,
      nextDate: 0,
    }
    this.props.dispatch(classesActions.getShowClassesBookSlotList(temp))

  }
  static getDerivedStateFromProps(nextProps, prevState) {

    // console.log("STATIC______nextProps.classes.addUserSuccess", nextProps.classes.addUserSuccess);

    if (nextProps) {
      return {
        ...nextProps,

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }

  inputEmsData = () => {

    let temp = {
      "calenderData": this.state.fieldsCalender,
    }
    console.log("temp_______________temp", temp);
    this.props.dispatch(classesActions.getShowClassesBookSlotList(temp))
  }


  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(classesActions.getShowClassesBookSlotList(datatemp));
  }
  disableUser = (element) => {
    let datatemp = {
      "id": element.id,
    }
    let paginationdata = {
      // "keyWord": "",
      // "pageNo": 1,
      // "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status of booked slot?',
      message: `Are you sure cancel booked slot ${element.slotId.slotNo}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(classesActions.rejectBookClass(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleChangeDate = (data) => {
    console.log("data_________________data", data);
    let { classes } = this.props
    let { items } = classes

    let temp = {
      currentDate: items.day,
      nextDate: data
    }
    this.props.dispatch(classesActions.getShowClassesBookSlotList(temp))

  }

  handleChangeDateCelander = () => {
    // console.log("data_________________data1111111111111", data);
    // console.log("data_________2222222222222222222", data);
    // let { classes } = this.props
    // let { currentDate } = classes

    let temp = {
      "calenderData": this.state.fieldsCalender,
    }
    this.props.dispatch(classesActions.getShowClassesBookSlotList(temp))

  }


  inputCalenderChange = (e) => {
    // e.preventDefault();
    console.log('1111111111111111111111111111111', e.target.name, e.target.value);

    this.setState({ fieldsCalender: e.target.value });

    let temp = {
      "calenderData": this.state.fieldsCalender,
    }
    this.props.dispatch(classesActions.getShowClassesBookSlotList(temp))
  }
  inputClassData = () => {

    let temp = {
      "calenderData": this.state.fieldsCalender,
    }
    console.log("temp_______________temp", temp);
    this.props.dispatch(classesActions.getShowClassesBookSlotList(temp))
  }


  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }

  handleOpenCreateModalMoreDetails = (data) => {

    console.log("5555555555555555555555555555555555555555", data);
    let { classes } = this.props
    let { calenderDay } = classes
    console.log("calenderDaycalenderDaycalenderDaycalenderDaycalenderDaycalenderDaycalenderDay", calenderDay);

    this.props.history.push(`/app/classBookListDetail/${data.id}/${calenderDay}`)
  }

  render() {

    let { classes } = this.props
    let { items, currentDate, calenderDay, total, loading } = classes

    // let calenderDay = items.day

    console.log("calenderDaycalenderDaycalenderDaycalenderDaycalenderDaycalenderDaycalenderDay", calenderDay);
    console.log("items________items", items);
    console.log("this.state.fieldsCalender________currentDate", this.state.fieldsCalender);
    console.log("currentDate________currentDate", currentDate);
    console.log("render55555555555555555", this.state.ClassesRowData);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-x-auto  overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="bg-white border rounded-md  p-6 space-y-6">





                  <div className=" px-2">
                    <h3 className="text-5xl font-semibold  ">Class Book List</h3>
                  </div>
                  <div className='flex justify-between '>

                    <div  >

                      <div className="w-full cursor-pointer ">
                        <label class="block text-gray-700 text-sm" for="username"> Date</label>
                        <div className='flex'>
                          <input className={`px-3 py-2 placeholder-gray-400 text-gray-600 bg-gray-100 rounded text-sm shadow focus:outline-none border-gray-600 focus:ring-gray-600 w-full ease-linear  transition-all duration-150 ${this.state.errorsCalender && !this.state.errorsCalender["resultDate"] ? "placeholder-gray-500" : "border border-opacity-100  "}`}
                            id="resultDate" name="resultDate" placeholder="Select Date" value={this.state.fieldsCalender.date} type="date" onChange={this.inputCalenderChange} />
                          <button className='bg-gray-600 hover:bg-gray-100 border border-black text-white item-center h-10' onClick={() => this.inputEmsData()}><BiSearchAlt className='w-10 h-7 cursor-pointer  hover:text-gray-500' /></button>
                          {this.state.errorsCalender && this.state.errorsCalender["resultDate"] ?
                            <div className="invalid-feedback text-yellow-600">
                              {this.state.errorsCalender["resultDate"]}
                            </div>
                            : null}
                        </div>


                      </div>
                    </div>
                    <p className='border rounded-lg px-16 text-xl bg-gray-300 text-gray-700 font-bold py-4'>Date :-  {moment(new Date(parseInt(calenderDay))).utcOffset("+05:30").format("DD/MM/YYYY")}</p>
                    <div className='flex justify-between space-x-8 px-10'>
                      <button onClick={() => this.handleChangeDate(0)}><BsFillArrowLeftCircleFill className='w-10 h-10 cursor-pointer hover:text-gray-500' /></button>
                      <button onClick={() => this.handleChangeDate(1)}><BsFillArrowRightCircleFill className='w-10 h-10 cursor-pointer hover:text-gray-500' /></button>
                    </div>



                  </div>






                  <div className="pb-2 mt-4 overflow-hidden overflow-x-auto  ">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full ">
                        <div className=" ">
                          <table className="min-w-full border-0 divide-y  ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Slot No</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Limit</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Starting Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Ending Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Creation Date/Time</th>

                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Status </th>
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">View Details </th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.objectClass && items.objectClass.length > 0 ?
                                  items.objectClass.map((element, index) =>
                                  (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-800 font-medium whitespace-nowrap capitalize" title={element.name} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element && element.slotNo ? element.slotNo : "_"}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.limit ? element.limit : "_"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap uppercase">     {element && element.startingTime ? element.startingTime : "_"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap uppercase"> {element && element.endingTime ? element.endingTime : "_"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isDisable == true ?
                                            <button class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUser(element)}>

                                              <label>Reject</label>
                                            </button>
                                            : <label class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-md shadow-sm " disabled >

                                              <label>Rejected</label>
                                            </label>}
                                        </span>
                                      </td>

                                      {/* <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isDisable == false ?
                                            <button class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-gray-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-gray-400 cursor-pointer" onClick={() => this.disableUser(element)}>

                                              <label>Booked</label>
                                            </button>
                                            : <label class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-gray-500 border rounded-md shadow-sm " disabled >

                                              <label>Not Booked</label>
                                            </label>}
                                        </span>
                                      </td> */}


                                      <td className=" px-2 py-3 text-gray-600 whitespace-nowrap space-x-2 text-center flex justify-center">
                                        <div className='flex space-x-2'>
                                          <span className="relative ">
                                            {element.isActive ? "ADDED" :
                                              <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer  " onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                                <span className='tooltip-text bg-gray-500 p-6 mx-auto top-0 left-0 -mt-8 -ml-6 text-white text-sm rounded'>More Details</span>
                                                <BiDetail style={{ fontSize: "1.5rem" }} />
                                              </div>}
                                          </span>

                                          {/* <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              <span className='tooltip-text bg-gray-500 p-6 mx-auto top-0 left-0 -mt-8  text-white text-sm rounded'>Edit</span>

                                              <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.deleteCategory(element)}>
                                              <span className='tooltip-text bg-gray-500 p-6 mx-auto top-0 left-0 -mt-8 -ml-2 text-white text-sm rounded'>Delete</span>
                                              <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span> */}
                                        </div>
                                      </td>


                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>


        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          ClassesRowData={this.state.ClassesRowData}
          // getProductByCategoryId={getProductByCategoryId}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { classes } = state;
  return {
    classes
  };
}
export default connect(mapStateToProps)(classBookList);
