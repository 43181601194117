import React from "react";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { AiOutlineDown } from "react-icons/ai";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Header(props) {
  const onClickMenu = (url) => {
    localStorage.removeItem('adminuser');
    props.history.push(url)
  }

  return (



    <div className="z-20 flex items-center justify-end h-16 px-6 bg-white border-b ">
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div className="relative ml-6">
              <div className="flex items-center max-w-xs space-x-2 rounded-full focus:outline-none ">
                <div className="pr-2 text-lg font-semibold text-black">Admin</div>
                <Menu.Button className="flex items-center justify-center space-x-2">
                  <img className="w-10 h-10 border rounded-full" src="dist/img/profile.png" alt="" />
                  <AiOutlineDown className="text-black" style={{ fontSize: "0.8rem" }} />
                </Menu.Button>
              </div>
            </div>
            <Transition show={open} as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items static className="absolute right-0 w-40 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {/* <Menu.Item>
                          {({ active }) =>
                            (<span href="#" className={classNames(active ? 'bg-gray-200 text-gray-900' : 'text-gray-700', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-sm')} >Your Profile</span>)}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) =>
                            (<span href="#" className={classNames(active ? 'bg-gray-200 text-gray-900' : 'text-gray-700', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-sm')} >Settings</span>)}
                        </Menu.Item>*/}
                  <Menu.Item>
                    {({ active }) =>
                      (<span onClick={() => onClickMenu('/login')} className={classNames(active ? 'bg-shine text-gray-900' : 'text-gray-900', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-medium')}>Logout</span>)}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>


    </div>


  );
}
