export const restaurentConstants = {

    GET_ID_RESTAURENT_REQUEST: 'GET_ID_RESTAURENT_REQUEST',
    GET_ID_RESTAURENT_SUCCESS: 'GET_ID_RESTAURENT_SUCCESS',
    GET_ID_RESTAURENT_FAILURE: 'GET_ID_RESTAURENT_FAILURE',

    GETALL_RESTAURENT_REQUEST: 'GETALL_RESTAURENT_REQUEST',
    GETALL_RESTAURENT_SUCCESS: 'GETALL_RESTAURENT_SUCCESS',
    GETALL_RESTAURENT_FAILURE: 'GETALL_RESTAURENT_FAILURE',

    GET_LIST_RESTAURENT_REQUEST: 'GET_LIST_RESTAURENT_REQUEST',
    GET_LIST_RESTAURENT_SUCCESS: 'GET_LIST_RESTAURENT_SUCCESS',
    GET_LIST_RESTAURENT_FAILURE: 'GET_LIST_RESTAURENT_FAILURE',


    ADD_RESTAURENT_REQUEST: 'ADD_RESTAURENT_REQUEST',
    ADD_RESTAURENT_SUCCESS: 'ADD_RESTAURENT_SUCCESS',
    ADD_RESTAURENT_FAILURE: 'ADD_RESTAURENT_FAILURE',

    DELETE_RESTAURENT_REQUEST: 'DELETE_RESTAURENT_REQUEST',
    DELETE_RESTAURENT_SUCCESS: 'DELETE_RESTAURENT_SUCCESS',
    DELETE_RESTAURENT_FAILURE: 'DELETE_RESTAURENT_FAILURE',

    UPDATE_RESTAURENT_REQUEST: 'UPDATE_RESTAURENT_REQUEST',
    UPDATE_RESTAURENT_SUCCESS: 'UPDATE_RESTAURENT_SUCCESS',
    UPDATE_RESTAURENT_FAILURE: 'UPDATE_RESTAURENT_FAILURE',

    UPDATE_RESTAURENT_PASSWORD_REQUEST: 'UPDATE_RESTAURENT_PASSWORD_REQUEST',
    UPDATE_RESTAURENT_PASSWORD_SUCCESS: 'UPDATE_RESTAURENT_PASSWORD_SUCCESS',
    UPDATE_RESTAURENT_PASSWORD_FAILURE: 'UPDATE_RESTAURENT_PASSWORD_FAILURE',

    DISABLE_RESTAURENT_REQUEST: 'DISABLE_RESTAURENT_REQUEST',
    DISABLE_RESTAURENT_SUCCESS: 'DISABLE_RESTAURENT_SUCCESS',
    DISABLE_RESTAURENT_FAILURE: 'DISABLE_RESTAURENT_FAILURE',

};
