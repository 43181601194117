import {
 massageConstants
} from '../_constants';

export function massage(state = {}, action) {
//  console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case massageConstants.GET_ID_MASSAGE_REQUEST:
      return {
        ...state
      };
    case massageConstants.GET_ID_MASSAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allrestaurent: action.users.getRestaurantUserById,
      };
    case massageConstants.GET_ID_MASSAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case massageConstants.ADD_MASSAGE_REQUEST:
      return {
        ...state
      };
    case massageConstants.ADD_MASSAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case massageConstants.ADD_MASSAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case massageConstants.GET_LIST_MASSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case massageConstants.GET_LIST_MASSAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getMassageUserList.list,
        total: action.users.getMassageUserList.total
      };
    case massageConstants.GET_LIST_MASSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case massageConstants.GETALL_MASSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case massageConstants.GETALL_MASSAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allrestaurent: action.users.getRestaurantUserById,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case massageConstants.GETALL_MASSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case massageConstants.UPDATE_MASSAGE_REQUEST:
      return {
        ...state
      };
    case massageConstants.UPDATE_MASSAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case massageConstants.UPDATE_MASSAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };
      case massageConstants.UPDATE_MASSAGE_USER_PASSWORD_REQUEST:
      return {
        ...state
      };
    case massageConstants.UPDATE_MASSAGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case massageConstants.UPDATE_MASSAGE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case massageConstants.DELETE_MASSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case massageConstants.DELETE_MASSAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case massageConstants.DELETE_MASSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case massageConstants.DISABLE_MASSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case massageConstants.DISABLE_MASSAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case massageConstants.DISABLE_MASSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}