
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const sectionService = {
    login,
    logout,
    createSection,
    updateSection,
    getSectionList,
    deleteSection,
    disablegroupClass,
    getAllSectionList

};
function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getSectionList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    console.log('requestOptionsrequestOptionsrequestOptionsrequestOptionsrequestOptions', requestOptions);
    return fetch(CONST.BACKEND_URL + `/getSectionList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getSectionList: data.data
            }
            console.log("i am in service getSectionList", userObj);

            return userObj;
        });
}

function getAllSectionList() {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllSectionList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllSectionList: data.data
            }
            console.log("i am in service getAllSectionList", userObj);

            return userObj;
        });
}




function deleteSection(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteSection`, requestOptions)
        .then(handleResponse)
        .then(data => {


            let userObj = {
                deleteSection: data.data
            }
            console.log("i am in service''...>> deleteSection ::", userObj);

            return userObj;
        });
}
function disablegroupClass(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateGroupStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateGroupStatus: data.data
            }
            console.log("i am in service''...>> updateGroupStatus ::", userObj);

            return userObj;
        });
}

function updateSection(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateSection`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateSection: data.data
            }
            console.log("I am in service updateSection", userObj);

            return userObj;
        });
}
function createSection(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createSection`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createGroup: data.data
            }
            console.log("I am in service createSection", userObj);

            return userObj;
        });
}



function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}