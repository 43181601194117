import {
 restaurentConstants
} from '../_constants';

export function restaurent(state = {}, action) {
//  console.log("fgvbfghhrj>>>>>>>>>>>>>>", action);
  switch (action.type) {
    case restaurentConstants.GET_ID_RESTAURENT_REQUEST:
      return {
        ...state
      };
    case restaurentConstants.GET_ID_RESTAURENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allrestaurent: action.users.getRestaurantUserById,
      };
    case restaurentConstants.GET_ID_RESTAURENT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case restaurentConstants.ADD_RESTAURENT_REQUEST:
      return {
        ...state
      };
    case restaurentConstants.ADD_RESTAURENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case restaurentConstants.ADD_RESTAURENT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case restaurentConstants.GET_LIST_RESTAURENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case restaurentConstants.GET_LIST_RESTAURENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getRestaurantUserList.list,
        total: action.users.getRestaurantUserList.total
      };
    case restaurentConstants.GET_LIST_RESTAURENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case restaurentConstants.GETALL_RESTAURENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case restaurentConstants.GETALL_RESTAURENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allrestaurent: action.users.getRestaurantUserById,
        // allrestaurentTotal: action.users.getAllrestaurent
      };
    case restaurentConstants.GETALL_RESTAURENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case restaurentConstants.UPDATE_RESTAURENT_REQUEST:
      return {
        ...state
      };
    case restaurentConstants.UPDATE_RESTAURENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case restaurentConstants.UPDATE_RESTAURENT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case restaurentConstants.UPDATE_RESTAURENT_PASSWORD_REQUEST:
      return {
        ...state
      };
    case restaurentConstants.UPDATE_RESTAURENT_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case restaurentConstants.UPDATE_RESTAURENT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case restaurentConstants.DELETE_RESTAURENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case restaurentConstants.DELETE_RESTAURENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case restaurentConstants.DELETE_RESTAURENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case restaurentConstants.DISABLE_RESTAURENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case restaurentConstants.DISABLE_RESTAURENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case restaurentConstants.DISABLE_RESTAURENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}