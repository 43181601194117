import { userConstants, sectionConstants } from '../_constants';
import { userService, sectionService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const sectionActions = {

    login,
    logout,
    disablegroupClass,
    getAllSectionList,
    createSection,
    updateSection,
    getSectionList,
    deleteSection,
    uploadImageClear,
    updateGroupPassword,
    getUserById,
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createSection(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        sectionService.createSection(data)
            .then(

                users => {
                    dispatch(alertActions.success("Section  Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getSectionList(reqData));
                    // dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sectionConstants.ADD_SECTION_REQUEST } }
    function success(users) { return { type: sectionConstants.ADD_SECTION_SUCCESS, users } }
    function failure(error) { return { type: sectionConstants.ADD_SECTION_FAILURE, error } }
}

function updateSection(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        sectionService.updateSection(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Section Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getSectionList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sectionConstants.UPDATE_SECTION_REQUEST } }
    function success(users) { return { type: sectionConstants.UPDATE_SECTION_SUCCESS, users } }
    function failure(error) { return { type: sectionConstants.UPDATE_SECTION_FAILURE, error } }
}
function updateGroupPassword(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        sectionService.updateGroupPassword(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Password Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getSectionList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sectionConstants.UPDATE_SECTION_PASSWORD_REQUEST } }
    function success(users) { return { type: sectionConstants.UPDATE_SECTION_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: sectionConstants.UPDATE_SECTION_PASSWORD_FAILURE, error } }
}
function getAllSectionList() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        sectionService.getAllSectionList()
            .then(
                users => {
                    console.log("getAllSectionList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sectionConstants.GETALL_SECTION_REQUEST } }
    function success(users) { return { type: sectionConstants.GETALL_SECTION_SUCCESS, users } }
    function failure(error) { return { type: sectionConstants.GETALL_SECTION_FAILURE, error } }
}

function getSectionList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        sectionService.getSectionList(data)
            .then(
                users => {
                    console.log("getGroupList $$$$ action:", users);
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sectionConstants.GET_LIST_SECTION_REQUEST } }
    function success(users) { return { type: sectionConstants.GET_LIST_SECTION_SUCCESS, users } }
    function failure(error) { return { type: sectionConstants.GET_LIST_SECTION_FAILURE, error } }
}
function getUserById(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        sectionService.getUserById(data)
            .then(
                users => {
                    console.log("getUserById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sectionConstants.GET_LIST_USER_REQUEST } }
    function success(users) { return { type: sectionConstants.GET_LIST_USER_SUCCESS, users } }
    function failure(error) { return { type: sectionConstants.GET_LIST_USER_FAILURE, error } }
}


function deleteSection(data, paginationData) {
  
    return dispatch => {
        dispatch(request());
        sectionService.deleteSection(data)
            .then(
                users => {
                    dispatch(alertActions.success("Section Delete Successfully."));
                    dispatch(success(users));
                    dispatch(this.getSectionList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sectionConstants.DELETE_SECTION_REQUEST } }
    function success(users) { return { type: sectionConstants.DELETE_SECTION_SUCCESS, users } }
    function failure(error) { return { type: sectionConstants.DELETE_SECTION_FAILURE, error } }
}
function disablegroupClass(data, paginationData) {

    return dispatch => {
        dispatch(request());
        sectionService.disablegroupClass(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getSectionList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sectionConstants.DISABLE_SECTION_REQUEST } }
    function success(users) { return { type: sectionConstants.DISABLE_SECTION_SUCCESS, users } }
    function failure(error) { return { type: sectionConstants.DISABLE_SECTION_FAILURE, error } }
}
