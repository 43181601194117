import React from "react";
import { CgLogIn } from 'react-icons/cg';
// import Multiselect from 'multiselect-react-dropdown';



export default function CreateAddCategoryModal(props) {

  let { addUserCreateModal, handleAddUserHideModal, getAllSection, errorsgroupClass, inputAddUserChange, createRestoCategorySubmit, fieldsgroupClass, handleFile, filesDetails } = props;
  console.log("getAllPlotListgetAllPlotListgetAllPlotListgetAllPlotList", getAllSection);

  return (
    <div className={addUserCreateModal ? "fixed w-full top-0 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto mt-2">
        {/*modal header*/}
        <div className="flex items-center justify-between p-6 py-2 bg-blue-500 border-b">
          <p className="text-[16px] font-medium text-white uppercase">Add Plot </p>
          <div className="rounded-full cursor-pointer modal-close ">
            <svg onClick={() => handleAddUserHideModal()} className="fill-current text-white/60 hover:text-white/100 " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>

        <div className="p-4">
          <button className="text-sm text-gray-600 font-medium p-4 py-2 border border-gray-300 border-b-transparent bg-white -mb-0.5">
            Create Plot
          </button>
          <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">

            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">plotNo :</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 {errorsgroupClass && !errorsgroupClass["name"] ? "border placeholder-gray-500" : "border   "}`}
                id="plotno" name="plotno" placeholder="plotno" value={fieldsgroupClass.plotno} type="text" onChange={inputAddUserChange} />
              {errorsgroupClass && errorsgroupClass["plotno"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsgroupClass["plotno"]}roadsize
                </div>
                : null}
            </div>
            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">roadSize:</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 {errorsgroupClass && !errorsgroupClass["name"] ? "border placeholder-gray-500" : "border   "}`}
                id="roadsize" name="roadsize" placeholder="roadSize" value={fieldsgroupClass.roadsize} type="text" onChange={inputAddUserChange} />
              {errorsgroupClass && errorsgroupClass["roadsize"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsgroupClass["roadsize"]}
                </div>
                : null}
            </div>

            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Section:</label>
              <select class="form-select border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputAddUserChange} id="sectionId" name="sectionId"
                value={fieldsgroupClass && fieldsgroupClass["sectionId"] ? fieldsgroupClass["sectionId"] : null} >
                <option selected>Please Select Section</option>
                {
                  getAllSection && getAllSection && getAllSection.length > 0 ?
                    getAllSection.map((element, index) => (
                      <option value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                    )) : null
                }
              </select>
              {errorsgroupClass && errorsgroupClass["sectionId"] ?
                <div className="invalid-feedback text-red-600 text-xs">
                  {errorsgroupClass["sectionId"]}
                </div>
                : null}
            </div>

            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">size:</label>
              <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 {errorsgroupClass && !errorsgroupClass["name"] ? "border placeholder-gray-500" : "border   "}`}
                id="size" name="size" placeholder="size" value={fieldsgroupClass.size} type="text" onChange={inputAddUserChange} />
              {errorsgroupClass && errorsgroupClass["size"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsgroupClass["size"]}
                </div>
                : null}
            </div>
            <div className="w-full">
              <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Description :</label>
              <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsgroupClass && !errorsgroupClass["description"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                id="desc" name="desc" placeholder="Description" value={fieldsgroupClass.desc} type="text" onChange={inputAddUserChange} />
              {errorsgroupClass && errorsgroupClass["desc"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsgroupClass["desc"]}
                </div>
                : null}
            </div>




            <div className="flex justify-end py-0 space-x-4">
              <button className="bg-blue-500  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
                onClick={createRestoCategorySubmit}
              >Submit
                <CgLogIn size={18} className="ml-2" />
              </button>
            </div>

          </form>
        </div>
        {/*Footer*/}
      </div>
    </div>




  );
}
