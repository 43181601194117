export const classesConstants = {

    GET_ID_CLASSES_REQUEST: 'GET_ID_CLASSES_REQUEST',
    GET_ID_CLASSES_SUCCESS: 'GET_ID_CLASSES_SUCCESS',
    GET_ID_CLASSES_FAILURE: 'GET_ID_CLASSES_FAILURE',

    GETALL_MASSAGE_REQUEST: 'GETALL_MASSAGE_REQUEST',
    GETALL_MASSAGE_SUCCESS: 'GETALL_MASSAGE_SUCCESS',
    GETALL_MASSAGE_FAILURE: 'GETALL_MASSAGE_FAILURE',

    GET_LIST_CLASSES_REQUEST: 'GET_LIST_CLASSES_REQUEST',
    GET_LIST_CLASSES_SUCCESS: 'GET_LIST_CLASSES_SUCCESS',
    GET_LIST_CLASSES_FAILURE: 'GET_LIST_CLASSES_FAILURE',

    GET_LIST_CLASSES_BOOK_REQUEST: 'GET_LIST_CLASSES_BOOK_REQUEST',
    GET_LIST_CLASSES_BOOK_SUCCESS: 'GET_LIST_CLASSES_BOOK_SUCCESS',
    GET_LIST_CLASSES_BOOK_FAILURE: 'GET_LIST_CLASSES_BOOK_FAILURE',


    GET_CLASSES_BOOK_DETAIL_REQUEST: 'GET_CLASSES_BOOK_DETAIL_REQUEST',
    GET_CLASSES_BOOK_DETAIL_SUCCESS: 'GET_CLASSES_BOOK_DETAIL_SUCCESS',
    GET_CLASSES_BOOK_DETAIL_FAILURE: 'GET_CLASSES_BOOK_DETAIL_FAILURE',


    ADD_CLASSES_REQUEST: 'ADD_CLASSES_REQUEST',
    ADD_CLASSES_SUCCESS: 'ADD_CLASSES_SUCCESS',
    ADD_CLASSES_FAILURE: 'ADD_CLASSES_FAILURE',

    DELETE_CLASSES_REQUEST: 'DELETE_CLASSES_REQUEST',
    DELETE_CLASSES_SUCCESS: 'DELETE_CLASSES_SUCCESS',
    DELETE_CLASSES_FAILURE: 'DELETE_CLASSES_FAILURE',

    UPDATE_CLASSES_REQUEST: 'UPDATE_CLASSES_REQUEST',
    UPDATE_CLASSES_SUCCESS: 'UPDATE_CLASSES_SUCCESS',
    UPDATE_CLASSES_FAILURE: 'UPDATE_CLASSES_FAILURE',

    DISABLE_CLASSES_REQUEST: 'DISABLE_CLASSES_REQUEST',
    DISABLE_CLASSES_SUCCESS: 'DISABLE_CLASSES_SUCCESS',
    DISABLE_CLASSES_FAILURE: 'DISABLE_CLASSES_FAILURE',

    DISABLE_CLASSES_BOOK_REQUEST: 'DISABLE_CLASSES_BOOK_REQUEST',
    DISABLE_CLASSES_BOOK_SUCCESS: 'DISABLE_CLASSES_BOOK_SUCCESS',
    DISABLE_CLASSES_BOOK_FAILURE: 'DISABLE_CLASSES_BOOK_FAILURE',

};
