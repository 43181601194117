export const groupClassCategoryConstants = {

    GET_ID_CLASS_CATEGORY_REQUEST: 'GET_ID_CLASS_CATEGORY_REQUEST',
    GET_ID_CLASS_CATEGORY_SUCCESS: 'GET_ID_CLASS_CATEGORY_SUCCESS',
    GET_ID_CLASS_CATEGORY_FAILURE: 'GET_ID_CLASS_CATEGORY_FAILURE',

    GETALL_CLASS_CATEGORY_REQUEST: 'GETALL_CLASS_CATEGORY_REQUEST',
    GETALL_CLASS_CATEGORY_SUCCESS: 'GETALL_CLASS_CATEGORY_SUCCESS',
    GETALL_CLASS_CATEGORY_FAILURE: 'GETALL_CLASS_CATEGORY_FAILURE',

    GET_LIST_CLASS_CATEGORY_REQUEST: 'GET_LIST_CLASS_CATEGORY_REQUEST',
    GET_LIST_CLASS_CATEGORY_SUCCESS: 'GET_LIST_CLASS_CATEGORY_SUCCESS',
    GET_LIST_CLASS_CATEGORY_FAILURE: 'GET_LIST_CLASS_CATEGORY_FAILURE',


    ADD_CLASS_CATEGORY_REQUEST: 'ADD_CLASS_CATEGORY_REQUEST',
    ADD_CLASS_CATEGORY_SUCCESS: 'ADD_CLASS_CATEGORY_SUCCESS',
    ADD_CLASS_CATEGORY_FAILURE: 'ADD_CLASS_CATEGORY_FAILURE',

    DELETE_CLASS_CATEGORY_REQUEST: 'DELETE_CLASS_CATEGORY_REQUEST',
    DELETE_CLASS_CATEGORY_SUCCESS: 'DELETE_CLASS_CATEGORY_SUCCESS',
    DELETE_CLASS_CATEGORY_FAILURE: 'DELETE_CLASS_CATEGORY_FAILURE',

    UPDATE_CLASS_CATEGORY_REQUEST: 'UPDATE_CLASS_CATEGORY_REQUEST',
    UPDATE_CLASS_CATEGORY_SUCCESS: 'UPDATE_CLASS_CATEGORY_SUCCESS',
    UPDATE_CLASS_CATEGORY_FAILURE: 'UPDATE_CLASS_CATEGORY_FAILURE',

    DISABLE_CLASS_CATEGORY_REQUEST: 'DISABLE_CLASS_CATEGORY_REQUEST',
    DISABLE_CLASS_CATEGORY_SUCCESS: 'DISABLE_CLASS_CATEGORY_SUCCESS',
    DISABLE_CLASS_CATEGORY_FAILURE: 'DISABLE_CLASS_CATEGORY_FAILURE',

};
