import { userConstants, plotConstants } from '../_constants';
import { userService, plotService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const plotActions = {

    login,
    logout,
    disablegroupClass,
    getAllPlotList,
    createPlot,
    updatePlot,
    getPlotList,
    deleteGroup,
    uploadImageClear,
    getgroupClassUserById,
    updateGroupPassword,
    getUserById,
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    // console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createPlot(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        plotService.createPlot(data)
            .then(

                users => {
                    dispatch(alertActions.success("Plot  Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getPlotList(reqData));
                    // dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: plotConstants.ADD_PLOT_REQUEST } }
    function success(users) { return { type: plotConstants.ADD_PLOT_SUCCESS, users } }
    function failure(error) { return { type: plotConstants.ADD_PLOT_FAILURE, error } }
}

function updatePlot(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        plotService.updatePlot(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Plot  Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getPlotList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: plotConstants.UPDATE_PLOT_REQUEST } }
    function success(users) { return { type: plotConstants.UPDATE_PLOT_SUCCESS, users } }
    function failure(error) { return { type: plotConstants.UPDATE_PLOT_FAILURE, error } }
}
function updateGroupPassword(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        plotService.updateGroupPassword(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Password Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getPlotList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: plotConstants.UPDATE_PLOT_PASSWORD_REQUEST } }
    function success(users) { return { type: plotConstants.UPDATE_PLOT_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: plotConstants.UPDATE_PLOT_PASSWORD_FAILURE, error } }
}
function getAllPlotList() {
    // // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        plotService.getAllPlotList()
            .then(
                users => {
                    // console.log("getAllPlotList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: plotConstants.GETALL_PLOT_REQUEST } }
    function success(users) { return { type: plotConstants.GETALL_PLOT_SUCCESS, users } }
    function failure(error) { return { type: plotConstants.GETALL_PLOT_FAILURE, error } }
}
function getgroupClassUserById() {
    // // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        plotService.getgroupClassUserById()
            .then(
                users => {
                    // console.log("getgroupClassUserById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: plotConstants.GET_ID_PLOT_REQUEST } }
    function success(users) { return { type: plotConstants.GET_ID_PLOT_SUCCESS, users } }
    function failure(error) { return { type: plotConstants.GET_ID_PLOT_FAILURE, error } }
}
function getPlotList(data) {
    // // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        plotService.getPlotList(data)
            .then(
                users => {
                    console.log("getPlotList $$$$ action:", users);
                    dispatch(success(users));
                    // dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: plotConstants.GET_LIST_PLOT_REQUEST } }
    function success(users) { return { type: plotConstants.GET_LIST_PLOT_SUCCESS, users } }
    function failure(error) { return { type: plotConstants.GET_LIST_PLOT_FAILURE, error } }
}
function getUserById(data) {
    // // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        plotService.getUserById(data)
            .then(
                users => {
                    // console.log("getUserById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: plotConstants.GET_LIST_USER_REQUEST } }
    function success(users) { return { type: plotConstants.GET_LIST_USER_SUCCESS, users } }
    function failure(error) { return { type: plotConstants.GET_LIST_USER_FAILURE, error } }
}


function deleteGroup(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        plotService.deleteGroup(data)
            .then(
                users => {
                    dispatch(alertActions.success("Plot Delete Successfully."));
                    dispatch(success(users));
                    dispatch(this.getPlotList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: plotConstants.DELETE_PLOT_REQUEST } }
    function success(users) { return { type: plotConstants.DELETE_PLOT_SUCCESS, users } }
    function failure(error) { return { type: plotConstants.DELETE_PLOT_FAILURE, error } }
}
function disablegroupClass(data, paginationData) {

    return dispatch => {
        dispatch(request());
        plotService.disablegroupClass(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getPlotList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: plotConstants.DISABLE_PLOT_REQUEST } }
    function success(users) { return { type: plotConstants.DISABLE_PLOT_SUCCESS, users } }
    function failure(error) { return { type: plotConstants.DISABLE_PLOT_FAILURE, error } }
}
